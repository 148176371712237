import * as React from 'react';
import { useCallback, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as iots from 'io-ts';
import { get } from 'api/client';
import CDACInfoWindow, { IOCDACInfo, } from 'components/infoWindow/CDACInfoWindow';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import CDACImg from 'images/cdac.png';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey, LAST_SIX_MONTHS } from 'utils/filters';
const IOCDACMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        id: iots.string,
        date: iots.string,
        alimentaryId: iots.number,
        creationId: iots.number,
    }),
], 'IOCDACMarkerItem');
const fetchCDAC = (townId) => () => get(iots.array(IOCDACMarkerItem), `${townId}/cdac`);
const fetchCDACInfo = (item) => {
    return get(IOCDACInfo, `cdac-info/${item.id}`);
};
const formatInfoWindowContent = (info) => {
    const content = <CDACInfoWindow info={info}/>;
    return ReactDOMServer.renderToStaticMarkup(content);
};
const isTimePeriodAllowed = (marker, timePeriod) => {
    if (!timePeriod) {
        return true;
    }
    const date = new Date(marker.date);
    if (timePeriod === LAST_SIX_MONTHS) {
        const now = new Date();
        const diff = now.getTime() - date.getTime();
        const monthDiff = diff / (1000 * 3600 * 24 * 30);
        return monthDiff <= 6;
    }
    return date.getFullYear() === timePeriod;
};
const isAlimentaryAllowed = (marker, alimentaryId) => {
    if (!alimentaryId) {
        return true;
    }
    return marker.alimentaryId == alimentaryId;
};
const isCreationAllowed = (marker, creationId) => {
    if (!creationId) {
        return true;
    }
    return marker.creationId == creationId;
};
const useCDACLayer = () => {
    const extraFilters = useExtraFilters();
    const [markerInfo, setMarkerInfo] = useState({});
    const allowMarker = useCallback((marker) => {
        const date = extraFilters?.[filtersKey.CDAC]?.timePeriod ?? null;
        const alimentaryId = extraFilters?.[filtersKey.CDAC]?.alimentaryId ?? null;
        const creationId = extraFilters?.[filtersKey.CDAC]?.creationId ?? null;
        return (isTimePeriodAllowed(marker, date) &&
            isAlimentaryAllowed(marker, alimentaryId) &&
            isCreationAllowed(marker, creationId));
    }, [extraFilters]);
    const getInfoWindowContent = async (marker) => {
        if (markerInfo[marker.id]) {
            return markerInfo[marker.id];
        }
        const CDACInfo = await fetchCDACInfo(marker);
        const content = formatInfoWindowContent(CDACInfo);
        setMarkerInfo({
            ...markerInfo,
            [marker.id]: content,
        });
        return content;
    };
    createLayersHook({
        fetch: fetchCDAC,
        markerImg: CDACImg,
        getInfoWindowContent,
        allowLayer: allowMarker,
        updateOnChange: true,
    })(filtersKey.CDAC);
};
const CDACLayer = () => {
    useCDACLayer();
    return null;
};
export default CDACLayer;
