import * as React from 'react';
import PlotLayers from 'components/tabs/plots/PlotSections/rules/PlotLayers';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import useProjectLayers from 'hooks/crm/useProjectLayers';
const RulesSection = ({ projectId }) => {
    const selectedTown = useSelectedTown();
    const { projectLayers, isLoading } = useProjectLayers(selectedTown?.id, projectId);
    return <PlotLayers layers={projectLayers} isLoading={isLoading}/>;
};
export default RulesSection;
