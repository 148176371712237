import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, HStack, Icon, Link, Skeleton, Text, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CATEGORY_DOWNLOAD, ANALYTICS_MAP_STICKY, } from 'analytics/useAnalytics';
import { useIsFilterActive, useToggleFilter, } from 'hooks/contexts/useFiltersContext';
import { useIsLoadingPLUInfo, usePLUInfo } from 'hooks/contexts/useMapContext';
import DownloadIcon from 'images/icons/Download';
import { filtersKey } from 'utils/filters';
import LegendAccordion from './LegendAccordion';
const PLULegendSkeleton = () => {
    return (<VStack alignItems="flex-start">
      <Skeleton height="10px" width="60%"/>
      <Skeleton height="10px" width="80%"/>
      <Skeleton height="10px" width="100%"/>
      <Skeleton height="20px" width="80%"/>
    </VStack>);
};
const PLULegendItem = () => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const toggleFilter = useToggleFilter();
    const PLUInfo = usePLUInfo();
    const isLoadingPLUInfo = useIsLoadingPLUInfo();
    const isFilterActive = useIsFilterActive();
    if (!isFilterActive(filtersKey.PLU) && !PLUInfo && !isLoadingPLUInfo) {
        return null;
    }
    return (<LegendAccordion id={filtersKey.PLU} isLoading={isLoadingPLUInfo} skeleton={<PLULegendSkeleton />} legendLabel={t('legends.plu.title')} handleClose={() => {
            trackEvent({
                category: ANALYTICS_MAP_STICKY,
                action: `${t('legends.plu.title')} off légende`,
            });
            toggleFilter(filtersKey.PLU, false);
        }}>
      {PLUInfo && (<Box key={PLUInfo?.name}>
          <Text fontWeight="900">{PLUInfo?.area}</Text>
          <Text>
            {t('legends.plu.subarea')} : <b>{PLUInfo.subarea}</b>
          </Text>
          <Text>{PLUInfo.name}</Text>
          {PLUInfo.url && (<HStack alignItems="start" marginTop={1}>
              <Icon as={DownloadIcon} width={4} height={4} color="colorMode.revertBase" alignSelf="center"/>
              <Link isExternal={true} fontSize="12px" href={PLUInfo.url} color="colorMode.revertBase" onClick={() => trackEvent({
                    category: ANALYTICS_CATEGORY_DOWNLOAD,
                    action: 'Télécharger règlement carte',
                })}>
                {t('legends.plu.url')}
              </Link>
            </HStack>)}
        </Box>)}
    </LegendAccordion>);
};
export default PLULegendItem;
