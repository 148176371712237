import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
import { IOPlotJson } from 'types/api';
const PLOT_QUERY_KEY = 'plot';
export const fetchPlot = (townshipId, plotId) => {
    plotId = plotId ?? '0';
    return get(IOPlotJson, `plot/${townshipId}/${plotId}`, {}, false, {}, (error) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        !error.response?.data.detail.startsWith('other_town_') &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            error.response?.data.detail !== 'nothing_here');
    });
};
const usePlot = ({ townshipId, plotId, enabled }) => {
    const { data: plot, isInitialLoading: isLoading } = useQuery({
        queryKey: [PLOT_QUERY_KEY, plotId],
        queryFn: () => fetchPlot(townshipId, plotId),
        enabled: !!plotId && !!townshipId && enabled,
        retry: false,
    });
    return { plot, isLoading };
};
export default usePlot;
