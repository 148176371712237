import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchIcon } from '@chakra-ui/icons';
import { CircularProgress, Input, InputGroup, InputLeftElement, InputRightElement, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CATEGORY_SEARCH_TOWN, } from 'analytics/useAnalytics';
import SearchGoogleMapPopover from 'components/SearchGoogleMapPopover';
import { useClearPlotSummary } from 'hooks/contexts/useLegendContext';
import { useFormattedAddress, useSelectedTown, } from 'hooks/contexts/useLocationContext';
import { useMap } from 'hooks/contexts/useMapContext';
import useLocationSearch from 'hooks/plot/useLocationSearch';
import { ADDRESS_FROM_CLICK } from 'hooks/useChangeTown';
import useCustomToast from 'hooks/useCustomToast';
import useUserCountry from 'hooks/useUserCountry';
import { getFormattedAddressFromPlace } from 'utils/address';
import { cleanAllMarkerFromMapAndPanorama } from 'utils/map';
import GeoLocationButton from './GeolocationButton';
const LAT_LNG_REGEX = /^(?<lat>[-+]?(?:[1-8]?\d(?:\.\d+)?|90(?:\.0+)?))\s*,*\s*(?<lng>[-+]?(?:180(\.0+)?|(?:1[0-7]\d|[1-9]?\d)(?:\.\d+)?))$/;
const addSelectFirstItemOnEnterListener = (input) => {
    // store the original event binding function
    const _addEventListener = input.addEventListener
        ? input.addEventListener
        : input.attachEvent;
    const addEventListenerWrapper = (type, listener) => {
        // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
        // and then trigger the original listener.
        if (type == 'keydown' || type == 'onclick') {
            const originalListener = listener;
            listener = function (event) {
                const selectedSuggestion = document.getElementsByClassName('pac-item-selected').length > 0;
                if (event.which == 13 && !selectedSuggestion) {
                    const simulatedDownArrow = new KeyboardEvent('keydown', {
                        keyCode: 40,
                    });
                    originalListener.apply(input, [simulatedDownArrow]);
                }
                originalListener.apply(input, [event]);
            };
        }
        // add the modified listener
        _addEventListener.apply(input, [type, listener]);
    };
    if (input.addEventListener)
        input.addEventListener = addEventListenerWrapper;
    else if (input.attachEvent)
        input.attachEvent = addEventListenerWrapper;
};
const LocationSearchInput = () => {
    const inputRef = useRef(null);
    const { t } = useTranslation();
    const selectedTown = useSelectedTown();
    const formattedAddress = useFormattedAddress();
    const { locatePlace, isLocating } = useLocationSearch();
    const { getErrorToast } = useCustomToast();
    const clearPlotSummary = useClearPlotSummary();
    const [input, setInput] = useState(null);
    const [showNoResultAutocomplete, setShowNoResultAutocomplete] = useState(false);
    const [showHelperAutocomplete, setShowHelperAutocomplete] = useState(true);
    const { countriesIso2 } = useUserCountry();
    const { trackEvent } = useAnalytics();
    const [place, setPlace] = useState();
    const map = useMap();
    const inputValue = input !== null
        ? input
        : (formattedAddress !== ADDRESS_FROM_CLICK && formattedAddress) ||
            (selectedTown ? selectedTown.name : '');
    useEffect(() => {
        if (!inputRef || !map || !countriesIso2) {
            return;
        }
        const inputField = inputRef.current;
        addSelectFirstItemOnEnterListener(inputField);
        const geocoder = new google.maps.Geocoder();
        const autocomplete = new google.maps.places.Autocomplete(inputField, {
            types: ['geocode'],
            componentRestrictions: { country: countriesIso2 },
            fields: ['formatted_address', 'geometry', 'address_components'],
        });
        let timer = null;
        autocomplete.addListener('place_changed', () => {
            clearTimeout(timer);
            setShowNoResultAutocomplete(false);
            setShowHelperAutocomplete(false);
            trackEvent({
                category: ANALYTICS_CATEGORY_SEARCH_TOWN,
                action: 'Google map',
            });
            return setPlace(autocomplete.getPlace());
        });
        // addListenerOnce ne fonctionne pas tout le temps
        google.maps.event.addListener(map, 'idle', function () {
            if (inputField.getAttribute('data-init') === '1') {
                return;
            }
            // Pour laisser le temps à google de générer pac-container
            setTimeout(() => {
                const autocompleteResult = document.querySelector('.pac-container');
                // La mutation n'est appelée que s'il y a du contenu dans le résultat de recherche
                const autocompleteListMutationCallback = () => {
                    // l'utilisation de const autocompleteItems = autocompleteResult.querySelectorAll('.pac-item');
                    // dans la mutation marche une fois mais apprès les items ne sont plus cliquable ???!!
                    clearTimeout(timer);
                    setShowNoResultAutocomplete(false);
                };
                const autocompleteListCallback = () => {
                    const autocompleteItems = autocompleteResult.querySelectorAll('.pac-item');
                    if (inputField.value === '') {
                        if (showNoResultAutocomplete) {
                            setShowNoResultAutocomplete(false);
                        }
                    }
                    else {
                        const noResult = autocompleteItems.length === 0;
                        // Vérification que l'on a bien un résultat
                        if (noResult) {
                            geocoder.geocode({ address: inputField.value }, function (results, status) {
                                if (status === google.maps.GeocoderStatus.OK) {
                                    if (results === null) {
                                        setShowNoResultAutocomplete(true);
                                    }
                                    else {
                                        setShowNoResultAutocomplete(false);
                                    }
                                }
                                else if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
                                    setShowNoResultAutocomplete(true);
                                }
                            });
                        }
                        else {
                            setShowNoResultAutocomplete(false);
                        }
                    }
                };
                if (!autocompleteResult) {
                    return;
                }
                // Création d'un observer pour la modification d'un noeud du DOM
                const observer = new MutationObserver(autocompleteListMutationCallback);
                observer.observe(autocompleteResult, {
                    childList: true,
                    attributes: false,
                });
                inputField.addEventListener('blur', () => {
                    setShowNoResultAutocomplete(false);
                });
                inputField.addEventListener('keyup', (e) => {
                    clearTimeout(timer);
                    if (e.code !== 'Enter') {
                        timer = setTimeout(autocompleteListCallback, 500);
                    }
                    else {
                        inputField.blur();
                    }
                });
                inputField.addEventListener('focus', () => {
                    clearTimeout(timer);
                    timer = setTimeout(autocompleteListCallback, 300);
                });
                inputField.setAttribute('data-init', '1');
            }, 500);
        });
    }, [inputRef, map, countriesIso2]);
    useEffect(() => {
        if (formattedAddress === ADDRESS_FROM_CLICK || formattedAddress === null) {
            setInput(null);
        }
        else if (formattedAddress) {
            setInput(formattedAddress);
        }
    }, [formattedAddress]);
    useEffect(() => {
        if (place) {
            handlePlaceChange(place);
        }
    }, [place]);
    const handlePlaceChange = (place) => {
        clearPlotSummary();
        if (place.name) {
            const coordinatesMatch = place.name.match(LAT_LNG_REGEX);
            const lat = coordinatesMatch?.groups?.lat;
            const lng = coordinatesMatch?.groups?.lng;
            if (lat && lng) {
                const geocoder = new google.maps.Geocoder();
                geocoder.geocode({
                    location: new google.maps.LatLng(parseFloat(lat), parseFloat(lng)),
                }, (results, status) => {
                    if (status !== google.maps.GeocoderStatus.OK) {
                        getErrorToast({
                            title: t('header.select_town.wrong_coordinates'),
                            description: process.env.NODE_ENV === 'development' ? status : undefined,
                        });
                    }
                    setPlace(results[0]);
                });
                return;
            }
        }
        if (!place.geometry)
            return;
        const newFormattedAddress = getFormattedAddressFromPlace(place);
        setInput(newFormattedAddress);
        if (newFormattedAddress !== formattedAddress) {
            cleanAllMarkerFromMapAndPanorama();
            locatePlace(place);
        }
    };
    return (<SearchGoogleMapPopover inputRef={inputRef} showNoResult={showNoResultAutocomplete} showHelper={showHelperAutocomplete}>
      <InputGroup>
        <InputLeftElement>
          <GeoLocationButton clearInput={() => setInput(null)}/>
        </InputLeftElement>
        <Input ref={inputRef} placeholder={t('header.select_town.placeholder')} onChange={(e) => setInput(e.currentTarget.value)} value={inputValue} data-cy="location_search_input" disabled={isLocating}/>
        <InputRightElement paddingRight="4px" pointerEvents="none">
          {isLocating ? (<CircularProgress size="15px" isIndeterminate color="gray"/>) : (<SearchIcon color="grey"/>)}
        </InputRightElement>
      </InputGroup>
    </SearchGoogleMapPopover>);
};
export default LocationSearchInput;
