import { useContextSelector } from 'use-context-selector';
import FiltersContext from 'context/FiltersContext';
export const useFilters = () => {
    return useContextSelector(FiltersContext, (state) => state.filters);
};
export const useUpdateFilters = () => {
    return useContextSelector(FiltersContext, (state) => state.updateFilters);
};
export const useExtraFilters = () => {
    return useContextSelector(FiltersContext, (state) => state.extraFilters);
};
export const useUpdateExtraFilter = () => {
    return useContextSelector(FiltersContext, (state) => state.updateExtraFilter);
};
export const useFiltersSearchRegex = () => {
    return useContextSelector(FiltersContext, (state) => state.filtersSearchRegex);
};
export const useUpdateFiltersSearch = () => {
    return useContextSelector(FiltersContext, (state) => state.updateFiltersSearch);
};
export const useToggleFilter = () => {
    return useContextSelector(FiltersContext, (state) => state.toggleFilter);
};
export const useResetFilters = () => {
    return useContextSelector(FiltersContext, (state) => state.resetFilters);
};
export const useTableFilters = () => {
    return useContextSelector(FiltersContext, (state) => state.tableFilters);
};
export const useUpdateTableFilter = () => {
    return useContextSelector(FiltersContext, (state) => state.updateTableFilter);
};
export const useSetFilters = () => {
    return useContextSelector(FiltersContext, (state) => state.setFilters);
};
export const useClearFilters = () => {
    return useContextSelector(FiltersContext, (state) => state.clearFilters);
};
export const useActiveFiltersCount = () => {
    return useContextSelector(FiltersContext, (state) => state.activeFiltersCount);
};
export const useHiddenFilters = () => {
    return useContextSelector(FiltersContext, (state) => state.hiddenFilters);
};
export const useToggleHiddenFilter = () => {
    return useContextSelector(FiltersContext, (state) => state.toggleHiddenFilter);
};
export const useIsHiddenFilter = () => {
    return useContextSelector(FiltersContext, (state) => state.isHiddenFilter);
};
export const useIsFilterActive = () => {
    return useContextSelector(FiltersContext, (state) => state.isFilterActive);
};
