import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading, Image, Spinner, Stack, Text } from '@chakra-ui/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import useSite, { getSiteLogoWhite } from 'hooks/useSite';
import Home2 from 'images/Home2.png';
import Img1 from 'images/img1.png';
import Img2 from 'images/img2.png';
import Img3 from 'images/img3.png';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
const CarouselHome = () => {
    const { t } = useTranslation();
    const { site, isLoading } = useSite();
    const dataCarousel = [
        {
            title: `${t('carousel.legend_1.title')}`,
            subtitle: `${t('carousel.legend_1.subtitle')}`,
            img: Img1,
        },
        {
            title: `${t('carousel.legend_2.title')}`,
            subtitle: `${t('carousel.legend_2.subtitle')}`,
            img: Img2,
        },
        {
            title: `${t('carousel.legend_3.title')}`,
            subtitle: `${t('carousel.legend_3.subtitle')}`,
            img: Img3,
        },
        {
            title: `${t('carousel.legend_4.title')}`,
            subtitle: `${t('carousel.legend_4.subtitle')}`,
            img: Home2,
        },
        {
            title: `${t('carousel.legend_5.title')}`,
            subtitle: `${t('carousel.legend_5.subtitle')}`,
            img: Img2,
        },
    ];
    return (<Stack width="100%" height="100vh" position="relative" overflow="hidden">
      <Box width="100%" position="absolute" top={0} height="20%" bgGradient={['linear(to-b, #1B1B1C, transparent)']} zIndex={10}/>

      <Box position="absolute" paddingTop="24px" paddingLeft="24px" zIndex={101}>
        {isLoading ? (<Spinner />) : (<Image src={getSiteLogoWhite(site)} objectFit="contain" height="24px"/>)}
      </Box>

      <Box width="100%">
        <Swiper slidesPerView={1} spaceBetween={0} loop={true} pagination={{
            clickable: true,
        }} modules={[Autoplay, Pagination]} centeredSlides={true} autoplay={{
            delay: 5000,
            disableOnInteraction: false,
        }}>
          {dataCarousel.map((item, index) => {
            return (<SwiperSlide key={index} className="slide">
                <Image src={item.img} objectFit="cover" height="100vh"/>

                <Box zIndex={101} position="absolute" bottom="110px" width="100%" textAlign="center" maxWidth="450px" left="0" right="0" margin="auto">
                  <Heading marginBottom={2} color="white" variant="h2">
                    {item.title}
                  </Heading>
                  <Text textStyle="paragraph" fontWeight={400} textAlign="center" color="white">
                    {item.subtitle}
                  </Text>
                </Box>
                <Box width="100%" position="absolute" bottom={0} height="30%" bgGradient={['linear(to-b, transparent, #1B1B1C)']} zIndex={100}/>
              </SwiperSlide>);
        })}
        </Swiper>
      </Box>
    </Stack>);
};
export default CarouselHome;
