import * as React from 'react';
import { Box, HStack, VStack } from '@chakra-ui/react';
import ProjectPlotsTable from 'components/tabs/crmTabs/project/ProjectPlotsTable';
import AddPlotForm from 'components/tabs/utils/AddPlotForm';
import AddPlotFromMap from 'components/tabs/utils/AddPlotFromMap';
import { PLOT_SELECTION_PROJECT } from 'context/LocationContext';
import { PROJECT_MODE } from 'context/PlotSearchContext';
import { useToggleFilter } from 'hooks/contexts/useFiltersContext';
import { SPACING_PANEL } from 'utils/constants';
import { filtersKey } from 'utils/filters';
const ProjectPlotsSection = ({ projectId, projectPlots, isLoadingPlots, removePlot, removePlotFromProjectLoading, currentDeletedProjectPlot, landPointId, isUserOwner = true, }) => {
    const toggleFilter = useToggleFilter();
    const onChange = (enabled) => {
        // Dans le cas du point foncier le calque est activé par défaut
        if (landPointId) {
            return;
        }
        if (enabled) {
            toggleFilter(filtersKey.MAP_PLOTS, false);
        }
        else {
            toggleFilter(filtersKey.MAP_PLOTS, true);
        }
    };
    return (<VStack width="full" spacing={SPACING_PANEL}>
      {isUserOwner && (<HStack width="full" spacing={4} alignItems="stretch">
          <Box minWidth="220px" flex="1">
            <AddPlotFromMap mode={PLOT_SELECTION_PROJECT} onChange={onChange}/>
          </Box>
          <Box minWidth="220px" flex="2">
            <AddPlotForm mode={PROJECT_MODE} withLabel={false} submitText="tabs.project.add_plot"/>
          </Box>
        </HStack>)}

      <ProjectPlotsTable isUserOwner={isUserOwner} landPointId={landPointId} projectId={projectId} projectPlots={projectPlots} isLoading={isLoadingPlots} removePlot={removePlot} isDeletingPlot={removePlotFromProjectLoading} currentDeletedProjectPlot={currentDeletedProjectPlot}/>
    </VStack>);
};
export default ProjectPlotsSection;
