import { memo, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { Box } from '@chakra-ui/react';
import Layers from 'components/layers/Layers';
import { menuItems } from 'components/sidebar/utils';
import StreetViewContainer from 'components/StreetViewContainer';
import StreeViewSizeControl from 'components/StreeViewSizeControl';
import { useMenuItemSelected } from 'hooks/contexts/useMenuContext';
import { useInitMap } from 'hooks/useInitMap';
import MapBlock from './MapBlock';
import MapTools from './MapTools';
const MapContainer = () => {
    const refMap = useRef(null);
    const refPanorama = useRef(null);
    const menuItemSelected = useMenuItemSelected();
    useInitMap(refMap, refPanorama);
    const [mapHeight, setMapHeight] = useState(null);
    useEffect(() => {
        if (!refMap.current)
            return;
        const resizeObserver = new ResizeObserver(() => {
            setMapHeight(refMap?.current?.clientHeight);
        });
        resizeObserver.observe(refMap.current);
        return () => resizeObserver.disconnect();
    }, []);
    return (<Box data-cy="map_container" display={menuItemSelected === menuItems.NEWS ? 'none' : 'block'} position="relative" flex={1} width="full" height="100%">
      <StreetViewContainer refPanorama={refPanorama}/>
      <MapBlock refMap={refMap}/>
      <Box height="auto">
        <MapTools mapHeight={mapHeight}/>
      </Box>
      <StreeViewSizeControl />
      <Layers />
    </Box>);
};
export default memo(MapContainer);
