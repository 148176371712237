import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Heading, Text, VStack } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { uniqueId } from 'lodash';
import { post } from 'api/client';
import { TWO_FACTOR_METHOD_EMAIL, TWO_FACTOR_METHOD_SMS, TWO_FACTOR_METHOD_TOTP, } from 'context/AuthContext';
import { useUpdateUserData, useUserData } from 'hooks/contexts/useAuthContext';
import useCustomToast from 'hooks/useCustomToast';
import { IOEmpty } from 'types/api';
import TwoFactorForm from './TwoFactorForm';
const IOEnableTotp2FA = iots.type({
    qrCode: iots.string,
});
const apiEnableTotp2FA = () => post(IOEnableTotp2FA, 'two-factor/enable-totp');
const apiConfirmTotp2FA = (code) => post(IOEmpty, 'two-factor/enable-totp-confirm', {
    _auth_code: code,
});
const apiEnableEmail2FA = () => post(IOEmpty, 'two-factor/enable-email');
const apiConfirmEmail2FA = (code) => post(IOEmpty, 'two-factor/enable-email-confirm', {
    _auth_code: code,
});
const apiDisableTotp2FA = (code) => post(IOEmpty, 'two-factor/disable-totp', {
    _auth_code: code,
});
const apiDisableEmail2FA = () => post(IOEmpty, 'two-factor/disable-email');
const apiConfirmDisableEmail2FA = (code) => post(IOEmpty, 'two-factor/disable-email-confirm', {
    _auth_code: code,
});
const getEnabledTwoFactorMethods = (userData) => {
    const enabledMethods = [];
    if (!userData.twoFactorRequired) {
        return enabledMethods;
    }
    if (userData.twoFactorTotpEnabled) {
        enabledMethods.push(TWO_FACTOR_METHOD_TOTP);
    }
    if (userData.twoFactorEmailEnabled) {
        enabledMethods.push(TWO_FACTOR_METHOD_EMAIL);
    }
    if (userData.twoFactorSmsEnabled) {
        enabledMethods.push(TWO_FACTOR_METHOD_SMS);
    }
    return enabledMethods;
};
const canDisableTwoFactorMethod = (userData) => {
    const enabledMethods = getEnabledTwoFactorMethods(userData);
    return enabledMethods.length > 1;
};
const TWO_FACTOR_ENABLE_EMAIL_QUERY_KEY = 'two-factor-enable-email';
const TWO_FACTOR_ENABLE_EMAIL_CONFIRM_QUERY_KEY = 'two-factor-enable-email-confirm';
const TWO_FACTOR_DISABLE_EMAIL_QUERY_KEY = 'two-factor-disable-email';
const TWO_FACTOR_DISABLE_EMAIL_CONFIRM_QUERY_KEY = 'two-factor-disable-email-confirm';
const TWO_FACTOR_ENABLE_TOTP_QUERY_KEY = 'two-factor-enable-totp';
const TWO_FACTOR_ENABLE_TOTP_CONFIRM_QUERY_KEY = 'two-factor-enable-totp-confirm';
const TWO_FACTOR_DISABLE_TOTP_QUERY_KEY = 'two-factor-disable-totp';
const TwoFactorConfiguration = () => {
    const { t } = useTranslation();
    const userData = useUserData();
    const updateUserData = useUpdateUserData();
    const { getSuccessToast } = useCustomToast();
    const notifyChange = (message) => {
        getSuccessToast({
            title: message,
            id: uniqueId(),
        });
    };
    const userCanDisableTwoFactorMethod = canDisableTwoFactorMethod(userData);
    const { mutate: enableEmail2FA, isSuccess: email2FAEnablingSuccess, reset: reset2FAEnableEmail, } = useMutation({
        mutationKey: [TWO_FACTOR_ENABLE_EMAIL_QUERY_KEY],
        mutationFn: apiEnableEmail2FA,
    });
    const { mutate: confirmEmail2FA, isError: confirmEmail2FAIsError } = useMutation({
        mutationKey: [TWO_FACTOR_ENABLE_EMAIL_CONFIRM_QUERY_KEY],
        mutationFn: apiConfirmEmail2FA,
        onSuccess: () => {
            updateUserData((userData) => ({
                ...userData,
                twoFactorEmailEnabled: true,
            }));
            reset2FAEnableEmail();
            notifyChange(t('security.two_factor.email.enabled_success'));
        },
    });
    const { mutate: disableEmail2FA, isSuccess: email2FADisablingSuccess, reset: reset2FADisableEmail, } = useMutation({
        mutationKey: [TWO_FACTOR_DISABLE_EMAIL_QUERY_KEY],
        mutationFn: apiDisableEmail2FA,
    });
    const { mutate: confirmDisableEmail2FA, isError: confirmDisableEmail2FAIsError, } = useMutation({
        mutationKey: [TWO_FACTOR_DISABLE_EMAIL_CONFIRM_QUERY_KEY],
        mutationFn: apiConfirmDisableEmail2FA,
        onSuccess: () => {
            updateUserData((userData) => ({
                ...userData,
                twoFactorEmailEnabled: false,
            }));
            reset2FADisableEmail();
            notifyChange(t('security.two_factor.email.disabled_success'));
        },
    });
    const { mutate: enableTotp2FA, data: totp2FAEnablingData, isSuccess: totp2FAEnablingSuccess, reset: reset2FAEnableTotp, } = useMutation({
        mutationKey: [TWO_FACTOR_ENABLE_TOTP_QUERY_KEY],
        mutationFn: apiEnableTotp2FA,
    });
    const { mutate: confirmTotp2FA, isError: confirmTotp2FAIsError } = useMutation({
        mutationKey: [TWO_FACTOR_ENABLE_TOTP_CONFIRM_QUERY_KEY],
        mutationFn: apiConfirmTotp2FA,
        onSuccess: () => {
            updateUserData((userData) => ({
                ...userData,
                twoFactorTotpEnabled: true,
            }));
            reset2FAEnableTotp();
            notifyChange(t('security.two_factor.totp.enabled_success'));
        },
    });
    const { mutate: disableTotp2FA, isError: disableTotp2FAIsError } = useMutation({
        mutationKey: [TWO_FACTOR_DISABLE_TOTP_QUERY_KEY],
        mutationFn: apiDisableTotp2FA,
        onSuccess: () => {
            updateUserData((userData) => ({
                ...userData,
                twoFactorTotpEnabled: false,
            }));
            notifyChange(t('security.two_factor.email.disabled_success'));
        },
    });
    const updateInProgress = totp2FAEnablingSuccess ||
        email2FAEnablingSuccess ||
        email2FADisablingSuccess;
    return (<VStack align="start">
      <Heading as="h3" variant="h3">
        {t('security.two_factor.title')}
      </Heading>
      {userData.twoFactorRequired && (<Text color="red.500">{t('security.two_factor.required_by_site')}</Text>)}
      <Divider />
      <Heading as="h4" variant="h4">
        {t('security.two_factor.totp.title')}
      </Heading>
      <Text>
        {t(`security.two_factor.totp.${userData.twoFactorTotpEnabled ? 'enabled' : 'disabled'}`)}
      </Text>
      {!userData.twoFactorTotpEnabled && (<Button disabled={updateInProgress} onClick={() => enableTotp2FA()}>
          {t('security.two_factor.totp.enable')}
        </Button>)}
      {!userData.twoFactorTotpEnabled &&
            totp2FAEnablingSuccess &&
            totp2FAEnablingData && (<img alt="QR code" src={totp2FAEnablingData.qrCode}/>)}
      {!userData.twoFactorTotpEnabled && totp2FAEnablingSuccess && (<TwoFactorForm confirm={confirmTotp2FA} isError={confirmTotp2FAIsError} fieldLabel={t('security.two_factor.code.totp')}/>)}
      {userData.twoFactorTotpEnabled && userCanDisableTwoFactorMethod && (<>
          <Text>{t('security.two_factor.totp.disable')}</Text>
          <TwoFactorForm confirm={disableTotp2FA} isError={disableTotp2FAIsError} fieldLabel={t('security.two_factor.code.totp')}/>
        </>)}
      <Divider />
      <Heading as="h4" variant="h4">
        {t('security.two_factor.email.title')}
      </Heading>
      <Text>
        {t(`security.two_factor.email.${userData.twoFactorEmailEnabled ? 'enabled' : 'disabled'}`)}
      </Text>
      {(!userData.twoFactorEmailEnabled || userCanDisableTwoFactorMethod) && (<Button disabled={updateInProgress} onClick={() => userData.twoFactorEmailEnabled
                ? disableEmail2FA()
                : enableEmail2FA()}>
          {t(`security.two_factor.email.${userData.twoFactorEmailEnabled ? 'disable' : 'enable'}`)}
        </Button>)}
      {!userData.twoFactorEmailEnabled && email2FAEnablingSuccess && (<TwoFactorForm confirm={confirmEmail2FA} isError={confirmEmail2FAIsError} fieldLabel={t('security.two_factor.code.email', {
                email: userData.email,
            })}/>)}
      {userData.twoFactorEmailEnabled && email2FADisablingSuccess && (<TwoFactorForm confirm={confirmDisableEmail2FA} isError={confirmDisableEmail2FAIsError} fieldLabel={t('security.two_factor.code.email', {
                email: userData.email,
            })}/>)}
    </VStack>);
};
export default TwoFactorConfiguration;
