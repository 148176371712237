import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useAnalytics, { ANALYTICS_MAP_BUTTONS, ANALYTICS_MAP_STICKY, } from 'analytics/useAnalytics';
import { useFilters, useToggleFilter } from 'hooks/contexts/useFiltersContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { filtersKey } from 'utils/filters';
import PopoverMenu from './PopoverMenu';
const CadastreMenu = () => {
    const { t } = useTranslation();
    const selectedTown = useSelectedTown();
    const { trackEvent } = useAnalytics();
    const filters = useFilters();
    const toggleFilter = useToggleFilter();
    return (<PopoverMenu label={t('toolbar.cadastre')} id="layers_button_cadastre" isDisabled={!selectedTown} isActive={!!filters[filtersKey.CADASTRE] || !!filters[filtersKey.OLD_CADASTRE]} menuItems={[
            {
                label: t('toolbar.cadastre'),
                isActive: !!filters[filtersKey.CADASTRE],
                handleClick: () => {
                    trackEvent({
                        category: ANALYTICS_MAP_STICKY,
                        action: `Cadastre ${filters[filtersKey.CADASTRE] ? 'off' : 'on'} carte`,
                    });
                    toggleFilter(filtersKey.CADASTRE);
                },
            },
            {
                label: t('toolbar.old_cadastre'),
                isActive: !!filters[filtersKey.OLD_CADASTRE],
                handleClick: () => {
                    trackEvent({
                        category: ANALYTICS_MAP_BUTTONS,
                        action: 'Ancien cadastre',
                    });
                    toggleFilter(filtersKey.OLD_CADASTRE);
                },
            },
        ]} selectDefault={(filter) => toggleFilter(filter, true)} borderLeftRadius={0} showCheck/>);
};
export default CadastreMenu;
