import * as React from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Center, Flex, FormControl, FormErrorMessage, HStack, Icon, Image, Input, Link, Spinner, Stack, Text, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CRM } from 'analytics/useAnalytics';
import DeleteButton from 'components/buttons/DeleteButton';
import { useSectionHash } from 'hooks/contexts/useTabsContext';
import DownloadIcon from 'images/icons/Download';
import { resolver } from './DocumentList';
const ACCEPTED_PHOTO_FORMATS = '.gif,.jpg,.jpeg,.png';
const PhotoList = ({ projectId, plotId, photos, deletePhoto, addPhoto, photosFiles, isLoadingPhoto, isUserOwner = true, }) => {
    const { trackEvent } = useAnalytics();
    const sectionHash = useSectionHash();
    const { t } = useTranslation();
    const defaultValues = { plotId: plotId, projectId: projectId, file: null };
    const { register, handleSubmit, formState: { errors }, watch, } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues,
        resolver,
    });
    const onSubmit = (data) => addPhoto(data);
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === 'file' && type === 'change') {
                return handleSubmit(onSubmit)();
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);
    return (<Stack width="full" spacing={3} data-cy="photo_list">
      {isUserOwner ? (<Box as="form">
          <FormControl isInvalid={!!errors?.file}>
            <Box borderRadius="6px" border="1px dashed" borderColor={!errors?.file ? 'colorMode.font700' : 'red.500'} _hover={{ borderColor: 'colorMode.font900' }}>
              <Input {...register('file')} type="file" height="100px" accept={ACCEPTED_PHOTO_FORMATS} opacity="0" cursor="pointer" position="absolute" data-cy="plot_crm_image_upload"/>

              <VStack justifyContent="center" alignItems="center" padding={6}>
                <Center backgroundColor="colorMode.base" width="40px" height="40px" borderRadius="32px">
                  <Icon as={DownloadIcon} width={5} height={5} fill="currentColor" alignSelf="center"/>
                </Center>

                <Text textStyle="paragraph" color="colorMode.font700" fontWeight={500} onClick={() => {
                trackEvent({
                    category: ANALYTICS_CRM,
                    action: 'Télécharger une photo' + sectionHash === 'my_crm'
                        ? ''
                        : ' fiche projet',
                });
            }}>
                  <Text as="span" color="red.500" marginRight={1}>
                    {t('tabs.photo.download_file')}
                  </Text>
                  {t('tabs.photo.or_dnd_files')}
                </Text>
                <FormErrorMessage>{errors?.file?.message}</FormErrorMessage>
              </VStack>
            </Box>
          </FormControl>
        </Box>) : (photos?.length === 0 && (<Box textAlign="center">{t('global.no_photo')}</Box>))}

      {photos?.length > 0 && (<HStack alignItems="flex-start" flexWrap="wrap" spacing={0} shouldWrapChildren>
          {photos.map((photo) => {
                return isLoadingPhoto ? (<Spinner key={photo.id}/>) : (<Link key={photo.id} display="block" href={photosFiles?.[photo.id]} isExternal download={photo.name} marginTop={2} marginLeft={2} data-cy="plot_crm_image_file">
                <Box height="120px" width="140px">
                  <Image src={photosFiles?.[photo.id]} alt={photo.name} height="100%" width="100%" objectFit="contain" border="1px solid gray" borderTopLeftRadius="lg" borderTopRightRadius="lg"/>
                </Box>
                <Stack backgroundColor="colorMode.base" borderBottomLeftRadius="lg" borderBottomRightRadius="lg" spacing={0}>
                  <Flex padding={3} justifyContent="space-between" alignItems="center">
                    <Text textStyle="small" maxWidth="90px" noOfLines={1} fontWeight={500} textOverflow="ellipsis" data-cy="image_plot_name">
                      {photo.name}
                    </Text>
                    <Box onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}>
                      <DeleteButton id="plot_image_delete" mode="icon" confirmText={photo.plotId
                        ? t('tabs.photo.delete_plot_confirm')
                        : t('tabs.photo.delete_confirm')} handleDelete={() => {
                        deletePhoto({ document: photo });
                        trackEvent({
                            category: ANALYTICS_CRM,
                            action: 'Supprimer une photo' + sectionHash === 'my_crm'
                                ? ''
                                : ' fiche projet',
                        });
                    }} size="sm"/>
                    </Box>
                  </Flex>
                  {projectId && photo.plotId != 0 && photo.plotNumber && (<Text textAlign="center" as="div" fontSize="11px" padding={1} backgroundColor="colorMode.background800" borderBottomLeftRadius="lg" borderBottomRightRadius="lg">
                      {photo.plotNumber}
                    </Text>)}
                </Stack>
              </Link>);
            })}
        </HStack>)}
    </Stack>);
};
export default PhotoList;
