import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get, post } from 'api/client';
import { QUERY_KEY as CONTACT_LIST_QUERY_KEY } from 'hooks/crm/useContactList';
import { QUERY_KEY as USER_PROJECT_QUERY_KEY } from 'hooks/crm/useProject';
import { QUERY_KEY as USER_PLOTS_QUERY_KEY } from 'hooks/crm/useUserPlotsList';
import { QUERY_KEY as USER_PROJECTS_QUERY_KEY } from 'hooks/crm/useUserProjectsList';
import { QUERY_KEY as PLOT_SEARCH_DETAIL_QUERY_KEY } from 'hooks/plotsSearch/usePlotsSearchDetails';
import { IOEmpty } from 'types/api';
import { QUERY_KEY as PLOT_HISTORY_QUERY_KEY } from './usePlotHistory';
import { PROJECT_QUERY_KEY as USER_STATS_CRM_PLOT_QUERY_KEY } from './useUserStatisticsCRM';
export const IOPlotStatus = iots.number;
const fetchPlotStatus = (plotId) => () => get(IOPlotStatus, `legacy/${plotId}/status`);
const updatePlotStatus = ({ plotId, status }) => post(IOEmpty, `legacy/${plotId}/update-status`, { status });
export const getPlotStatusQueryKey = (plotId) => ['plot-status', plotId];
const usePlotStatus = (plotId) => {
    const queryClient = useQueryClient();
    const { data: plotStatus, isInitialLoading: isLoading } = useQuery({
        queryKey: getPlotStatusQueryKey(plotId),
        queryFn: fetchPlotStatus(plotId),
        enabled: !!plotId,
    });
    const { mutate: changePlotStatus, isPending: isUpdating } = useMutation({
        mutationFn: updatePlotStatus,
        onMutate: async (newStatus) => {
            await queryClient.cancelQueries({
                queryKey: getPlotStatusQueryKey(plotId),
            });
            const previousStatus = queryClient.getQueryData(getPlotStatusQueryKey(plotId)) ?? 0;
            queryClient.setQueryData(getPlotStatusQueryKey(plotId), () => {
                return { ...newStatus };
            });
            return { previousStatus };
        },
        onError: (err, newFilter, context) => {
            queryClient.setQueryData(getPlotStatusQueryKey(plotId), context.previousStatus);
        },
        onSuccess: (_data, { plotId }) => {
            queryClient.invalidateQueries({ queryKey: getPlotStatusQueryKey(plotId) });
            queryClient.invalidateQueries({ queryKey: USER_PLOTS_QUERY_KEY });
            queryClient.invalidateQueries({ queryKey: CONTACT_LIST_QUERY_KEY });
            queryClient.invalidateQueries({ queryKey: USER_PROJECTS_QUERY_KEY });
            queryClient.invalidateQueries({
                queryKey: [USER_STATS_CRM_PLOT_QUERY_KEY],
            });
            queryClient.invalidateQueries({
                queryKey: [PLOT_HISTORY_QUERY_KEY, { plotId: plotId }],
            });
            // update projects related to this plot
            const projects = queryClient.getQueriesData({
                queryKey: [USER_PROJECT_QUERY_KEY],
            });
            projects.forEach((projectData) => {
                const [queryKey, project] = projectData;
                if (project && project.plots.find((plot) => plot.id === plotId)) {
                    queryClient.invalidateQueries({ queryKey: queryKey });
                }
            });
            queryClient.invalidateQueries({
                queryKey: [PLOT_SEARCH_DETAIL_QUERY_KEY],
            });
        },
    });
    return { plotStatus, isLoading, changePlotStatus, isUpdating };
};
export default usePlotStatus;
