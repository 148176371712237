import { filtersKey } from 'utils/filters';
export const CST_POSITIONS = {
    [filtersKey.OLD_CADASTRE]: 1,
    [filtersKey.PLU_GPU]: 2,
    PLU: 3,
    LABELS: 4,
    [filtersKey.THEMATIC_MAPS]: 5,
    [filtersKey.PLOTS]: 7,
    [filtersKey.TOWNS]: 8,
    [filtersKey.EPCI]: 10,
    [filtersKey.TOWN_AND_NEIGHBORHOODS]: 12,
    [`${filtersKey.TOWN_AND_NEIGHBORHOODS}_2nd`]: 13,
    [filtersKey.SCOT]: 14,
    ABF_FILTERS: 15,
    [filtersKey.PPR_EARTHQUAKE_HAZARD]: 16,
    [filtersKey.TERMITES]: 17,
    ROAD_RAILWAY_FILTERS: 18,
    [filtersKey.WAREHOUSES_AND_DENSE_LOGISTICS_AREAS]: 20,
    RAILWAY_NETWORK_FILTERS: 22,
    COMMERCIAL_BUILDINGS_FILTERS: 23,
    [filtersKey.HAZMAT_TRANSPORT_PIPELINES]: 24,
    [filtersKey.RADIOACTIVE_GAS]: 25,
    [filtersKey.THEMATIC_MAP_RENT_APARTMENT]: 26,
    [filtersKey.THEMATIC_MAP_RENT_HOUSE]: 27,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2017]: 28,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2018]: 29,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2019]: 30,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2020]: 31,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2021]: 32,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2022]: 33,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_LAST]: 40,
    [filtersKey.WIND_TOWER]: 41,
    [filtersKey.WIND_POTENTIAL]: 42,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_ABANDONED_MOTORWAYS]: 43,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_PARKING_SPACE_500]: 44,
    [filtersKey.AVERAGE_SOLAR_IRRADIATION]: 45,
    [filtersKey.BIOTOP_PROTECTION]: 46,
    [filtersKey.BIOLOGICAL_RESERVE]: 47,
    [filtersKey.HUNTING_RESERVE]: 48,
    [filtersKey.NATIONAL_RESERVE]: 49,
    [filtersKey.NATURA_2000_SIC]: 50,
    [filtersKey.NATURA_2000_ZPS]: 51,
    [filtersKey.NATIONAL_PARK]: 52,
    [filtersKey.MARIN_PARK]: 53,
    [filtersKey.COASTLINE_CONSERVATOIRE]: 54,
    [filtersKey.REGIONAL_NATURAL_SPACE_CONSERVATOIRE]: 55,
    [filtersKey.BIOSPHERE_RESERVE]: 56,
    [filtersKey.REGIONAL_RESERVE]: 57,
    [filtersKey.REGIONAL_PARK]: 58,
    [filtersKey.ZNIEFF_TYPE1]: 59,
    [filtersKey.ZNIEFF_TYPE2]: 60,
    [filtersKey.PUBLIC_FOREST]: 61,
    [filtersKey.COMPENSATORY_MEASURES_FOR_BIODIVERSITY_DAMAGE]: 62,
    [filtersKey.LEVEL_CURVE]: 63,
    [filtersKey.INCLINE_10]: 64,
    [filtersKey.INCLINE_30]: 65,
    [filtersKey.WIND_POTENTIAL_140]: 66,
    [filtersKey.WIND_POTENTIAL_160]: 67,
    [filtersKey.WIND_CONSTRAINT_ROAD]: 68,
    [filtersKey.WIND_CONSTRAINT_INCLINE]: 69,
    [filtersKey.ELECTRICAL_NETWORKS_HIGH_VOLTAGE_LINE]: 70,
    [filtersKey.ELECTRICAL_NETWORKS_UNDERGROUND_LINE]: 71,
    [filtersKey.ELECTRICAL_NETWORKS_ELECTRICITY_PYLON]: 72,
    [filtersKey.RADAR]: 73,
    [filtersKey.WETLAND_AREA_POTENTIALLY]: 74,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_LAND_UNIT_AREA_PARKING_SPACE_500]: 75,
    [filtersKey.WIND_LARGE_FRENCH_SITE]: 76,
    [filtersKey.WIND_ZAER_EXCLUSION]: 77,
    [filtersKey.WIND_CONSTRAINT_HOUSING]: 78,
    [filtersKey.WIND_CONSTRAINT_RAILWAY]: 79,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_PRAIRIE]: 80,
    [filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU]: 81,
    ZAER_FILTERS: 82,
    WIND_BIRD_FILTERS: 83,
    AERONAUTIC_FILTERS: 84,
    [filtersKey.WIND_UNESCO_NATURAL]: 85,
    [filtersKey.SRCE]: 86,
    [filtersKey.DRINKING_WATER_CAPTURE]: 87,
    [filtersKey.AERONAUTIC_SERVITUDE]: 88,
    [filtersKey.AERONAUTIC_SERVITUDE + '_2']: 89,
    [filtersKey.OPTICAL_FIBER]: 90,
    [filtersKey.OPTICAL_FIBER_CENTER]: 91,
    // Position 100 - 160 réservé aux PLU adjacents
};
// Certain filtre sont regroupés (ZAER_FILTERS, WIND_BIRD_FILTERS, AERONAUTIC_FILTERS)
export const LAYERS_WITH_OPACITY = {
    ...CST_POSITIONS,
    [filtersKey.OPTICAL_FIBER]: false,
    [filtersKey.ABF_NO_BUILDING_ZONE]: 15,
    [filtersKey.ABF_STRONGLY_REGULATED_AREA]: 15,
    [filtersKey.ABF_ADVICE_REQUIRED_IF_VISIBLE]: 15,
    [filtersKey.ABF_ADVICE_REQUIRED]: 15,
    [filtersKey.ABF_REGIONAL_PREFECT_ADVICE_REQUIRED]: 15,
    [filtersKey.ROAD_NOISE]: 18,
    [filtersKey.RAILWAY_NOISE]: 18,
    [filtersKey.RAILWAY_NETWORKS_FRANCE_RAILWAY_NETWORK]: 22,
    [filtersKey.RAILWAY_NETWORKS_IDF_RAILWAY_NETWORK]: 22,
    [filtersKey.COMMERCIAL_LINEAR]: 23,
    [filtersKey.URBAN_INTENSITY_PLACES]: 23,
    [filtersKey.ZAER_SOLAR_PV_GROUND]: 82,
    [filtersKey.ZAER_SOLAR_PV_ROOF]: 82,
    [filtersKey.ZAER_SOLAR_PV_SHADOW_PARKING]: 82,
    [filtersKey.ZAER_SOLAR_THERMIC]: 82,
    [filtersKey.ZAER_WIND]: 82,
    [filtersKey.ZAER_GEOTHERMAL]: 82,
    [filtersKey.ZAER_BIOMETHANE]: 82,
    [filtersKey.ZAER_BIOMASS]: 82,
    [filtersKey.ZAER_HYDROELECTRICITY]: 82,
    [filtersKey.WIND_BIRD_AVIFAUNA]: 83,
    [filtersKey.WIND_BIRD_BAT]: 83,
    [filtersKey.AERONAUTIC_ZONE_CRT]: 84,
    [filtersKey.AERONAUTIC_ZONE_P]: 84,
    [filtersKey.AERONAUTIC_ZONE_ZMT]: 84,
};
