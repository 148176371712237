import * as React from 'react';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Translation, useTranslation } from 'react-i18next';
import { CloseIcon } from '@chakra-ui/icons';
import { Box, Button, Center, Heading, HStack, IconButton, Spinner, Text, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CRM } from 'analytics/useAnalytics';
import ContactButton from 'components/buttons/ContactButton';
import DeleteButton from 'components/buttons/DeleteButton';
import { HighlightPlotsSwitch } from 'components/buttons/HighlightPlotsSwitch';
import ModalButton from 'components/buttons/ModalButton';
import PlotButton from 'components/buttons/PlotButton';
import PlotSelect from 'components/inputs/PlotSelect';
import StatusSelect from 'components/inputs/StatusSelect';
import BindContactDrawer from 'components/modals/contactModal/BindContactDrawer';
import Table from 'components/table/Table';
import CustomTooltip from 'components/tooltips/CustomTooltip';
import { useOpenPlotModal } from 'hooks/contexts/useModalContext';
import useContact from 'hooks/crm/useContact';
import usePlotContact from 'hooks/crm/usePlotContact';
import useCustomToast from 'hooks/useCustomToast';
import MapIcon from 'images/icons/Map';
import { getPlotContactStatusFromCode, PLOT_CONTACT_STATUS_OPTIONS, } from 'utils/constants';
const getColumnHeader = (column) => (<Translation>
    {(t) => (<Text align="center">
        {t(`tabs.project.columns.plot_contacts.${column}`)}
      </Text>)}
  </Translation>);
const initialSort = [
    {
        id: 'lastname',
        desc: false,
    },
];
const getAllowedPlotsForContact = (contactId, project, projectPlots, projectContacts) => {
    let contactPlotsIds = [];
    projectContacts.forEach((projectContact) => {
        if (projectContact.id === contactId && projectContact?.plots.length > 0) {
            contactPlotsIds = [
                ...contactPlotsIds,
                ...projectContact.plots.map((plot) => plot.id),
            ];
        }
    });
    return projectPlots?.filter((plot) => !contactPlotsIds.includes(plot.id));
};
const ProjectContactsTable = ({ project, projectContacts, projectPlots, landPointId, isLoading, isUserOwner = true, }) => {
    const { t } = useTranslation();
    const { updatePlotsContactStatus } = usePlotContact();
    const { trackEvent } = useAnalytics();
    const openPlotModal = useOpenPlotModal();
    const { addContactToProject, removeContactFromProject, removeContactFromPlots, removeContactFromProjectVariables, isRemovingContactFromProject, addContactToPlot, } = useContact();
    const currentDeletedContact = removeContactFromProjectVariables?.contactId;
    const { getSuccessToast } = useCustomToast();
    const columns = useMemo(() => {
        return [
            {
                id: 'columns',
                columns: [
                    {
                        accessorKey: 'lastname',
                        header: () => getColumnHeader('lastname'),
                        cell: (info) => {
                            return !landPointId ? (<ContactButton contactId={info.row.original.id} analyticDetail={ANALYTICS_CRM} analyticOrigin="Ouvrir fiche contact fiche projet" id="contact_button"/>) : (<Text>
                  {info.row.original.firstname} {info.row.original.lastname}
                </Text>);
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        id: 'status',
                        accessorFn: (row) => getPlotContactStatusFromCode(row.status),
                        header: () => getColumnHeader('status'),
                        cell: (info) => {
                            const projectPlotIds = Object.values(info.row.original.plots).map((plot) => {
                                return plot.id;
                            });
                            return info.row.original.id && projectPlotIds.length > 0 ? (isUserOwner ? (<StatusSelect data={PLOT_CONTACT_STATUS_OPTIONS} onChange={(value) => {
                                    updatePlotsContactStatus({
                                        plotIds: projectPlotIds,
                                        plotContactId: info.row.original.id,
                                        status: value.value,
                                    });
                                    trackEvent({
                                        category: ANALYTICS_CRM,
                                        action: "Statut du propriétaire dans une parcelle d'un projet",
                                    });
                                }} value={info.row.original.status}/>) : (getPlotContactStatusFromCode(info.row.original.status))) : null;
                        },
                        meta: {
                            minW: '155px',
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'plots',
                        header: () => getColumnHeader('cadastreId'),
                        cell: (info) => {
                            const methods = useForm({});
                            const plots = info.getValue();
                            return (<>
                  {isUserOwner && (<FormProvider {...methods}>
                      <form>
                        <PlotSelect name="plot" plots={getAllowedPlotsForContact(info.row.original.id, project, projectPlots, projectContacts)} disabled={isRemovingContactFromProject} onChange={({ plot }) => {
                                        trackEvent({
                                            category: ANALYTICS_CRM,
                                            action: 'Ajouter un contact fiche projet',
                                        });
                                        addContactToPlot({
                                            plotId: plot.value,
                                            contactId: info.row.original.id,
                                            townId: plot.townId,
                                            plotProjectsIds: [project?.id],
                                            fromProjectId: project.id,
                                            duplicateDataFromPlotId: Object.values(plots).length > 0
                                                ? Object.values(plots)[0].id
                                                : null,
                                        }, {
                                            onSuccess: () => {
                                                methods.reset();
                                            },
                                        });
                                    }}/>
                      </form>
                    </FormProvider>)}
                  <VStack spacing="2px">
                    {Object.values(plots).map((plot) => {
                                    return (<HStack key={plot.id} justifyContent="flex-end">
                          {!landPointId ? (<PlotButton lat={plot.lat} lng={plot.lng} townId={plot.townId} variant="link-primary" padding={1} height="auto" plotId={plot.id}>
                              {plot.plotNumber}
                            </PlotButton>) : (<ModalButton onClick={() => {
                                                openPlotModal({
                                                    townshipId: plot.townId,
                                                    plotId: plot.id,
                                                });
                                            }}>
                              {plot.plotNumber}
                            </ModalButton>)}
                          {isUserOwner && (<IconButton size="xs" variant="link-primary" isDisabled={isRemovingContactFromProject} aria-label="delete" icon={<CloseIcon fill="currentColor"/>} onClick={() => {
                                                removeContactFromPlots({
                                                    contactId: info.row.original.id,
                                                    plotIds: [plot.id],
                                                    townId: plot.townId,
                                                    plotProjectsIds: [project?.id],
                                                    fromProjectId: project.id,
                                                });
                                                trackEvent({
                                                    category: ANALYTICS_CRM,
                                                    action: 'Supprimer un contact fiche projet',
                                                });
                                            }}/>)}
                        </HStack>);
                                })}
                  </VStack>
                </>);
                        },
                    },
                    {
                        accessorKey: 'nbPlots',
                        accessorFn: (row) => {
                            const plots = row.plots;
                            const projectPlotIds = [];
                            let surfaceTotal = 0;
                            projectPlots?.forEach((plot) => {
                                projectPlotIds.push(plot.id);
                                surfaceTotal += plot.surface;
                            });
                            let surface = 0;
                            Object.values(plots).forEach((plot) => {
                                if (projectPlotIds.includes(plot.id)) {
                                    surface += plot.surface;
                                }
                            });
                            return surfaceTotal
                                ? Math.round((surface / surfaceTotal) * 100)
                                : 0;
                        },
                        header: () => getColumnHeader('surface'),
                        cell: (info) => info.getValue() + ' %',
                        enableGlobalFilter: false,
                        filterFn: 'inNumberRange',
                    },
                    {
                        accessorKey: 'mobile',
                        header: () => getColumnHeader('mobile'),
                        accessorFn: (row) => {
                            if (!row.mobile)
                                return t('table.empty_column');
                            return row.mobile;
                        },
                        cell: ({ row }) => row.original.mobile,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'landline',
                        header: () => getColumnHeader('landline'),
                        accessorFn: (row) => {
                            if (!row.landline)
                                return t('table.empty_column');
                            return row.landline;
                        },
                        cell: ({ row }) => row.original.landline,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'action',
                        header: () => (<CustomTooltip label={t('global.actions.highlight_plots')}>
                <Box color="colorMode.font900">
                  <MapIcon width="30px" height="30px" fill="currentColor"/>
                </Box>
              </CustomTooltip>),
                        cell: ({ row }) => {
                            const plotsInfo = Object.values(row.original.plots);
                            return (<Box textAlign="center">
                  <HighlightPlotsSwitch isDisabled={plotsInfo.length === 0} plotsInfo={plotsInfo} highlightLegend={t('legends.highlighted_plots_of', {
                                    owner: `${row.original.firstname} ${row.original.lastname}`,
                                })}/>
                </Box>);
                        },
                        enableGlobalFilter: false,
                        enableColumnFilter: false,
                        enableSorting: false,
                    },
                    {
                        accessorKey: 'id',
                        header: () => '',
                        cell: (info) => {
                            const plotIds = Object.values(info.row.original.plots).map((plot) => plot.id);
                            return (<HStack justifyContent="center">
                  <DeleteButton mode="icon" id="delete_contact" handleDelete={() => {
                                    if (plotIds.length > 0) {
                                        removeContactFromPlots({
                                            contactId: info.getValue(),
                                            plotIds: plotIds,
                                            plotProjectsIds: [project?.id],
                                            townId: projectPlots[0].townId,
                                        }, {
                                            onSuccess: () => {
                                                getSuccessToast({
                                                    title: t('tabs.contact.delete_success', {
                                                        count: 1,
                                                    }),
                                                    id: 'delete_contact',
                                                });
                                            },
                                        });
                                    }
                                    else {
                                        removeContactFromProject({
                                            contactId: info.getValue(),
                                            projectId: project?.id,
                                            townshipId: `${projectPlots[0].townId}`,
                                        });
                                    }
                                }} isLoading={currentDeletedContact === info.row.original.id}/>
                </HStack>);
                        },
                        enableGlobalFilter: false,
                        enableColumnFilter: false,
                        enableSorting: false,
                    },
                ],
            },
        ];
    }, [
        project,
        removeContactFromProject,
        projectContacts,
        isRemovingContactFromProject,
        currentDeletedContact,
    ]);
    if (isLoading) {
        return (<Center height="100%">
        <Spinner size="lg"/>
      </Center>);
    }
    return (<Box width="full">
      <HStack paddingBottom={4}>
        <Heading as="h4" variant="h4">
          {t('tabs.project.contacts')}
        </Heading>
        {isUserOwner && (<BindContactDrawer triggerItem={(onOpen) => (<Button size="sm" variant="outline" onClick={onOpen} isDisabled={isLoading} data-cy="add_contact_project">
                {t('tabs.project.add_contact')}
              </Button>)} onSubmit={(contactId, onClose) => {
                addContactToProject({
                    townshipId: `${projectPlots[0].townId}`,
                    projectId: project.id,
                    contactId: contactId,
                }, {
                    onSuccess: onClose,
                });
            }}/>)}
      </HStack>
      {projectContacts && (<Table id={`project-${project?.id}-plot-contacts`} columns={columns} data={projectContacts || []} isLoading={isLoading} initialSort={initialSort} disabled={isRemovingContactFromProject} withExport={false} initialColumnVisibility={{
                action: !landPointId,
                id: isUserOwner,
            }}/>)}
    </Box>);
};
export default ProjectContactsTable;
