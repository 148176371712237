import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Icon, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Portal, useDisclosure, } from '@chakra-ui/react';
import TooltipIcon from 'images/icons/Tooltip';
const InfoPopover = ({ children, withTitle = true, closeOnOut = false, }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t } = useTranslation();
    return (<Popover onClose={onClose} isOpen={isOpen}>
      <PopoverTrigger>
        <Box onMouseOver={onOpen} onMouseOut={closeOnOut ? onClose : () => ({})} borderRadius="full" boxShadow="md" display="flex" justifyContent="center" alignItems="center" width="16px" height="16px" backgroundColor="white" cursor="pointer">
          <Icon as={TooltipIcon}/>
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent margin={0}>
          <PopoverArrow />
          <PopoverCloseButton top="2px"/>
          {withTitle && (<PopoverHeader fontWeight={500}>
              {t('tabs.plots.section.neighborhood.good_to_know')}
            </PopoverHeader>)}
          <PopoverBody color="colorMode.font800" fontSize="11px">
            {children}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>);
};
export default InfoPopover;
