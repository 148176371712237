import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';
import { useClearPlotSummary } from 'hooks/contexts/useLegendContext';
import { useConfirmCity } from 'hooks/contexts/useLocationContext';
import useNavigateToTown from 'hooks/navigation/useNavigateToTown';
import ConfirmDialog from './ConfirmDialog';
const ConfirmCity = () => {
    const { t } = useTranslation();
    const navigateToTown = useNavigateToTown();
    const { confirmCity, setConfirmCity } = useConfirmCity();
    const clearPlotSummary = useClearPlotSummary();
    const handleConfirmCityChange = (confirm) => {
        if (confirm && !!confirmCity) {
            clearPlotSummary();
            const lat = confirmCity.coordinates.lat().toString();
            const lng = confirmCity.coordinates.lng().toString();
            navigateToTown({
                lat,
                lng,
                centerMapOnLat: lat,
                centerMapOnLng: lng,
            });
        }
        setConfirmCity(null);
    };
    if (confirmCity?.townName)
        return (<ConfirmDialog header={t('tabs.confirm_city.header')} onConfirm={() => handleConfirmCityChange(true)} onCancel={() => handleConfirmCityChange(false)} confirmLabel={t('global.actions.validate')}>
        <Text>
          {t('tabs.confirm_city.body', { name: confirmCity?.townName })}
        </Text>
      </ConfirmDialog>);
    return null;
};
export default ConfirmCity;
