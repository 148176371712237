import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
import useCurrentSite from 'hooks/useCurrentSite';
import { IOTown } from 'types/api';
import useCustomToast from './useCustomToast';
const fetchTownshipByCoordinates = (lat, lng, siteId) => () => get(IOTown, `township-by-coordinates/${lat}/${lng}`, { siteId }, false, {}, () => false);
export const QUERY_KEY = 'town-by-coordinates';
const useTownByCoordinates = ({ coordinates, queryKey = QUERY_KEY, successCallback, errorCallback = () => { }, enabled = true, }) => {
    const lat = coordinates?.lat().toString();
    const lng = coordinates?.lng().toString();
    const { t } = useTranslation();
    const { getInfoToast } = useCustomToast();
    const { currentSite } = useCurrentSite();
    const queryResult = useQuery({
        queryKey: [queryKey, lat, lng],
        queryFn: fetchTownshipByCoordinates(lat, lng, currentSite?.legacyId),
        enabled: !!lat && !!lng && enabled,
        staleTime: Infinity,
        retry: false,
        refetchOnWindowFocus: false,
        meta: {
            onSuccess: (data) => {
                successCallback && successCallback(data);
            },
            onError: (error) => {
                if (error?.status === 403) {
                    const id = 'forbidden-town-error';
                    getInfoToast({
                        id,
                        title: t('error.restricted_town.title'),
                        variant: 'subtle',
                        description: (<>
                <Text marginTop={2}>
                  {t('error.restricted_town.description')}
                </Text>
                <Text marginTop={2}>
                  {t('error.restricted_town.greetings')}
                </Text>
              </>),
                        status: 'info',
                        position: 'top',
                        duration: null,
                        isClosable: true,
                    });
                    errorCallback();
                }
                else {
                    getInfoToast({
                        id: 'no_town_found',
                        description: error.detail,
                        position: 'top',
                    });
                }
            },
        },
    });
    return queryResult;
};
export default useTownByCoordinates;
