import * as React from 'react';
import { useCallback, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as iots from 'io-ts';
import { get } from 'api/client';
import CellTowerInfoWindow, { IOCellTowerInfo, } from 'components/infoWindow/CellTowerInfoWindow';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import telecomImg from 'images/POIImages/Pin_Antenne_Relais.png';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOCellTowerMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        id: iots.string,
        systemIds: iots.array(iots.number),
        operatorIds: iots.array(iots.number),
        generationIds: iots.array(iots.number),
        statusIds: iots.array(iots.number),
    }),
], 'IOCellTowerMarkerItem');
const fetchCellTower = (townId, townScot, selectedTown) => () => get(iots.array(IOCellTowerMarkerItem), `${selectedTown?.department}/cell-tower`);
const fetchCellTowerInfo = (item) => {
    return get(IOCellTowerInfo, `cell-tower-info/${item.id}`);
};
const formatInfoWindowContent = (info) => {
    const content = <CellTowerInfoWindow info={info}/>;
    return ReactDOMServer.renderToStaticMarkup(content);
};
const isStatusAllowed = (marker, statusId) => {
    if (!statusId) {
        return true;
    }
    return marker.statusIds.includes(statusId);
};
const isOperatorAllowed = (marker, operatorId) => {
    if (!operatorId) {
        return true;
    }
    return marker.operatorIds.includes(operatorId);
};
const isSystemAllowed = (marker, systemId) => {
    if (!systemId) {
        return true;
    }
    return marker.systemIds.includes(systemId);
};
const isGenerationAllowed = (marker, generationId) => {
    if (!generationId) {
        return true;
    }
    return marker.generationIds.includes(generationId);
};
const useCellTowerLayer = () => {
    const extraFilters = useExtraFilters();
    const [markerInfo, setMarkerInfo] = useState({});
    const allowMarker = useCallback((marker) => {
        const statusId = extraFilters?.[filtersKey.CELL_TOWER]?.statusId ?? null;
        const operatorId = extraFilters?.[filtersKey.CELL_TOWER]?.operatorId ?? null;
        const systemId = extraFilters?.[filtersKey.CELL_TOWER]?.systemId ?? null;
        const generationId = extraFilters?.[filtersKey.CELL_TOWER]?.generationId ?? null;
        return (isStatusAllowed(marker, statusId) &&
            isOperatorAllowed(marker, operatorId) &&
            isSystemAllowed(marker, systemId) &&
            isGenerationAllowed(marker, generationId));
    }, [extraFilters]);
    const getInfoWindowContent = async (marker) => {
        if (markerInfo[marker.id]) {
            return markerInfo[marker.id];
        }
        const telecomInfo = await fetchCellTowerInfo(marker);
        const content = formatInfoWindowContent(telecomInfo);
        setMarkerInfo({
            ...markerInfo,
            [marker.id]: content,
        });
        return content;
    };
    createLayersHook({
        fetch: fetchCellTower,
        markerImg: telecomImg,
        getInfoWindowContent,
        allowLayer: allowMarker,
        updateOnChange: true,
    })(filtersKey.CELL_TOWER);
};
const CellTowerLayer = () => {
    useCellTowerLayer();
    return null;
};
export default CellTowerLayer;
