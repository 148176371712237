import * as React from 'react';
import { memo, useCallback, useMemo, } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, FormLabel, HStack, Text } from '@chakra-ui/react';
import { useIsFetching } from '@tanstack/react-query';
import useAnalytics, { ANALYTICS_MAP_DISPLAY, ANALYTICS_MAP_STICKY, } from 'analytics/useAnalytics';
import FilterTitle from 'components/FilterTitle';
import { isFilterAllowed } from 'context/FiltersContext';
import { useIsSecondarySelectionMode, useSelectedTown, } from 'hooks/contexts/useLocationContext';
import useComputedZoom from 'hooks/map/useComputedZoom';
import useCountForFilter from 'hooks/useCountForFilter';
import useDebug from 'hooks/useDebug';
import useFilterCheckbox from 'hooks/useFilterCheckbox';
import { filtersKey, IOFilterObject } from 'utils/filters';
import { getTVA } from 'utils/formatText';
export const UNKNOWN_LAYER = 'unknown_layer';
const Filter = ({ filterKey, filter, parentCategory, id, disabled = false, }) => {
    const isLoadingLayers = useIsFetching({ queryKey: ['legend'] }) > 0;
    const { t } = useTranslation();
    const { isDebug } = useDebug();
    const { isChecked, onCheck, radioName } = useFilterCheckbox(filterKey, filter);
    const computedZoom = useComputedZoom();
    const isFilterItem = IOFilterObject.is(filter);
    const isSecondaryPlotSelectionMode = useIsSecondarySelectionMode();
    const selectedTown = useSelectedTown();
    const hasError = filterKey === UNKNOWN_LAYER;
    const count = useCountForFilter(filterKey, filter);
    const hasEmptyCount = count === 0;
    const { trackEvent, trackGetActionFilter } = useAnalytics();
    const handleChange = useCallback((e) => {
        if (id === 'main_layers') {
            trackEvent({
                category: ANALYTICS_MAP_STICKY,
                action: `${label} ${e.target.checked ? 'on' : 'off'} télécommande`,
            });
        }
        else if (e.target.checked) {
            trackEvent({
                category: ANALYTICS_MAP_DISPLAY,
                action: trackGetActionFilter(parentCategory, label),
            });
        }
        onCheck(e.target.checked);
    }, []);
    const label = useMemo(() => {
        if (isFilterItem) {
            if (filter.needsTranslate) {
                if (t(`matomo.${filter.title}`) !== `matomo.${filter.title}`) {
                    return t(`matomo.${filter.title}`);
                }
                else {
                    return t(`sidebar.layers.${filter.title}`, {
                        TVA: getTVA(selectedTown?.id),
                    });
                }
            }
            else {
                return filter.title;
            }
        }
        else {
            return t(`sidebar.layers.${filterKey}`, {
                TVA: getTVA(selectedTown?.id),
            });
        }
    }, [filter, filterKey]);
    const prefix = useMemo(() => isFilterItem && filter.prefix
        ? t(`sidebar.layers.${filter.prefix}`, {
            TVA: getTVA(selectedTown?.id),
        })
        : null, [filter]);
    const subtitle = useMemo(() => isFilterItem && filter.subtitle
        ? t(`sidebar.layers.${filter.subtitle}`, {
            TVA: getTVA(selectedTown?.id),
        })
        : null, [filter]);
    const isDisabled = disabled ||
        isLoadingLayers ||
        hasEmptyCount ||
        !isFilterAllowed(filterKey, computedZoom, selectedTown) ||
        (filterKey === filtersKey.PLOTS && !!isSecondaryPlotSelectionMode);
    const content = useMemo(() => (<>
        <Text as="span" textStyle="paragraph" color={hasError ? 'red.500' : 'colorMode.font800'}>
          {/* eslint-disable-next-line no-irregular-whitespace */}
          {!!prefix && `${prefix} - `}
          <FilterTitle title={label}/>
          {hasError && ' (Not implemented)'}
        </Text>
        {!!count && count > 0 && (<Text as="span" textStyle="small">
            {` (${count})`}
          </Text>)}
      </>), [count, label, prefix, hasError]);
    if ((!isDebug && hasError) ||
        (!selectedTown?.additionalPLU && filterKey === filtersKey.PLU_ADDITIONAL)) {
        return null;
    }
    return (<Box data-cy={'filter_' + filterKey}>
      {radioName ? (<HStack alignItems="center" key={filterKey}>
          <input type="radio" id={`${radioName}-${filterKey}`} name={radioName} disabled={isDisabled} checked={isChecked} onChange={handleChange}/>
          <FormLabel fontWeight={500} htmlFor={`${radioName}-${filterKey}`} cursor="pointer" lineHeight="1">
            {content}
          </FormLabel>
        </HStack>) : (<Checkbox key={filterKey} data-id={filterKey} isChecked={isChecked} isDisabled={isDisabled} onChange={handleChange} lineHeight="1" display="flex" alignItems="center" cursor="pointer">
          {content}
        </Checkbox>)}
      {subtitle && (<Text paddingLeft={5} textStyle="paragraph">
          {subtitle}
        </Text>)}
    </Box>);
};
export default memo(Filter);
