import { useEffect, useMemo } from 'react';
import { usePrevious } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
import { useConfirmCity } from 'hooks/contexts/useLocationContext';
import { IOPlotJson } from 'types/api';
import { useTranslation } from 'react-i18next';
import { useIsFilterActive } from 'hooks/contexts/useFiltersContext';
import useCurrentSite from 'hooks/useCurrentSite';
import useCustomToast from 'hooks/useCustomToast';
import { FROM_CLICK } from 'utils/constants';
import { filtersKey } from 'utils/filters';
const PLOT_QUERY_KEY = 'plot-by-coordinates';
export const getPlotByCoordinatesQueryKey = (lat, lng, townId) => `${PLOT_QUERY_KEY}_${lat}_${lng}_${townId}`;
export const fetchPlotByCoordinates = (townshipId, lat, lng, signal, siteId, plotId, from) => {
    plotId = plotId ?? '0';
    return get(IOPlotJson, `plot-by-coordinates/${townshipId}/${plotId}/${lat}/${lng}`, { siteId, from }, false, { signal }, (error) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        !error.response?.data.detail.startsWith('other_town_') &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            error.response?.data.detail !== 'nothing_here');
    });
};
// Can only be done if a town is selected, will automatically be done after selecting a town if coords provided
const usePlotByCoordinates = ({ plotId, coordinates, enabled, townId, from = FROM_CLICK, errorCallback, successCallback, }) => {
    const { t } = useTranslation();
    const { currentSite } = useCurrentSite();
    const { setConfirmCity } = useConfirmCity();
    const lat = coordinates?.lat().toString();
    const lng = coordinates?.lng().toString();
    const isFilterActive = useIsFilterActive();
    const { getInfoToast } = useCustomToast();
    const { data, error, isInitialLoading, ...rest } = useQuery({
        queryKey: [getPlotByCoordinatesQueryKey(lat, lng, townId), lat, lng],
        queryFn: ({ signal }) => fetchPlotByCoordinates(townId, lat, lng, signal, currentSite?.legacyId, plotId, from),
        enabled: enabled && !!coordinates && !!townId,
        retry: false,
        meta: {
            onSuccess: (data) => {
                if (successCallback) {
                    successCallback(data);
                }
            },
            onError: (error) => {
                if (error?.detail?.startsWith('other_town_') &&
                    !isFilterActive(filtersKey.PLU_GPU)) {
                    setConfirmCity({
                        coordinates,
                        townName: error.detail.replace('other_town_', ''),
                    });
                }
                else if (error?.detail?.startsWith('nothing_here')) {
                    getInfoToast({ description: t('error.nothing_here') });
                }
                if (errorCallback) {
                    errorCallback(error, coordinates);
                }
            },
        },
    });
    const plot = useMemo(() => {
        if (!data) {
            return null;
        }
        return data;
    }, [data]);
    // useQuery onSuccess callback is only called when the data is fetched,
    // not when it is retrieved from cache, so we need to call it manually
    const prevPlot = usePrevious(plot);
    useEffect(() => {
        if (successCallback && plot && plot.cadastreId !== prevPlot?.cadastreId) {
            successCallback(plot);
        }
    }, [plot]);
    return { plot, error, isInitialLoading, data, ...rest };
};
export default usePlotByCoordinates;
