import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as iots from 'io-ts';
import { get, post } from 'api/client';
import DPEInfoWindow, { IODPEInfo, } from 'components/infoWindow/DPEInfoWindow';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import useComputedBounds from 'hooks/map/useComputedBounds';
import DPEOldImg from 'images/dpe_old.png';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey, LAST_SIX_MONTHS } from 'utils/filters';
import { getBbox } from './POILayers';
export const IODPEMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        id: iots.string,
        dates: iots.array(iots.string),
        labelsGES: iots.array(iots.string),
        labelsDPE: iots.array(iots.string),
        buildingType: iots.array(iots.string),
    }),
], 'IODPEMarkerItem');
const fetchDPEOld = (townId, townScot, selectedTown, bbox) => () => get(iots.array(IODPEMarkerItem), `${townId}/DPE-old/${bbox}`);
const fetchDPEOldInfo = (item) => {
    return post(iots.array(IODPEInfo), `DPE-old-info`, { ids: item.id });
};
export const formatInfoWindowContent = (info) => {
    const content = <DPEInfoWindow info={info}/>;
    return ReactDOMServer.renderToStaticMarkup(content);
};
export const isTimePeriodAllowed = (marker, timePeriod) => {
    if (!timePeriod) {
        return true;
    }
    let find = false;
    marker.dates.forEach((markerDate) => {
        if (find) {
            return;
        }
        const date = new Date(markerDate);
        if (timePeriod === LAST_SIX_MONTHS) {
            const now = new Date();
            const diff = now.getTime() - date.getTime();
            const monthDiff = diff / (1000 * 3600 * 24 * 30);
            find = monthDiff <= 6;
        }
        else {
            find = date.getFullYear() === timePeriod;
        }
    });
    return find;
};
export const isGESAllowed = (marker, labelGES) => {
    if (!labelGES) {
        return true;
    }
    return marker.labelsGES.includes(labelGES);
};
export const isDPEAllowed = (marker, labelDPE) => {
    if (!labelDPE) {
        return true;
    }
    return marker.labelsDPE.includes(labelDPE);
};
export const isBuildingTypeAllowed = (marker, buildingType) => {
    if (!buildingType) {
        return true;
    }
    return marker.buildingType.includes(buildingType);
};
const LEGENDS = {
    dpe_old: {
        label: 'dpe_old',
        layerKey: filtersKey.DPE_OLD,
        needsTranslate: true,
        image: DPEOldImg,
    },
};
const useDPEOldLayer = () => {
    const computedBounds = useComputedBounds();
    const extraFilters = useExtraFilters();
    const [markerInfo, setMarkerInfo] = useState({});
    const allowMarker = useCallback((marker) => {
        const date = extraFilters?.[filtersKey.DPE]?.timePeriod ?? null;
        const labelGES = extraFilters?.[filtersKey.DPE]?.labelGES ?? null;
        const labelDPE = extraFilters?.[filtersKey.DPE]?.labelDPE ?? null;
        const buildingType = extraFilters?.[filtersKey.DPE]?.buildingType ?? null;
        return (isTimePeriodAllowed(marker, date) &&
            isGESAllowed(marker, labelGES) &&
            isDPEAllowed(marker, labelDPE) &&
            isBuildingTypeAllowed(marker, buildingType));
    }, [extraFilters]);
    const getInfoWindowContent = async (marker) => {
        if (markerInfo[marker.id]) {
            return markerInfo[marker.id];
        }
        const DPEInfo = await fetchDPEOldInfo(marker);
        const content = formatInfoWindowContent(DPEInfo);
        setMarkerInfo({
            ...markerInfo,
            [marker.id]: content,
        });
        return content;
    };
    const bbox = useMemo(() => getBbox(computedBounds), [computedBounds]);
    createLayersHook({
        fetch: fetchDPEOld,
        markerImg: DPEOldImg,
        legends: LEGENDS,
        getInfoWindowContent,
        allowLayer: allowMarker,
        updateOnChange: true,
        extraParams: [bbox],
        disableQuery: !bbox,
    })(filtersKey.DPE_OLD);
};
const DPEOldLayer = () => {
    useDPEOldLayer();
    return null;
};
export default DPEOldLayer;
