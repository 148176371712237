import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getTileBoundingBox } from 'utils/geo';
import { getBaseTileUrlHost3 } from 'utils/multiplexage';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    const boundingBox = getTileBoundingBox(coord, zoom, map, tileWidth, tileHeight);
    const layer = 'unesco';
    return (getBaseTileUrlHost3() +
        'cgi-bin/mm/mapserv.cgi?map=/var/data/public_maps/project_unesco.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=' +
        layer +
        '&TILED=true&WIDTH=' +
        tileWidth +
        '&HEIGHT=' +
        tileHeight +
        '&CRS=EPSG:4326&STYLES=&BBOX=' +
        boundingBox);
};
const getLegends = () => {
    const legends = {};
    legends.wind_unesco_natural_near = {
        layerKey: filtersKey.WIND_UNESCO_NATURAL,
        label: 'wind_unesco_natural_near',
        needsTranslate: true,
        color: '#7b00c9',
        opacity: 0.6,
    };
    legends.wind_unesco_natural_far = {
        layerKey: filtersKey.WIND_UNESCO_NATURAL,
        label: 'wind_unesco_natural_far',
        needsTranslate: true,
        color: '#b644ff',
        opacity: 0.6,
    };
    legends.wind_unesco_natural_project_near = {
        layerKey: filtersKey.WIND_UNESCO_NATURAL,
        label: 'wind_unesco_natural_project_near',
        needsTranslate: true,
        color: '#0047ff',
        opacity: 0.6,
    };
    legends.wind_unesco_natural_project_far = {
        layerKey: filtersKey.WIND_UNESCO_NATURAL,
        label: 'wind_unesco_natural_project_far',
        needsTranslate: true,
        color: '#0374be',
        opacity: 0.6,
    };
    return legends;
};
const useUNESCONaturalLayers = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.WIND_UNESCO_NATURAL],
        getLegends,
    })(filtersKey.WIND_UNESCO_NATURAL);
};
const UNESCONaturalLayers = () => {
    useUNESCONaturalLayers();
    return null;
};
export default UNESCONaturalLayers;
