import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FormControl, FormLabel } from '@chakra-ui/react';
export const multiSelectStyles = {
    indicatorSeparator: () => null,
    container: (provided) => ({
        ...provided,
        width: '100%',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 200,
    }),
    placeholder: (provided) => {
        return {
            ...provided,
            fontSize: '11px',
        };
    },
    menuList: (provided) => ({
        ...provided,
        backgroundColor: 'var(--colorModeBase)',
    }),
    control: (provided) => ({
        ...provided,
        backgroundColor: 'var(--colorModeBase)',
        borderRadius: '8px',
        width: '100%',
        borderColor: '#F2F2F2',
        minHeight: '32px',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: '5px',
    }),
    option: (provided, { isFocused, isSelected }) => ({
        ...provided,
        fontSize: '12px',
        textAlign: 'left',
        color: 'var(--colorModeFont-900)',
        backgroundColor: isFocused || isSelected
            ? 'var(--colorModeBackground-800)'
            : 'transparent',
        ':active': {
            ...provided[':active'],
            backgroundColor: 'var(--colorModeBackground-800)',
        },
    }),
    multiValue: (styles) => {
        return {
            ...styles,
            backgroundColor: 'var(--colorModeBackground-800)',
        };
    },
    multiValueLabel: (styles) => ({
        ...styles,
        color: 'var(--colorModeFont-900)',
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: 'var(--chakra-colors-red-500)',
        ':hover': {
            color: 'var(--chakra-colors-red-700)',
        },
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'var(--colorModeFont-900)',
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0 8px',
    }),
};
export const selectSetValue = (options, values) => {
    return options.filter((option) => values.includes(option.value));
};
const MultiSelectInput = ({ isLoading, label, name, isDisabled, onChange, styles, ...rest }) => {
    const { t } = useTranslation();
    const { setValue, watch } = useFormContext();
    const fieldValue = watch(name);
    return (<FormControl>
      {label && (<FormLabel htmlFor={name} marginBottom={1}>
          {label}
        </FormLabel>)}

      <Select loadingMessage={() => t('global.loading')} noOptionsMessage={() => t('global.no_options')} isDisabled={isDisabled} {...rest} onChange={onChange
            ? onChange
            : (selectValues) => {
                setValue(name, selectValues);
            }} value={fieldValue} closeMenuOnSelect={false} isMulti styles={styles ?? multiSelectStyles} isLoading={isLoading}/>
    </FormControl>);
};
export default MultiSelectInput;
