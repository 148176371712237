import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Center, Spinner, Text } from '@chakra-ui/react';
import useAnalytics from 'analytics/useAnalytics';
import useContactList from 'hooks/crm/useContactList';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import ContactButton from './ContactButton';
const CreateContactButton = ({ newContact, setContactToCreate, analyticsAction, analyticsCategory, }) => {
    const { t } = useTranslation();
    const { hasDashboardItem } = useSiteConfiguration();
    const { contacts, isInitialLoading: isLoadingContact } = useContactList();
    const { trackEvent } = useAnalytics();
    if (isLoadingContact) {
        return (<Center>
        <Spinner />
      </Center>);
    }
    const existingContact = contacts.find((contact) => {
        if (!contact.lastname || !newContact.name) {
            return false;
        }
        return (contact.lastname.toLowerCase() === newContact.name.toLowerCase() ||
            (contact.siren !== '' && contact.siren === newContact.siren));
    });
    if (!hasDashboardItem('myContact')) {
        return null;
    }
    const canAddContact = !existingContact || newContact.siren !== existingContact.siren;
    const addContactButton = canAddContact ? (<Button variant={existingContact ? 'link-primary' : 'outline'} size="sm" onClick={() => {
            setContactToCreate(newContact);
            if (analyticsAction && analyticsCategory) {
                trackEvent({
                    category: analyticsCategory,
                    action: analyticsAction,
                });
            }
        }}>
      {t(`tabs.plots.section.owner.add_to_contact`)}
    </Button>) : null;
    return (<>
      {!existingContact && addContactButton}
      {existingContact && (<Text textStyle="paragraph" size="sm" marginTop={1}>
          {t(`tabs.plots.section.owner.similar_contact`)}
          <ContactButton variant="outline" contactId={existingContact.id} size="sm" marginTop={1}>
            {t(`tabs.plots.section.owner.see_similar_contact`)}
          </ContactButton>
        </Text>)}
      {existingContact && addContactButton}
    </>);
};
export default CreateContactButton;
