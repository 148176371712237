import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('PARKING.SUP.500', 'PARKING.SUP.500', zoom, coord);
};
const getLegends = () => {
    const legends = {};
    legends.photovoltaic_not_prone_area_parking_space_500 = {
        layerKey: filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_PARKING_SPACE_500,
        label: 'photovoltaic_not_prone_area_parking_space_500',
        needsTranslate: true,
        color: '#DA57DE',
        opacity: 1,
    };
    legends.photovoltaic_not_prone_area_parking_space_1500 = {
        layerKey: filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_PARKING_SPACE_500,
        label: 'photovoltaic_not_prone_area_parking_space_1500',
        needsTranslate: true,
        color: '#901377',
        opacity: 1,
    };
    return legends;
};
const usePhotovoltaicProneAreaParkingSpace500Layer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_PARKING_SPACE_500],
        getLegends,
        opacity: 0.55,
    })(filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_PARKING_SPACE_500);
};
const PhotovoltaicProneAreaParkingSpace500Layer = () => {
    usePhotovoltaicProneAreaParkingSpace500Layer();
    return null;
};
export default PhotovoltaicProneAreaParkingSpace500Layer;
