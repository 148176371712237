import { useCallback } from 'react';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getTileBoundingBox } from 'utils/geo';
import { getBaseTileUrlHost3 } from 'utils/multiplexage';
import { CST_POSITIONS } from 'utils/tilesPositions';
// On multiplie par 10 car le slider arrondie
export const DEFAULT_CAPARESEAU_ISODISTANCE = 100;
const getLayerTileUrlParams = (map, tileWidth, tileHeight, enabledFilters, extraFilters) => (coord, zoom) => {
    const boundingBox = getTileBoundingBox(coord, zoom, map, tileWidth, tileHeight);
    const isoDistance = (extraFilters?.[filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU]?.isoDistance ??
        DEFAULT_CAPARESEAU_ISODISTANCE) / 10;
    const layer = `capareseau_${isoDistance.toString().replace('.', '_')}km`;
    return (getBaseTileUrlHost3() +
        'cgi-bin/mm/mapserv.cgi?map=/var/data/public_maps/project_capareseau.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=' +
        layer +
        '&TILED=true&WIDTH=' +
        tileWidth +
        '&HEIGHT=' +
        tileHeight +
        '&CRS=EPSG:4326&STYLES=&BBOX=' +
        boundingBox);
};
const getLegends = () => {
    const legends = {};
    legends.electrical_networks_capareseau = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU,
        label: 'electrical_networks_capareseau',
        needsTranslate: true,
        color: '#6F7DE7',
        opacity: 0.6,
        borderColor: '#6F7DE7',
        borderWidth: 2,
    };
    return legends;
};
const useElectricalNetworkCapareseauLayers = () => {
    const extraFilters = useExtraFilters();
    const getLayerTileUrl = useCallback((map, tileWidth, tileHeight, enabledFilters) => getLayerTileUrlParams(map, tileWidth, tileHeight, enabledFilters, extraFilters), [extraFilters?.[filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU]?.isoDistance]);
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU],
        getLegends,
        refreshParam: extraFilters?.[filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU]?.isoDistance ||
            DEFAULT_CAPARESEAU_ISODISTANCE,
    })(filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU);
};
const ElectricalNetworkCapareseauLayers = () => {
    useElectricalNetworkCapareseauLayers();
    return null;
};
export default ElectricalNetworkCapareseauLayers;
