import * as React from 'react';
import { Text } from '@chakra-ui/react';
import { SirenLink } from 'components/links/SirenLink';
import { formatAddress } from 'utils/formatText';
const OwnerIdentity = ({ owner }) => {
    return (<>
      <Text textStyle="paragraph" textTransform="capitalize" fontWeight={600}>
        {owner.name}
        {owner.siren &&
            !(owner.siren.substring(0, 1).toUpperCase() === 'U') && (<Text as="span" marginLeft={1}>
              <SirenLink siren={owner.siren} analyticDetail="Pappers Propriétaires" showExternalLink/>
            </Text>)}
      </Text>

      <Text textStyle="small">{formatAddress(owner)}</Text>
    </>);
};
export default OwnerIdentity;
