import * as React from 'react';
import { useState } from 'react';
import { CADASTRE_ZOOM_LIMIT } from 'components/layers/CadastreLayer';
import useUserHistory from 'hooks/crm/useUserHistory';
import { createContext } from 'use-context-selector';
import usePlotLayersById from 'hooks/plot/usePlotLayersById';
import { ADDRESS_FROM_CLICK } from 'hooks/useChangeTown';
import useTownByCoordinates from 'hooks/useTownByCoordinates';
import { cleanAllMarkerFromMapAndPanorama } from 'utils/map';
export const PLOT_SELECTION_FEASIBILITY = 'plot_selection_feasibility';
export const PLOT_SELECTION_PROJECT = 'plot_selection_project';
export const PLOT_SELECTION_INITIAL = 'plot_selection_initial';
export const LocationContext = createContext(null);
export const LocationProvider = ({ children }) => {
    const [plotSelectionMode, setPlotSelectionMode] = useState(PLOT_SELECTION_INITIAL);
    const resetPlotSelectionMode = () => {
        setPlotSelectionMode(PLOT_SELECTION_INITIAL);
    };
    const isSecondarySelectionMode = [
        PLOT_SELECTION_FEASIBILITY,
        PLOT_SELECTION_PROJECT,
    ]?.includes(plotSelectionMode);
    const [displayedPlot, setDisplayedPlot] = useState(null);
    const [locatedProject, setLocatedProject] = useState(null);
    const [clickedCoord, setClickedCoord] = useState(null);
    const [townCoordinates, setTownCoordinates] = useState(null);
    const [shouldAddTownToHistory, setShouldAddTownToHistory] = useState(false);
    const [lastTownAddedToHistory, setLastTownAddedToHistory] = useState(null);
    const [formattedAddress, setFormattedAddress] = useState(null);
    const [selectedTown, setSelectedTown] = useState(null);
    const [towns, setTowns] = useState({});
    const [confirmCity, setConfirmCity] = useState();
    const [layers, setLayers] = useState({});
    const { addAddressToHistory } = useUserHistory(false);
    const [drawingMode, setDrawingMode] = useState(null);
    const { isInitialLoading: isLoadingTown, error: townError, data: townData, } = useTownByCoordinates({
        coordinates: townCoordinates,
        enabled: !!townCoordinates,
        errorCallback: () => {
            setSelectedTown(null);
            setTownCoordinates(undefined);
        },
    });
    React.useEffect(() => {
        if (townData) {
            handleSelectTown(townData);
            setTownCoordinates(undefined);
        }
    }, [townData]);
    const { isInitialLoading: isLoadingPlotLayers } = usePlotLayersById({
        plot: displayedPlot,
        enabled: !!displayedPlot && !!selectedTown?.id,
        selectedTown,
        successCallback: (data) => {
            if (data?.length && !layers[displayedPlot?.id]) {
                setLayers({ ...layers, [displayedPlot?.id]: data });
            }
        },
    });
    const resetSelectedTown = () => {
        setTownCoordinates(null);
        setTowns({});
        setFormattedAddress(null);
        setSelectedTown(null);
        cleanAllMarkerFromMapAndPanorama();
    };
    const handleSelectTown = (data) => {
        if (data?.id) {
            setSelectedTown(data);
            setTowns({ ...towns, [data.id]: data });
            let address = formattedAddress;
            if (!formattedAddress || formattedAddress === ADDRESS_FROM_CLICK) {
                address = data.name;
                setFormattedAddress(address);
            }
            if (!shouldAddTownToHistory || lastTownAddedToHistory === data.id) {
                return;
            }
            addAddressToHistory({
                townId: data.id,
                address,
                lat: townCoordinates?.lat().toString(),
                lng: townCoordinates?.lng().toString(),
                acuteness: CADASTRE_ZOOM_LIMIT,
            });
            setShouldAddTownToHistory(false);
            setLastTownAddedToHistory(data.id);
        }
    };
    const resetLocation = ({ resetTown }) => {
        setClickedCoord(null);
        setPlotSelectionMode(null);
        if (resetTown) {
            resetSelectedTown();
        }
    };
    return (<LocationContext.Provider value={{
            layers,
            plotSelectionMode,
            setPlotSelectionMode,
            selectedTown,
            handleSelectTown,
            townError,
            clickedCoord,
            setClickedCoord,
            townCoordinates,
            setTownCoordinates,
            setShouldAddTownToHistory,
            lastTownAddedToHistory,
            setLastTownAddedToHistory,
            formattedAddress,
            setFormattedAddress,
            isLoadingTown,
            towns,
            resetLocation,
            confirmCity,
            setConfirmCity,
            clearTown: () => setSelectedTown(undefined),
            isLoadingPlotLayers,
            resetPlotSelectionMode,
            isSecondarySelectionMode,
            displayedPlot,
            setDisplayedPlot,
            locatedProject,
            setLocatedProject,
            drawingMode,
            setDrawingMode,
        }}>
      {children}
    </LocationContext.Provider>);
};
export default LocationContext;
