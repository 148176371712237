import * as React from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, FormControl, FormLabel, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Textarea, useDisclosure, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CRM } from 'analytics/useAnalytics';
import { useSectionHash } from 'hooks/contexts/useTabsContext';
import usePlotContact from 'hooks/crm/usePlotContact';
const UpdateContactInfoModal = ({ title, plotId, plotContactId, defaultValues, }) => {
    const { trackEvent } = useAnalytics();
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { updatePlotContact } = usePlotContact(null, onClose);
    const sectionHash = useSectionHash();
    const { register, handleSubmit, reset } = useForm({
        defaultValues,
    });
    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues]);
    const onSubmit = (data) => {
        updatePlotContact({
            plotId,
            plotContactId,
            ...data,
        });
    };
    return (<>
      <Button variant="link-primary" onClick={() => {
            onOpen();
            if (sectionHash === 'my_crm') {
                trackEvent({
                    category: ANALYTICS_CRM,
                    action: 'Ajouter un commentaire',
                });
            }
        }} data-cy="update_contact_info">
        {title}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <ModalHeader>
              <Heading as="h4" variant="h4">
                {t('modals.update_owner_info.title')}
              </Heading>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl paddingBottom={2}>
                <FormLabel>{t('modals.update_owner_info.comment')}</FormLabel>
                <Textarea {...register('comment')} placeholder={t('modals.update_owner_info.comment_placeholder')} data-cy="update_contact_info_input"/>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button size="sm" marginRight={2} variant="outline" onClick={onClose}>
                {t('modals.update_owner_info.close')}
              </Button>
              <Button type="submit" size="sm" variant="primary" data-cy="update_contact_info_submit">
                {t('modals.update_owner_info.submit')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>);
};
export default UpdateContactInfoModal;
