import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckIcon } from '@chakra-ui/icons';
import { Box, Button, Text, VStack } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MAP_BUTTONS } from 'analytics/useAnalytics';
import { HIGH_DEFINITION, HISTORY_MAP, HISTORY_MAP_YEARS, MAP_DISPLAYS_SATELLITE, OBLIQUE, ROADMAP, SAT_IGN, SAT_IGN_TOPOGRAPHY, SATELLITE, } from 'context/MapContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useChangeMapDisplay, useMapDisplay, } from 'hooks/contexts/useMapContext';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import DropdownIcon from 'images/icons/Dropdown';
import PopoverMenu from './PopoverMenu';
import ToolbarButton from './ToolbarButton';
export const MAP_DISPLAYS_TRACK = {
    [ROADMAP]: 'Plan',
    [SATELLITE]: 'Satellite',
    [SAT_IGN]: 'Satellite IGN',
    [OBLIQUE]: 'Vue oblique',
    [HIGH_DEFINITION]: 'High definition',
    [HISTORY_MAP]: 'Remonter le temps',
};
const MapDisplayButtons = () => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const { hasMapControl } = useSiteConfiguration();
    const changeMapDisplay = useChangeMapDisplay();
    const selectedTown = useSelectedTown();
    const { mapDisplay, historyMapYear, mapDisplaySatellite } = useMapDisplay();
    const [lastSatelliteDisplay, setLastSatelliteDisplay] = useState(null);
    const [open, setOpen] = useState(false);
    const historyMapYearsAvailable = HISTORY_MAP_YEARS.filter((year) => selectedTown?.mapAvailable[year]);
    const highDefinitionAvailable = selectedTown?.mapAvailable.thr ?? 0;
    useEffect(() => {
        if (selectedTown) {
            if ((mapDisplay === HIGH_DEFINITION && !highDefinitionAvailable) ||
                (mapDisplay === HISTORY_MAP &&
                    !selectedTown.mapAvailable[historyMapYear])) {
                changeMapDisplay(SATELLITE);
            }
        }
    }, [selectedTown]);
    const allowedSatelliteDisplays = MAP_DISPLAYS_SATELLITE.filter((display) => {
        if (display === SAT_IGN) {
            return hasMapControl('display_satign');
        }
        if (display === OBLIQUE) {
            return hasMapControl('display_oblique');
        }
        if (display === SAT_IGN_TOPOGRAPHY) {
            return hasMapControl('display_satign_road');
        }
        if (display === HIGH_DEFINITION) {
            return highDefinitionAvailable === 1;
        }
        return true;
    });
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleClosePopover = () => {
        setOpen(false);
    };
    return (<>
      <ToolbarButton label={t(`map.map_type.${ROADMAP}`)} isActive={mapDisplay === ROADMAP} handleClick={() => {
            trackEvent({
                category: ANALYTICS_MAP_BUTTONS,
                action: MAP_DISPLAYS_TRACK[ROADMAP],
            });
            changeMapDisplay(ROADMAP);
        }} marginRight={2}/>

      {hasMapControl('map') && (<PopoverMenu label={historyMapYear
                ? historyMapYear
                : lastSatelliteDisplay
                    ? t(`map.map_type.${lastSatelliteDisplay}`)
                    : t(`map.map_type.${mapDisplaySatellite}`)} isActive={mapDisplay !== ROADMAP} menuItems={allowedSatelliteDisplays.map((display) => ({
                handleClick: (year) => {
                    trackEvent({
                        category: ANALYTICS_MAP_BUTTONS,
                        action: MAP_DISPLAYS_TRACK[display],
                    });
                    changeMapDisplay(display, year);
                    setLastSatelliteDisplay(display);
                },
                label: t(`map.map_type.${display}`),
                isActive: mapDisplay === display,
            }))} borderRightRadius={0} trigger="hover" showCheck handleClose={handleClosePopover} selectDefault={() => {
                if (mapDisplay === ROADMAP) {
                    changeMapDisplay(lastSatelliteDisplay || SATELLITE);
                }
            }}>
          {Boolean(historyMapYearsAvailable?.length) && (<Box width="full" onMouseEnter={handleOpen} onMouseLeave={handleClose}>
              <Button width="full" backgroundColor="colorMode.base" borderRadius={0} justifyContent="left" paddingRight={1}>
                {t(`map.map_type.historyMap`)}
                <Text as="span" marginLeft={2}>
                  <DropdownIcon color="colorMode.font700" width="15px" transform={open ? 'rotate(180)' : 'rotate(0)'}/>
                </Text>
              </Button>
              <VStack spacing={0} display={open ? 'block' : 'none'} width="180px">
                {historyMapYearsAvailable.map((year) => {
                    return (<Button size="sm" width="full" key={year} borderRadius={0} leftIcon={<CheckIcon color={year === historyMapYear ? 'inherit' : 'transparent'} marginLeft="0.5rem"/>} onClick={() => {
                            setOpen(false);
                            trackEvent({
                                category: ANALYTICS_MAP_BUTTONS,
                                action: 'Remonter le temps ' + year,
                            });
                            changeMapDisplay(HISTORY_MAP, year);
                        }} justifyContent="left" paddingLeft="0">
                      {year}
                    </Button>);
                })}
              </VStack>
            </Box>)}
        </PopoverMenu>)}
    </>);
};
export default MapDisplayButtons;
