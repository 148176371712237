import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as Navigation } from 'react-router-dom';
import { Box, Button, FormControl, FormErrorMessage, FormLabel, Heading, Input, Link, Spinner, Stack, Text, VStack, } from '@chakra-ui/react';
import { LOGIN_URL } from 'app';
import LanguageDropdown from 'components/dropdowns/LanguageDropdown';
import useForgotPassword from 'hooks/useForgotPassword';
import { EMAIL_PATTERN } from 'utils/search';
const ForgottenPasswordForm = () => {
    const { t } = useTranslation();
    const { register, handleSubmit, formState: { isSubmitting, errors }, reset, } = useForm();
    const { sendForgotPasswordEmail, isLoading } = useForgotPassword(() => reset());
    return (<VStack height="100vh" bgColor="colorMode.background900" justifyContent="center">
      <Box position="absolute" right={5} top={5}>
        <LanguageDropdown />
      </Box>

      <VStack flex={1} justifyContent="center" alignItems="center">
        <Heading as="h3" variant="h3" marginBottom={3}>
          {t('forgotten_password.title')}
        </Heading>

        <Text textStyle="paragraph">{t('forgotten_password.email')}</Text>
        <Text textStyle="paragraph" paddingBottom="50px">
          {t('forgotten_password.link')}
        </Text>

        <form onSubmit={handleSubmit((data) => sendForgotPasswordEmail(data))}>
          <Stack paddingBottom={2} width="300px" spacing={2}>
            <FormControl isInvalid={!!errors?.email}>
              <FormLabel>{t('forgotten_password.email_label')}</FormLabel>
              <Input data-cy="forgotten_password_email" {...register('email', {
        required: t('forgotten_password.invalid_email'),
        pattern: {
            value: EMAIL_PATTERN,
            message: t('forgotten_password.invalid_email'),
        },
    })} placeholder={t('forgotten_password.email_placeholder')}/>
              <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
            </FormControl>
          </Stack>

          {isLoading ? (<Button width={300} variant="primary" type="button">
              <Spinner />
            </Button>) : (<Button width={300} variant="primary" isDisabled={isSubmitting} type="submit">
              {t('forgotten_password.send')}
            </Button>)}
        </form>

        <Box width="full" paddingTop={5}>
          <Text textStyle="paragraph" textAlign="center">
            {t('forgotten_password.has_account')}
            <Navigation to={LOGIN_URL}>
              <Text as="span" color="red.500" paddingLeft={2} cursor="pointer">
                {t('forgotten_password.login')}
              </Text>
            </Navigation>
          </Text>
        </Box>
      </VStack>

      <Box width="full" paddingBottom="56px">
        <Text textStyle="paragraph" textAlign="center">
          {t('login.no_account_info')}
          <Link href="https://kelfoncier.com/test-gratuit" isExternal paddingLeft={2} cursor="pointer" color="colorMode.font900">
            {t('login.signup')}
          </Link>
        </Text>
      </Box>
    </VStack>);
};
export default ForgottenPasswordForm;
