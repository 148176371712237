import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getBaseTileUrlHost3 } from 'utils/multiplexage';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return (getBaseTileUrlHost3() +
        'cgi-bin/mm/mapserv.cgi?map=/var/data/public_maps/project_quartiers.map&LAYERS=sous_quartiers&mode=tile&tilemode=gmap&tile=' +
        coord.x +
        '+' +
        coord.y +
        '+' +
        zoom +
        '&FORMAT=image%2Fpng&TRANSPARENT=true&TILED=true&CRS=EPSG:3857');
};
const getSecondLayerTileUrl = () => (coord, zoom) => {
    return (getBaseTileUrlHost3() +
        'cgi-bin/mm/mapserv.cgi?map=/var/data/public_maps/project_quartiers.map&LAYERS=sous_quartiers_centroide&mode=tile&tilemode=gmap&tile=' +
        coord.x +
        '+' +
        coord.y +
        '+' +
        zoom +
        '&FORMAT=image%2Fpng&TRANSPARENT=true&TILED=true&CRS=EPSG:3857');
};
const getLegends = () => {
    const legends = {};
    legends.town_and_neighborhood = {
        layerKey: filtersKey.TOWN_AND_NEIGHBORHOODS,
        label: 'town_and_neighborhood',
        needsTranslate: true,
        color: '#FFFFFE',
        borderColor: '#000000',
        borderWidth: 1,
        borderOpacity: 1,
    };
    return legends;
};
const useTownAndNeighborhoodsLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        getLegends,
        position: CST_POSITIONS[filtersKey.TOWN_AND_NEIGHBORHOODS],
    })(filtersKey.TOWN_AND_NEIGHBORHOODS);
    createTilesLayerHook({
        getLayerTileUrl: getSecondLayerTileUrl,
        position: CST_POSITIONS[`${filtersKey.TOWN_AND_NEIGHBORHOODS}_2nd`],
    })(filtersKey.TOWN_AND_NEIGHBORHOODS);
};
const TownAndNeighborhoodsLayer = () => {
    useTownAndNeighborhoodsLayer();
    return null;
};
export default TownAndNeighborhoodsLayer;
