import * as React from 'react';
import { useMemo } from 'react';
import { Box, Button, HStack, Menu, MenuButton, MenuItem, MenuList, Text, } from '@chakra-ui/react';
import { CUSTOMER_NUMBER_KELFONCIER } from 'context/AuthContext';
import { useUserData } from 'hooks/contexts/useAuthContext';
import useCurrentSite from 'hooks/useCurrentSite';
import { getSiteImage } from 'hooks/useSiteLogo';
import DropdownIcon from 'images/icons/Dropdown';
const UserSitesDropdown = () => {
    const { currentSite, setCurrentSite } = useCurrentSite();
    const userData = useUserData();
    const sites = userData?.sites;
    if (!userData ||
        sites?.length < 2 ||
        !currentSite ||
        userData.customerNumber !== CUSTOMER_NUMBER_KELFONCIER) {
        return null;
    }
    const orderedSites = useMemo(() => {
        sites?.forEach((site) => {
            if (!site.title || site.title === '') {
                site.title = site.url.substring(0, site.url.indexOf('.kelfoncier'));
            }
        });
        return sites.sort((a, b) => a.title.localeCompare(b.title));
    }, [sites]);
    return (<Menu placement="top">
      {({ isOpen }) => (<>
          <MenuButton as={Button} width="full" padding="0 7px" size="sm" variant="ghost" color="colorMode.font800" data-cy="site_selector">
            <HStack>
              <Text textStyle="small" textTransform="capitalize" overflowX="hidden" textOverflow="ellipsis">
                {currentSite.title}
              </Text>
              <Box marginLeft="0px !important" transform={isOpen ? 'rotateX(180deg)' : 'rotateX(0deg)'} transition="transform 0.5s">
                <DropdownIcon fill="currentColor"/>
              </Box>
            </HStack>
          </MenuButton>
          <MenuList maxHeight="15rem" height="100%" overflowY="auto" overflowX="hidden" backgroundColor="colorMode.background900" borderRadius="md" className="smooth-box-shadow">
            {orderedSites?.map((site, index) => {
                if (site.url === currentSite.url) {
                    return null;
                }
                return (<MenuItem key={index} onClick={() => setCurrentSite(site)} data-cy={site.url}>
                  <HStack>
                    {site.favicon && (<img width={10} src={getSiteImage(site.favicon)} alt={site.title}/>)}
                    <Text color="colorMode.font900" textStyle="small">
                      {site.title}
                    </Text>
                  </HStack>
                </MenuItem>);
            })}
          </MenuList>
        </>)}
    </Menu>);
};
export default UserSitesDropdown;
