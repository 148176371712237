import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionItem, Box, Button, Center, Spinner, } from '@chakra-ui/react';
import { useIsFetching } from '@tanstack/react-query';
import useAnalytics, { ANALYTICS_TELECOMMANDE_ACTIONS, } from 'analytics/useAnalytics';
import LegendItem from 'components/map/Legend/LegendItem/LegendItemWrapper';
import { useClearFilters } from 'hooks/contexts/useFiltersContext';
import { useLayersTree } from 'hooks/contexts/useLayersTreeContext';
import { useLegends } from 'hooks/contexts/useLegendContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import CloseIcon from 'images/icons/Close';
import { filtersKey } from 'utils/filters';
import { formatLayersNames, getNameAndPrefixForLayerKey, } from 'utils/formatLayersNames';
import PlotSummaryLegendItem from './LegendItem/PlotSummary/PlotSummaryLegendItem';
const LegendSkeleton = () => {
    return (<Center paddingY={1} data-cy="legend-skeleton" backgroundColor="colorMode.background900">
      <Spinner size="sm"/>
    </Center>);
};
const LegendList = ({ mapHeight }) => {
    const { t } = useTranslation();
    const legends = useLegends();
    const layersTree = useLayersTree();
    const isLoadingLayers = useIsFetching({ queryKey: ['layer'] }) > 0;
    const isLoadingLegends = useIsFetching({ queryKey: ['legend'] }) > 0;
    const isLoadingLayerTree = useIsFetching({ queryKey: ['layersTree'] }) > 0;
    const [openedLegendsIndex, setOpenedLegendsIndex] = useState([]);
    const [_, setPrevLegends] = useState({});
    const selectedTown = useSelectedTown();
    const clearFilters = useClearFilters();
    const { trackEvent } = useAnalytics();
    const isLoading = isLoadingLayers || isLoadingLegends;
    const layerNames = useMemo(() => {
        if (!isLoadingLayerTree && layersTree) {
            return formatLayersNames(layersTree);
        }
        return {};
    }, [JSON.stringify(layersTree), isLoadingLayerTree]);
    const legendsToDisplay = useMemo(() => {
        return Object.keys(legends)
            .reverse()
            .map((layerKey) => {
            const { name, prefix } = getNameAndPrefixForLayerKey(layerKey, layerNames, t, selectedTown?.id);
            return {
                layerKey,
                legends: legends[layerKey],
                prefix,
                legendName: name,
            };
        })
            .filter((legend) => legend.legendName); // Pour le cas où le layer n'est plus disponible
    }, [legends, layerNames]);
    useEffect(() => {
        const openedIndex = [0];
        // only the first legend opened, my plot and my project
        Object.keys(legends)
            .reverse()
            .forEach((value, index) => {
            if (index !== 0 &&
                (value === filtersKey.USER_PLOTS ||
                    value === filtersKey.USER_PROJECTS)) {
                openedIndex.push(index);
            }
        });
        // Open the legend only when new filter is checked
        setPrevLegends((prev) => {
            if (Object.keys(legends).length > Object.keys(prev).length) {
                setOpenedLegendsIndex(openedIndex);
            }
            return legends;
        });
    }, [Object.keys(legends).length]);
    const legendRef = useRef(null);
    return (<Box ref={legendRef} id="legends" boxShadow="sm" position="absolute" top={0} left={0} width="275px" maxH={mapHeight} minH="0" overflow={legendRef?.current?.clientHeight >= mapHeight ? 'auto' : 'none'}>
      {isLoading && <LegendSkeleton />}
      <Accordion reduceMotion allowMultiple index={openedLegendsIndex} onChange={(e) => setOpenedLegendsIndex(e)} backgroundColor="colorMode.background900">
        {legendsToDisplay.length > 1 && (<AccordionItem>
            <Button _hover={{ background: 'colorMode.background800' }} background={'transparent'} fontSize={12} width="100%" textAlign="center" leftIcon={<CloseIcon fill="currentColor"/>} height="auto" className="unselect_all_layers_button" onClick={() => {
                clearFilters(false);
                trackEvent({
                    category: ANALYTICS_TELECOMMANDE_ACTIONS,
                    action: 'Effacer les cartes Urbanisme - légende',
                });
            }}>
              {t('sidebar.header.unselect_all_layers')}
            </Button>
          </AccordionItem>)}
        {legendsToDisplay.map((legend) => (<LegendItem key={legend.layerKey} {...legend}/>))}
      </Accordion>
      <PlotSummaryLegendItem />
    </Box>);
};
export default LegendList;
