import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, HStack, Switch, Text, VStack } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_ONGLET } from 'analytics/useAnalytics';
import { useSetLayoutHalf } from 'hooks/contexts/useLayoutContext';
import { usePlotSelectionMode, useResetPlotSelectionMode, useSetPlotSelectionMode, } from 'hooks/contexts/useLocationContext';
const AddPlotFromMap = ({ mode, onChange, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const plotSelectionMode = usePlotSelectionMode();
    const setPlotSelectionMode = useSetPlotSelectionMode();
    const resetPlotSelectionMode = useResetPlotSelectionMode();
    const enabled = plotSelectionMode === mode;
    const toggle = () => {
        if (enabled) {
            resetPlotSelectionMode();
        }
        else {
            setPlotSelectionMode(mode);
        }
        onChange && onChange(enabled);
    };
    const setLayoutHalf = useSetLayoutHalf();
    return (<VStack backgroundColor="colorMode.base" padding={4} alignItems="flex-start" borderRadius="lg">
      <Heading as="h5" variant="h5">
        {t('tabs.plots.section.feasibility.add_plot_from_map')}
      </Heading>
      <HStack width="full" justifyContent="space-between">
        <HStack>
          <Text textStyle="small">
            {t(`tabs.plots.section.feasibility.multi_plot_mode.${enabled ? 'activated' : 'deactivated'}`)}
          </Text>
          <Switch isChecked={enabled} data-cy="plot_selection_mode_switch" onChange={() => {
            toggle();
            setLayoutHalf();
            if (enabled) {
                trackEvent({
                    category: ANALYTICS_ONGLET,
                    action: 'Faisabilité ajouter une parcelle carte',
                });
            }
        }} size="lg" id="plot-on-map"/>
        </HStack>
      </HStack>
    </VStack>);
};
export default AddPlotFromMap;
