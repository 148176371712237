import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, Checkbox, FormControl, FormLabel, HStack, VStack, } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import moment from 'moment';
import * as yup from 'yup';
import ContactSelect from 'components/inputs/ContactSelect';
import DateTimePickerInput from 'components/inputs/DateTimePickerInput';
import ProjectSelect from 'components/inputs/ProjectSelect';
import SelectInput from 'components/inputs/SelectInput';
import TextAreaInput from 'components/inputs/TextAreaInput';
import TextInput from 'components/inputs/TextInput';
import ActivityReminderModal from 'components/modals/ActivityReminderModal';
import useActivity from 'hooks/useActivity';
import useOnError from 'hooks/useOnError';
import { ACTIVITY_RECURRENCE_EVERY_DAYS, ACTIVITY_RECURRENCE_EVERY_MONTHS, ACTIVITY_RECURRENCE_EVERY_WEEKS, ACTIVITY_RECURRENCE_EVERY_YEARS, ACTIVITY_RECURRENCE_NO_RECURRENCE, ACTIVITY_RECURRENCE_ONCE, ACTIVITY_RECURRENCE_OPTIONS, AGENDA_ACTIVITY_TYPE_EVENT, AGENDA_ACTIVITY_TYPE_LAND_POINT, AGENDA_ACTIVITY_TYPE_REMINDER, AGENDA_ACTIVITY_TYPE_TASK, AGENDA_ACTIVITY_TYPE_VALUES, AGENDA_ACTIVITY_TYPES, } from 'utils/constants';
import { dateAddDays } from 'utils/date';
const getDefaultValues = (activityInfo) => {
    const agendaType = activityInfo?.agendaType ?? AGENDA_ACTIVITY_TYPE_TASK;
    const formData = {
        id: activityInfo?.id,
        cadastreId: activityInfo?.cadastreId,
        plotId: activityInfo?.plotId ? activityInfo?.plotId : null,
        contactId: activityInfo?.contactId ? activityInfo?.contactId : null,
        projectId: activityInfo?.projectId ? activityInfo?.projectId : null,
        date: activityInfo?.date ? new Date(activityInfo?.date) : new Date(),
        dateEnd: activityInfo?.dateEnd ? new Date(activityInfo?.dateEnd) : null,
        status: activityInfo?.status ? activityInfo.status.toString() : '0',
        groupActivityId: activityInfo?.groupActivityId ?? null,
        agendaType,
        title: activityInfo?.title || '',
        updateOnlyOne: 1,
    };
    formData.recurrence =
        agendaType !== AGENDA_ACTIVITY_TYPE_REMINDER
            ? ACTIVITY_RECURRENCE_NO_RECURRENCE
            : activityInfo?.recurrence ?? ACTIVITY_RECURRENCE_ONCE;
    if (agendaType === AGENDA_ACTIVITY_TYPE_EVENT) {
        const dateStart = new Date();
        let hours = dateStart.getHours();
        let minutes = 0;
        if (dateStart.getMinutes() > 30) {
            hours += 1;
        }
        else if (dateStart.getMinutes() > 0) {
            minutes = 30;
        }
        dateStart.setHours(hours, minutes, 0, 0);
        formData.time = activityInfo?.date
            ? new Date(activityInfo?.date)
            : dateStart;
        formData.endTime = activityInfo?.dateEnd
            ? new Date(activityInfo?.dateEnd)
            : new Date(formData.time.getTime() + 60 * 60 * 1000); // one hour after start date
        formData.location = activityInfo?.location;
        formData.comment = activityInfo?.comment;
        return formData;
    }
    if (agendaType === AGENDA_ACTIVITY_TYPE_TASK) {
        formData.comment = activityInfo?.comment;
        return formData;
    }
    return formData;
};
const getValidationSchema = (t, agendaType) => {
    const properties = {
        agendaType: yup
            .number()
            .oneOf(Object.values(AGENDA_ACTIVITY_TYPES))
            .required(t('global.fields.error.required')),
    };
    if (agendaType !== AGENDA_ACTIVITY_TYPE_LAND_POINT) {
        properties.date = yup.date().required(t('global.fields.error.required'));
        properties.title = yup
            .string()
            .nullable()
            .transform((v, o) => (o.trim() === '' ? null : v))
            .required(t('global.fields.error.required'))
            .min(1, t('global.fields.error.required'));
    }
    else {
        properties.comment = yup
            .string()
            .nullable()
            .transform((v, o) => (o.trim() === '' ? null : v))
            .required(t('global.fields.error.required'))
            .min(1, t('global.fields.error.required'));
    }
    if (agendaType === AGENDA_ACTIVITY_TYPE_EVENT) {
        properties.time = yup.date().required(t('global.fields.error.required'));
        properties.endTime = yup.date().required(t('global.fields.error.required'));
    }
    return yup.object().shape(properties);
};
const ActivityForm = ({ activityInfo, onClose, }) => {
    const onErrorCallback = useOnError();
    const { t } = useTranslation();
    const defaultValues = getDefaultValues(activityInfo);
    const agendaType = defaultValues.agendaType;
    const agendaTypeValue = AGENDA_ACTIVITY_TYPE_VALUES[agendaType];
    const groupActivityId = defaultValues.groupActivityId;
    const allDayDefault = () => {
        if (activityInfo.agendaType === AGENDA_ACTIVITY_TYPE_TASK &&
            activityInfo.id) {
            const dateStart = new Date(activityInfo.date);
            return dateStart.getHours() === 0;
        }
        else if (activityInfo.agendaType === AGENDA_ACTIVITY_TYPE_TASK) {
            return true;
        }
        return false;
    };
    const [allDay, setAllDay] = useState(allDayDefault());
    const validationSchema = getValidationSchema(t, agendaType);
    const { createActivity, updateActivity, isUpdating, isCreating } = useActivity({
        plotId: activityInfo?.plotId || undefined,
        contactId: activityInfo?.contactId || undefined,
        handleSuccess: onClose,
    });
    const isCreation = !activityInfo?.id;
    const methods = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
        mode: 'onSubmit',
    });
    const { handleSubmit, watch, formState: { isSubmitting, isDirty }, setValue, } = methods;
    const { recurrence } = watch();
    useEffect(() => {
        if (!recurrence || activityInfo.dateEnd) {
            return;
        }
        const now = moment();
        switch (parseInt(recurrence.toString())) {
            case ACTIVITY_RECURRENCE_EVERY_DAYS:
                now.add(4, 'days');
                break;
            case ACTIVITY_RECURRENCE_EVERY_WEEKS:
                now.add(4, 'weeks');
                break;
            case ACTIVITY_RECURRENCE_EVERY_MONTHS:
                now.add(4, 'months');
                break;
            case ACTIVITY_RECURRENCE_EVERY_YEARS:
                now.add(2, 'years');
                break;
            default:
                break;
        }
        setValue('dateEnd', new Date(now.format('YYYY-MM-DD')));
    }, [recurrence]);
    const onSubmit = (data) => {
        const { id, plotId, contactId, projectId, date, dateEnd, time, endTime, agendaType, comment, status, recurrence, title, location, updateOnlyOne, groupActivityId, } = data;
        if (allDay) {
            date.setHours(0, 0, 0, 0);
        }
        const startDate = agendaType === AGENDA_ACTIVITY_TYPE_EVENT
            ? date.setHours(time.getHours(), time.getMinutes(), 0, 0)
            : date;
        let dateEndParsed = agendaType === AGENDA_ACTIVITY_TYPE_EVENT
            ? date.setHours(endTime.getHours(), endTime.getMinutes(), 0, 0)
            : dateEnd;
        if (allDay) {
            dateEndParsed = null;
        }
        const activityData = {
            id,
            plotId,
            contactId,
            projectId,
            date: format(startDate, 'yyyy-MM-dd HH:mm:ss'),
            dateEnd: dateEndParsed
                ? format(dateEndParsed, 'yyyy-MM-dd HH:mm:ss')
                : undefined,
            agendaType,
            comment,
            status: parseInt(status),
            title,
            location,
            recurrence,
            updateOnlyOne,
            groupActivityId,
        };
        try {
            if (!isCreation) {
                updateActivity(activityData);
                return;
            }
            createActivity(activityData);
        }
        catch (e) {
            onErrorCallback(e);
        }
    };
    return (<FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <Box height="100%" display="flex" justifyContent="space-between" flexDirection="column" alignItems="baseline">
          <VStack width="full" spacing={4} alignItems="flex-start">
            {!!activityInfo?.projectId && (<ProjectSelect name="projectId" disabled={true}/>)}

            {agendaType !== AGENDA_ACTIVITY_TYPE_LAND_POINT &&
            (agendaType !== AGENDA_ACTIVITY_TYPE_EVENT ? (<TextInput name="title" label={t(`activity.fields.${agendaTypeValue}.title.label`)} placeholder={t(`activity.fields.${agendaTypeValue}.title.placeholder`)}/>) : (<TextInput label={t(`activity.fields.${agendaTypeValue}.object.label`)} name="title" placeholder={t(`activity.fields.${agendaTypeValue}.object.placeholder`)}/>))}

            {/* field value not sent, only used for display purposes}
        {/* TODO do we need to make a plot selector to create action from 0? */}
            {!!activityInfo?.cadastreId && (<TextInput name="cadastreId" disabled label={t('activity.fields.common.cadastre_id.label')}/>)}

            {agendaType !== AGENDA_ACTIVITY_TYPE_LAND_POINT &&
            (agendaType === AGENDA_ACTIVITY_TYPE_TASK ? (<VStack width="full">
                  <DateTimePickerInput name="date" label={t(`activity.fields.${agendaTypeValue}.date`)} required withTime={!allDay}/>
                  <FormControl display="flex">
                    <Checkbox defaultChecked={allDay} onChange={(e) => {
                    if (e.currentTarget.checked) {
                        setAllDay(true);
                    }
                    else {
                        setAllDay(false);
                    }
                }}/>
                    <FormLabel margin="0" paddingLeft={3} cursor="pointer" lineHeight="normal">
                      {t('activity.all_day')}
                    </FormLabel>
                  </FormControl>
                </VStack>) : (<DateTimePickerInput name="date" label={t(`activity.fields.${agendaTypeValue}.date`)} required withTime={agendaType === AGENDA_ACTIVITY_TYPE_REMINDER}/>))}

            {agendaType === AGENDA_ACTIVITY_TYPE_EVENT && (<HStack width="100%">
                <DateTimePickerInput name="time" label={t(`activity.fields.${agendaTypeValue}.time.label`)} required timeOnly/>
                <DateTimePickerInput name="endTime" label={t(`activity.fields.${agendaTypeValue}.end_time.label`)} required timeOnly/>
              </HStack>)}

            {agendaType !== AGENDA_ACTIVITY_TYPE_LAND_POINT && (<ContactSelect name="contactId" optional label={t('contact.actions.create_existent_contact')}/>)}

            {agendaType !== AGENDA_ACTIVITY_TYPE_LAND_POINT &&
            (agendaType === AGENDA_ACTIVITY_TYPE_REMINDER ? (<SelectInput label={t('activity.fields.reminder.recurrence.label')} name="recurrence" options={ACTIVITY_RECURRENCE_OPTIONS.map((option) => ({
                    value: option?.value,
                    label: t(option?.label),
                }))} required registerOption={{ valueAsNumber: true }}/>) : (<TextInput name="recurrence" type="hidden" value={ACTIVITY_RECURRENCE_NO_RECURRENCE}/>))}

            {agendaType === AGENDA_ACTIVITY_TYPE_REMINDER &&
            recurrence > ACTIVITY_RECURRENCE_ONCE && (<DateTimePickerInput name="dateEnd" label={t(`activity.fields.${agendaTypeValue}.date_end`)} required maxDate={dateAddDays(new Date(), 366 * 2)}/>)}

            {agendaType === AGENDA_ACTIVITY_TYPE_EVENT && (<TextInput name="location" label={t('activity.fields.event.location.label')}/>)}

            {agendaType !== AGENDA_ACTIVITY_TYPE_REMINDER && (<TextAreaInput name="comment" label={t('activity.fields.common.comment.label')} required={agendaType === AGENDA_ACTIVITY_TYPE_LAND_POINT}/>)}

            {agendaType !== AGENDA_ACTIVITY_TYPE_EVENT && (<SelectInput label={t('activity.fields.common.status.label')} name="status" options={[
                {
                    label: t('activity.fields.common.status.not_finished'),
                    value: 0,
                },
                {
                    label: t('activity.fields.common.status.finished'),
                    value: 1,
                },
            ]} required registerOption={{ valueAsNumber: true }}/>)}
            <TextInput name="groupActivityId" type="hidden"/>
          </VStack>
          <HStack paddingY={5} justifyContent="flex-end" alignItems={'baseline'} width="100%">
            <Button width="100px" paddingX={10} variant="ghost" onClick={onClose}>
              {t('global.actions.cancel')}
            </Button>
            {agendaType !== AGENDA_ACTIVITY_TYPE_REMINDER ||
            isCreation ||
            !groupActivityId ? (<Button type="submit" width="100px" paddingX={10} variant="primary" disabled={isSubmitting || !isDirty || isCreating || isUpdating}>
                {isCreation
                ? t('global.actions.add')
                : t('global.actions.modify')}
              </Button>) : (<ActivityReminderModal isSubmitting={isSubmitting} setValue={setValue} handleSubmit={handleSubmit(onSubmit)} triggerItem={(onOpen) => (<Button width="100px" paddingX={10} variant="primary" disabled={isSubmitting || isCreating || isUpdating} onClick={onOpen}>
                    {t('global.actions.modify')}
                  </Button>)}/>)}
          </HStack>
        </Box>
      </form>
    </FormProvider>);
};
export default ActivityForm;
