import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure, } from '@chakra-ui/react';
import useNews from 'hooks/news/useNews';
import useNewsAllRead from 'hooks/news/useNewsAllRead';
import { useSetNewsSelected, useToggleMenu, } from '../../hooks/contexts/useMenuContext';
import { menuItems } from '../sidebar/utils';
const NewsModal = () => {
    const { t } = useTranslation();
    const setNewsSelected = useSetNewsSelected();
    const toggleMenu = useToggleMenu();
    const { isInitialLoading, data: news } = useNews();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [lastNewsUnread, setLastNewsUnread] = useState(null);
    const newsUnread = news?.filter((item) => item.unread);
    const mutation = useNewsAllRead();
    useEffect(() => {
        if (newsUnread?.length > 0) {
            onOpen();
            mutation.mutate();
            setLastNewsUnread(newsUnread[0]);
        }
    }, [news]);
    const handleRead = () => {
        onClose();
        if (lastNewsUnread) {
            setNewsSelected(lastNewsUnread.id);
            toggleMenu(menuItems?.NEWS, true);
        }
    };
    if (isInitialLoading || !lastNewsUnread) {
        return null;
    }
    return (<Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{lastNewsUnread.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody textStyle="paragraph">
          <Box marginBottom={2} textStyle="paragraph" fontSize="13px" color="colorMode.font900" dangerouslySetInnerHTML={{ __html: lastNewsUnread.content }}/>
          <Box textAlign="right">
            <Button variant="primary" onClick={handleRead}>
              {t(`news.read_news`)}
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>);
};
export default NewsModal;
