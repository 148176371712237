import pick from 'lodash/pick';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { VECTOR_TYPES } from 'types/types';
import { filtersKey } from 'utils/filters';
import { getTileBoundingBox } from 'utils/geo';
import { getBaseTileUrlHost3 } from 'utils/multiplexage';
import { CST_POSITIONS } from 'utils/tilesPositions';
const URL_KEYS = {
    [filtersKey.ELECTRICAL_NETWORKS_HIGH_VOLTAGE_LINE]: 'lignes_electriques_aeriennes',
    [filtersKey.ELECTRICAL_NETWORKS_UNDERGROUND_LINE]: 'lignes_electriques_sous_terre',
    [filtersKey.ELECTRICAL_NETWORKS_ELECTRICITY_PYLON]: 'lignes_electriques_pylones',
};
const getLayerTileUrl = (map, tileWidth, tileHeight, enabledFilters) => (coord, zoom) => {
    const boundingBox = getTileBoundingBox(coord, zoom, map, tileWidth, tileHeight);
    const urlParts = Object.values(pick(URL_KEYS, enabledFilters));
    return (getBaseTileUrlHost3() +
        'cgi-bin/mm/mapserv.cgi?map=/var/data/public_maps/project_reseau_electrique.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=' +
        urlParts.join(',') +
        '&TILED=true&WIDTH=' +
        tileWidth +
        '&&HEIGHT=' +
        tileHeight +
        '&&CRS=EPSG:4326&STYLES=&BBOX=' +
        boundingBox);
};
const getLegends = (enabledFilters) => {
    const legends = {};
    if (enabledFilters.includes(filtersKey.ELECTRICAL_NETWORKS_HIGH_VOLTAGE_LINE)) {
        legends.electrical_networks_45 = {
            layerKey: filtersKey.ELECTRICAL_NETWORKS_HIGH_VOLTAGE_LINE,
            label: 'electrical_networks_45',
            needsTranslate: true,
            color: '#000000',
        };
        legends.electrical_networks_63 = {
            layerKey: filtersKey.ELECTRICAL_NETWORKS_HIGH_VOLTAGE_LINE,
            label: 'electrical_networks_63',
            needsTranslate: true,
            color: '#BF0054',
        };
        legends.electrical_networks_90 = {
            layerKey: filtersKey.ELECTRICAL_NETWORKS_HIGH_VOLTAGE_LINE,
            label: 'electrical_networks_90',
            needsTranslate: true,
            color: '#FF8000',
        };
        legends.electrical_networks_150 = {
            layerKey: filtersKey.ELECTRICAL_NETWORKS_HIGH_VOLTAGE_LINE,
            label: 'electrical_networks_150',
            needsTranslate: true,
            color: '#003DB0',
        };
        legends.electrical_networks_225 = {
            layerKey: filtersKey.ELECTRICAL_NETWORKS_HIGH_VOLTAGE_LINE,
            label: 'electrical_networks_225',
            needsTranslate: true,
            color: '#009414',
        };
        legends.electrical_networks_400 = {
            layerKey: filtersKey.ELECTRICAL_NETWORKS_HIGH_VOLTAGE_LINE,
            label: 'electrical_networks_400',
            needsTranslate: true,
            color: '#FF0000',
        };
    }
    if (enabledFilters.includes(filtersKey.ELECTRICAL_NETWORKS_UNDERGROUND_LINE)) {
        legends.electrical_networks_underground_45 = {
            layerKey: filtersKey.ELECTRICAL_NETWORKS_UNDERGROUND_LINE,
            label: 'electrical_networks_45',
            needsTranslate: true,
            color: '#000000',
        };
        legends.electrical_networks_underground_63 = {
            layerKey: filtersKey.ELECTRICAL_NETWORKS_UNDERGROUND_LINE,
            label: 'electrical_networks_63',
            needsTranslate: true,
            color: '#BF0054',
        };
        legends.electrical_networks_underground_90 = {
            layerKey: filtersKey.ELECTRICAL_NETWORKS_UNDERGROUND_LINE,
            label: 'electrical_networks_90',
            needsTranslate: true,
            color: '#FF8000',
        };
        legends.electrical_networks_underground_150 = {
            layerKey: filtersKey.ELECTRICAL_NETWORKS_UNDERGROUND_LINE,
            label: 'electrical_networks_150',
            needsTranslate: true,
            color: '#003DB0',
        };
        legends.electrical_networks_underground_225 = {
            layerKey: filtersKey.ELECTRICAL_NETWORKS_UNDERGROUND_LINE,
            label: 'electrical_networks_225',
            needsTranslate: true,
            color: '#009414',
        };
        legends.electrical_networks_underground_400 = {
            layerKey: filtersKey.ELECTRICAL_NETWORKS_UNDERGROUND_LINE,
            label: 'electrical_networks_400',
            needsTranslate: true,
            color: '#FF0000',
        };
    }
    if (enabledFilters.includes(filtersKey.ELECTRICAL_NETWORKS_ELECTRICITY_PYLON)) {
        legends.electrical_networks_pylon = {
            layerKey: filtersKey.ELECTRICAL_NETWORKS_ELECTRICITY_PYLON,
            label: 'electrical_networks_pylon',
            needsTranslate: true,
            color: '#000000',
            opacity: 0.5,
            borderColor: '#000000',
            borderWidth: 2,
            height: 7,
            polygonType: VECTOR_TYPES.point,
        };
    }
    return legends;
};
const useElectricalNetworksLayers = (filterName) => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filterName],
        getLegends,
    })(filterName);
};
const ElectricalNetworksLayers = ({ filterName }) => {
    useElectricalNetworksLayers(filterName);
    return null;
};
export default ElectricalNetworksLayers;
