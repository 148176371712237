import { useMemo } from 'react';
import { useQueries } from '@tanstack/react-query';
import { useSelectedBuilding } from 'hooks/contexts/useTabsContext';
import { fetchPlotBuildings, getQueryKey, SELECTED_BUILDING_BORDER, } from './usePlotBuildings';
const usePlotsBuildings = (plots) => {
    const selectedBuilding = useSelectedBuilding();
    const results = useQueries({
        queries: Object.keys(plots).map((plotId) => {
            const plot = plots[plotId];
            return {
                queryKey: getQueryKey(plot.townId, plotId),
                queryFn: fetchPlotBuildings(plot.townId, plotId),
                enabled: !!plot.townId && !!plotId,
            };
        }),
    });
    const buildingsIds = [];
    results.forEach((result) => {
        const data = (result.data || []);
        data.forEach((building) => {
            buildingsIds.push(building.buildingId);
        });
    });
    const isLoading = results.some((result) => result.isLoading);
    const data = useMemo(() => {
        const buildings = {};
        results.forEach((result) => {
            const data = result.data || [];
            data.forEach((building) => {
                buildings[building.buildingId] = {
                    ...building,
                    ...(building.buildingId === selectedBuilding
                        ? SELECTED_BUILDING_BORDER
                        : {}),
                };
            });
        });
        return buildings;
    }, [JSON.stringify(buildingsIds), selectedBuilding]);
    return { data, isLoading };
};
export default usePlotsBuildings;
