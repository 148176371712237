import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IOPolygonLayerItem } from 'types/api';
import { IOCoordinate } from 'types/types';
const IOUserProject = iots.type({
    id: iots.string,
    townId: iots.string,
    townIds: iots.array(iots.string),
    town: iots.string,
    department: iots.string,
    label: iots.string,
    status: iots.number,
    contactCount: iots.number,
    plotCount: iots.number,
    lng: IOCoordinate,
    lat: IOCoordinate,
    townLng: IOCoordinate,
    townLat: IOCoordinate,
    surface: iots.number,
    turnover: iots.number,
    rightToBuild: iots.union([iots.number, iots.string]),
    lots: iots.union([iots.number, iots.string]),
    modifiedAt: iots.string,
    plotIds: iots.array(iots.string),
    plots: iots.array(IOPolygonLayerItem),
});
export const QUERY_KEY = ['user-projects'];
export const fetchUserProjectsList = () => () => get(iots.array(IOUserProject), 'legacy/user-projects');
const useUserProjectsList = () => {
    const { data: userProjects, isInitialLoading } = useQuery({
        queryKey: QUERY_KEY,
        queryFn: fetchUserProjectsList(),
    });
    const getProjectDetail = (id) => userProjects?.find((project) => project?.id === id);
    return { userProjects, isInitialLoading, getProjectDetail };
};
export default useUserProjectsList;
