import * as React from 'react';
import { IconButton, VStack } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MAP_BUTTONS } from 'analytics/useAnalytics';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useMap } from 'hooks/contexts/useMapContext';
import Geoloc from '../../../images/icons/Geoloc';
const CommuneCenter = () => {
    const map = useMap();
    const selectedTown = useSelectedTown();
    const { trackEvent } = useAnalytics();
    const onClick = () => {
        const coordinates = new google.maps.LatLng(parseFloat(selectedTown.lat), parseFloat(selectedTown.lng));
        map.setCenter(coordinates);
    };
    return (<VStack backgroundColor="colorMode.background800" borderRadius="md" spacing={0} boxShadow="lg">
      <IconButton aria-label="zoom-less" variant="ghost" onClick={() => {
            trackEvent({
                category: ANALYTICS_MAP_BUTTONS,
                action: 'Recentrer sur la commune',
            });
            onClick();
        }} height="32px" minWidth="32px" color="blue.500" icon={<Geoloc fill="var(--chakra-colors-gray-600)"/>}/>
    </VStack>);
};
export default CommuneCenter;
