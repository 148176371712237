import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as Navigation } from 'react-router-dom';
import { Box, Heading, HStack, Link, Spinner, Text, VStack, } from '@chakra-ui/react';
import { LOGIN_URL } from 'app';
import CarouselHome from 'components/CarouselHome';
import LanguageDropdown from 'components/dropdowns/LanguageDropdown';
import ResetPasswordForm from 'components/security/ResetPasswordForm';
import useResetPassword from 'hooks/useResetPassword';
const ResetPasswordPage = () => {
    const { t } = useTranslation();
    const { isLoading, data } = useResetPassword();
    return (<Box as="main" width="100%">
      <HStack spacing={0}>
        <Box width="50%" height="100vh">
          <CarouselHome />
        </Box>
        <Box width="50%">
          <VStack height="100vh" bgColor="colorMode.background900" justifyContent="center">
            <Box position="absolute" right={5} top={5}>
              <LanguageDropdown />
            </Box>

            <VStack flex={1} justifyContent="center" alignItems="center">
              <Heading as="h3" variant="h3" marginBottom={3}>
                {t('reset_password.title')}
              </Heading>

              {isLoading && <Spinner />}

              {!isLoading && data?.error && (<Text textStyle="paragraph" dangerouslySetInnerHTML={{
                __html: data.error,
            }}/>)}
              {!isLoading && !data?.error && <ResetPasswordForm />}

              <Box width="full" paddingTop={5}>
                <Text textStyle="paragraph" textAlign="center">
                  {t('reset_password.has_account')}
                  <Navigation to={LOGIN_URL}>
                    <Text as="span" color="red.500" paddingLeft={2} cursor="pointer">
                      {t('reset_password.login')}
                    </Text>
                  </Navigation>
                </Text>
              </Box>
            </VStack>

            <Box width="full" paddingBottom="56px">
              <Text textStyle="paragraph" textAlign="center">
                {t('login.no_account_info')}
                <Link href="https://kelfoncier.com/test-gratuit" isExternal paddingLeft={2} cursor="pointer" color="colorMode.font900">
                  {t('login.signup')}
                </Link>
              </Text>
            </Box>
          </VStack>
        </Box>
      </HStack>
    </Box>);
};
export default ResetPasswordPage;
