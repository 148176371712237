import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Heading, MenuItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, } from '@chakra-ui/react';
import TwoFactorConfiguration from 'components/modals/security/TwoFactorConfiguration';
const SecurityModal = () => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (<MenuItem onClick={onOpen} paddingX={4} paddingY={3}>
      <Text textStyle="paragraph" color="colorMode.font900">
        {t('header.account.security')}
      </Text>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">
            <Heading as="h2" variant="h4">
              {t('security.title')}
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody textStyle="paragraph" color="colorMode.font900">
            <TwoFactorConfiguration />
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              {t('security.close')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </MenuItem>);
};
export default SecurityModal;
