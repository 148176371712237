const FLOOR_SPACE_RATIO = 80;
const LIVING_SPACE_RATIO = 95;
const AVERAGE_ACCOMMODATION_SIZE = 60;
const PARKING_SPACE_SIZE = 20;
const UNDERGROUND_PARKING_SIZE = 30;
// Voir dans le legacy _gestionMultiParcelles.js à partir de la ligne 102
// utils fonctions
const computeTotalSum = (allData, key) => {
    let sum = 0;
    allData.forEach((data) => {
        sum += data[key];
    });
    return isNaN(sum) ? 0 : sum;
};
const computeTotalSumSideLimits = (allData) => {
    let minSideLimits = 100;
    let maxSideLimits = 0;
    allData.forEach((data) => {
        if (data.sideLimits < minSideLimits) {
            minSideLimits = data.sideLimits;
        }
        if (data.sideLimits > maxSideLimits) {
            maxSideLimits = data.sideLimits;
        }
    });
    // 1 seule parcelle
    if (allData && allData.length === 1) {
        return minSideLimits;
    }
    return minSideLimits + maxSideLimits;
};
const computeTotalMax = (allData, key) => {
    const allValues = allData.map((data) => data[key]);
    // Get max data
    return allValues.reduce((a, b) => (a > b ? a : b), 0);
};
// Surface de l'emprise au sol maximum autorisée
const computeAllowedFootprintSurface = ({ sourceData, }) => {
    return (((sourceData.constructibleSurface ?? 0) * (sourceData.footprint ?? 0)) / 100);
};
// Surface de l'emprise au sol maximum autorisée
const computeRetainedFootprintSurface = ({ sourceData, allData, index, }) => {
    const computedTotal = computeComputedFootprintSurface({
        sourceData,
        allData,
        index,
    });
    const allowedTotal = computeAllowedFootprintSurface({ sourceData });
    if (computedTotal < allowedTotal && allowedTotal < 3000) {
        return computedTotal;
    }
    else {
        return allowedTotal;
    }
};
const computeTotalAllowedFootprintSurface = (allData) => {
    return allData
        .map((info) => computeAllowedFootprintSurface({ sourceData: info }))
        .reduce((a, b) => a + b, 0);
};
// Emprise au sol autorisée
const computeTotalFootprint = (allData) => {
    const total = (computeTotalAllowedFootprintSurface(allData) * 100) /
        plotFeasibilityFields.constructibleSurface.getTotalValue(allData);
    return isNaN(total) ? 0 : total;
};
const computeSideLimits = ({ sourceData, allData, index, }) => {
    if (allData && allData.length > 2) {
        let plotIdMinSideLimits = null;
        let plotIdMaxSideLimits = null;
        let minSideLimits = 100;
        let maxSideLimits = 0;
        // On récupère le sideLimit minimum et maximum pour ne comptabiliser que ces limites
        allData.forEach((data) => {
            if (data.sideLimits < minSideLimits) {
                minSideLimits = data.sideLimits;
                plotIdMinSideLimits = data.id;
            }
            if (data.sideLimits > maxSideLimits) {
                maxSideLimits = data.sideLimits;
                plotIdMaxSideLimits = data.id;
            }
        });
        if (plotIdMinSideLimits === plotIdMaxSideLimits && index === 0) {
            return sourceData.sideLimits;
        }
        else if (plotIdMinSideLimits === sourceData.id ||
            plotIdMaxSideLimits === sourceData.id) {
            return sourceData.sideLimits;
        }
        return 0;
    }
    return sourceData.sideLimits;
};
const computeNbSideLimits = ({ sourceData, allData, index, }) => {
    // On considère que les parcelles sont côte à côte et par conséquent on ne calcule qu'une seul de leur limite
    if (allData && allData.length === 2) {
        return 1;
    }
    else if (allData && allData.length > 2) {
        let plotIdMinSideLimits = null;
        let plotIdMaxSideLimits = null;
        let minSideLimits = 100;
        let maxSideLimits = 0;
        // On récupère le sideLimit minimum et maximum pour ne comptabiliser que ces limites
        allData.forEach((data) => {
            if (data.sideLimits < minSideLimits) {
                minSideLimits = data.sideLimits;
                plotIdMinSideLimits = data.id;
            }
            if (data.sideLimits > maxSideLimits) {
                maxSideLimits = data.sideLimits;
                plotIdMaxSideLimits = data.id;
            }
        });
        // On définie 1 limite pour au moins 2 parcelles
        if (plotIdMinSideLimits === plotIdMaxSideLimits && index < 1) {
            return 1;
        }
        else if (plotIdMinSideLimits === sourceData.id ||
            plotIdMaxSideLimits === sourceData.id) {
            return 1;
        }
        return 0;
    }
    return 2;
};
// Linéaire de la parcelle
// Index correspond au tableau des plotFeasibility (plot autre que le principal)
// Si l'index est égal à 0 alors c'est la première parcelle (et avec la main ça fait 2)
// au delà de 2 parcelles on ne compte plus les sideLimits (vu que logiquement les parcelles sont collés)
const computeBuildingLine = ({ sourceData, allData, index, }) => {
    const nbLimit = computeNbSideLimits({ sourceData, allData, index });
    const buildingLine = Math.max(0, sourceData.plotLineFirstSegmentMax - sourceData.sideLimits * nbLimit);
    return !isNaN(buildingLine) ? buildingLine : 0;
};
const computeTotalBuildingLine = (allData) => {
    return Math.max(0, computeTotalSum(allData, 'plotLineFirstSegmentMax') -
        computeTotalSumSideLimits(allData) * 2);
};
// Surface de l'emprise au sol maximum réalisable
const computeComputedFootprintSurface = ({ sourceData, allData, index, }) => {
    return (computeBuildingLine({ sourceData, allData, index }) *
        (sourceData.depth ?? 0));
};
const computeTotalComputedFootprintSurface = (allData) => {
    return (computeTotalBuildingLine(allData) *
        Math.max(...Object.values(allData).map((plot) => plot.depth)));
};
const computeTotalRetainedFootprintSurface = (allData) => {
    let total = 0;
    allData.forEach((sourceData, index) => {
        total += computeRetainedFootprintSurface({ sourceData, allData, index });
    });
    return total;
};
// Surface brute
const computeGrossSurface = ({ sourceData, allData, index, }) => {
    const allowedSurface = computeAllowedFootprintSurface({ sourceData });
    const computedSurface = computeComputedFootprintSurface({
        sourceData,
        allData,
        index,
    });
    const groundSurface = 'baseCalculationSelected' in sourceData
        ? sourceData.baseCalculationSelected === 'allowedFootprintSurface'
            ? allowedSurface // Calcul en fonction de l'emprise de surface au sol autorisé (checkbox)
            : computedSurface // Calcul en fonction de l'emprise de surface au sol calculé (checkbox)
        : computeRetainedFootprintSurface({ sourceData, allData, index });
    return computeGrossSurfaceByNbLevelRatio(groundSurface, sourceData);
};
const computeGrossSurfaceByNbLevelRatio = (groundSurface, sourceData) => {
    if ('baseCalculationSelected' in sourceData) {
        let groundSurfaceTotal = 0;
        for (let i = 0; i < sourceData.nbLevels; i++) {
            const percent = sourceData[`level-${i}`] !== undefined ? sourceData[`level-${i}`] : 100;
            groundSurfaceTotal += groundSurface * (percent / 100);
        }
        return groundSurfaceTotal;
    }
    else {
        return sourceData.nbLevels * groundSurface;
    }
};
const computeTotalGrossSurface = (allData) => {
    return allData
        .map((info, index) => computeGrossSurface({ sourceData: info, allData, index }))
        .reduce((a, b) => a + b, 0);
};
// Surface de plancher
export const computeFloorSpace = ({ sourceData, allData, index, }) => {
    const ratio = 'floorSpaceRatio' in sourceData
        ? sourceData.floorSpaceRatio
        : FLOOR_SPACE_RATIO;
    return (computeGrossSurface({ sourceData, allData, index }) * ratio) / 100;
};
const computeTotalFloorSpace = (allData) => {
    return (computeTotalGrossSurface(allData) * FLOOR_SPACE_RATIO) / 100;
};
// Surface habitable
const computeLivingSpace = ({ sourceData, allData, index, }) => {
    const ratio = 'livingSpaceRatio' in sourceData
        ? sourceData.livingSpaceRatio
        : LIVING_SPACE_RATIO;
    return (computeFloorSpace({ sourceData, allData, index }) * ratio) / 100;
};
const computeTotalLivingSpace = (allData) => {
    return (computeTotalFloorSpace(allData) * LIVING_SPACE_RATIO) / 100;
};
// Nombre de logements
export const computeAccommodationsNumber = ({ sourceData, allData, index, }) => {
    const ratio = 'averageAccommodationSize' in sourceData
        ? sourceData.averageAccommodationSize
        : AVERAGE_ACCOMMODATION_SIZE;
    return Math.floor(computeLivingSpace({ sourceData, allData, index }) / ratio);
};
const computeTotalAccommodationsNumber = (allData) => {
    return computeTotalLivingSpace(allData) / AVERAGE_ACCOMMODATION_SIZE;
};
// dont logements sociaux
export const computeSocialAccommodationsNumber = ({ sourceData, allData, index, }) => {
    return computeFloorSpace({ sourceData, allData, index }) >=
        sourceData.socialMixThreshold
        ? Math.ceil((computeAccommodationsNumber({ sourceData, index }) *
            sourceData.socialMixProportion) /
            100)
        : 0;
};
const computeTotalSocialAccommodationsNumber = (allData) => computeTotalFloorSpace(allData) >=
    plotFeasibilityFields.socialMixThreshold.getTotalValue(allData)
    ? Math.ceil((computeTotalAccommodationsNumber(allData) *
        plotFeasibilityFields.socialMixProportion.getTotalValue(allData)) /
        100)
    : 0;
// Nombre de places totales exigées
const computeTotalRequiredParkingNb = ({ sourceData, allData, index, }) => {
    if (sourceData.requiredParkingNb === 0) {
        if (sourceData.surfaceForOneParking === 0) {
            return 0;
        }
        const total = computeFloorSpace({ sourceData, allData, index }) /
            sourceData.surfaceForOneParking;
        return isNaN(total) ? 0 : total;
    }
    return Math.floor(computeAccommodationsNumber({ sourceData, allData, index }) *
        sourceData.requiredParkingNb);
};
const computeSumTotalRequiredParkingNb = (allData) => {
    if (plotFeasibilityFields.requiredParkingNb.getTotalValue(allData) === 0) {
        if (plotFeasibilityFields.surfaceForOneParking.getTotalValue(allData) === 0) {
            return 0;
        }
        const total = computeTotalFloorSpace(allData) /
            plotFeasibilityFields.surfaceForOneParking.getTotalValue(allData);
        return isNaN(total) ? 0 : total;
    }
    return (computeTotalAccommodationsNumber(allData) *
        plotFeasibilityFields.requiredParkingNb.getTotalValue(allData));
};
// Espace constructible en surface pour box et garages
const computeAboveGroundAvailableParkingSurface = ({ sourceData, allData, index, }) => {
    if ('baseCalculationSelected' in sourceData &&
        sourceData.baseCalculationSelected === 'allowedFootprintSurface') {
        return 0;
    }
    const aboveGroundAvailableParkingSurface = computeAllowedFootprintSurface({ sourceData }) -
        computeComputedFootprintSurface({ sourceData, allData, index });
    return aboveGroundAvailableParkingSurface >= 0
        ? aboveGroundAvailableParkingSurface
        : 0;
};
const computeTotalAboveGroundAvailableParkingSurface = (allData) => {
    let totalAboveGroundAvailableParkingSurface = 0;
    if (allData) {
        totalAboveGroundAvailableParkingSurface =
            computeTotalAllowedFootprintSurface(allData) -
                computeTotalComputedFootprintSurface(allData);
    }
    return totalAboveGroundAvailableParkingSurface >= 0
        ? totalAboveGroundAvailableParkingSurface
        : 0;
};
// Nombre de places constructibles en surface
const computeAboveGroundAvailableParkingSpaces = ({ sourceData, allData, index, }) => {
    const aboveGroundAvailableParkingSurface = computeAboveGroundAvailableParkingSurface({ sourceData, allData, index });
    if (aboveGroundAvailableParkingSurface === 0) {
        return 0;
    }
    const ratio = 'parkingSpaceSize' in sourceData
        ? sourceData.parkingSpaceSize
        : PARKING_SPACE_SIZE;
    return Math.floor(aboveGroundAvailableParkingSurface / ratio);
};
const computeTotalAboveGroundAvailableParkingSpaces = (allData) => {
    const aboveGroundAvailableParkingSurface = computeTotalAboveGroundAvailableParkingSurface(allData);
    if (aboveGroundAvailableParkingSurface === 0) {
        return 0;
    }
    return aboveGroundAvailableParkingSurface / PARKING_SPACE_SIZE;
};
// Espace constructible par niveau de sous-sol
const computeConstructibleSpacePerUndergroundLevel = ({ sourceData, }) => {
    return ((sourceData.constructibleSurface *
        (100 - sourceData.inconstructibleUndergroundSurface)) /
        100);
};
const computeTotalConstructibleSpacePerUndergroundLevel = (allData) => {
    let totalConstructibleByLevelUndergroundSurface = 0;
    allData.forEach((data) => {
        totalConstructibleByLevelUndergroundSurface +=
            computeConstructibleSpacePerUndergroundLevel({
                sourceData: data,
            });
    });
    return totalConstructibleByLevelUndergroundSurface ?? 0;
};
const computeTotalInconstructibleSpacePerUndergroundSurface = (allData) => {
    let totalInconstructibleSpaceUndergroundSurface = 0;
    allData.forEach((data) => {
        totalInconstructibleSpaceUndergroundSurface +=
            data.constructibleSurface * (data.inconstructibleUndergroundSurface / 100);
    });
    const total = (totalInconstructibleSpaceUndergroundSurface /
        plotFeasibilityFields.constructibleSurface.getTotalValue(allData)) *
        100;
    return isNaN(total) ? 0 : total;
};
// Maximum de places par niveau de sous-sol
const computeMaxParkingSpacePerUndergroundLevel = ({ sourceData, }) => {
    const ratio = 'undergroundParkingSize' in sourceData
        ? sourceData.undergroundParkingSize
        : UNDERGROUND_PARKING_SIZE;
    return computeConstructibleSpacePerUndergroundLevel({ sourceData }) / ratio;
};
const computeTotalMaxParkingSpacePerUndergroundLevel = (allData) => {
    return (computeTotalConstructibleSpacePerUndergroundLevel(allData) /
        UNDERGROUND_PARKING_SIZE);
};
// Nombre de niveaux de sous-sol
const computeNbUndergroundLevels = ({ sourceData, index, }) => {
    if (computeTotalRequiredParkingNb({ sourceData, index }) <
        computeAboveGroundAvailableParkingSpaces({ sourceData, index })) {
        return 0;
    }
    const parkingToConstructUnderground = computeTotalRequiredParkingNb({ sourceData, index }) -
        computeAboveGroundAvailableParkingSpaces({ sourceData, index });
    const computedMaxParkingSpace = computeMaxParkingSpacePerUndergroundLevel({
        sourceData,
    });
    if (computedMaxParkingSpace === 0) {
        return 0;
    }
    const nbUndergroundLevels = Math.ceil(parkingToConstructUnderground / computedMaxParkingSpace);
    if (nbUndergroundLevels === Infinity) {
        return 0;
    }
    return nbUndergroundLevels;
};
const computeMaxNbUndergroundLevels = (allData) => {
    let maxNbUndergroundLevels = 0;
    allData.forEach((data, index) => {
        const nbUndergroundLevels = computeNbUndergroundLevels({
            sourceData: data,
            index,
        });
        if (maxNbUndergroundLevels < nbUndergroundLevels) {
            maxNbUndergroundLevels = nbUndergroundLevels;
        }
    });
    return maxNbUndergroundLevels;
};
const FIELD_UNITS = [
    'meters',
    'meters_square',
    'percent',
    'levels',
    'accommodations',
    'per_accommodation',
    'parking_spaces',
];
export const plotFeasibilityFields = {
    constructibleSurface: {
        // calc_saisie_surf_par = surface_const
        getValue: ({ sourceData }) => formatRound(sourceData.constructibleSurface),
        getTotalValue: (allData) => formatRound(computeTotalSum(allData, 'constructibleSurface'), 2),
        unit: 'meters_square',
        mainRow: true,
        borderBottom: true,
    },
    footprint: {
        // calc_saisie_emp_max_aut = emprise_calcul
        getValue: ({ sourceData }) => formatRound(sourceData.footprint),
        getTotalValue: (allData) => formatRound(computeTotalFootprint(allData), 2),
        inputOptions: {
            min: 0,
            max: 100,
        },
        unit: 'percent',
        mainRow: true,
    },
    allowedFootprintSurface: {
        // calc_saisie_surf_emp_cal_1 = parseInt(calc_saisie_surf_par*parseFloat(calc_saisie_emp_max_aut)/100)
        getValue: (data) => formatRound(computeAllowedFootprintSurface(data)),
        getComputedValue: ({ formData }) => formatRound(computeAllowedFootprintSurface({ sourceData: formData })),
        getTotalValue: (allData) => formatRound(computeTotalAllowedFootprintSurface(allData)),
        unit: 'meters_square',
        readOnly: true,
        onCheck: true,
    },
    inconstructibleSurface: {
        // calc_saisie_surf_inconst = zones_inconstructibles_conso_calcul
        getValue: ({ sourceData }) => formatRound(sourceData.inconstructibleSurface),
        getTotalValue: (allData) => formatRound(computeTotalSum(allData, 'inconstructibleSurface'), 2),
        unit: 'meters_square',
        borderBottom: true,
    },
    plotLineFirstSegmentMax: {
        // calc_saisie_lineaire = lineaire_premier_segment_maximal_calcul
        getValue: ({ sourceData }) => formatRound(sourceData.plotLineFirstSegmentMax),
        getTotalValue: (allData) => formatRound(computeTotalSum(allData, 'plotLineFirstSegmentMax'), 2),
        unit: 'meters',
    },
    sideLimits: {
        // calc_saisie_lim_sep_lat = limites_laterales_calcul
        getValue: ({ sourceData }) => formatRound(computeSideLimits({ sourceData })),
        getTotalValue: (allData) => formatRound(computeTotalSumSideLimits(allData)),
        unit: 'meters',
        mainRow: true,
    },
    buildingLine: {
        // calc_saisie_lin_bat = parseInt(calc_saisie_lineaire-(calc_saisie_lim_sep_lat*2)) (0 if negative)
        getValue: (data) => formatRound(computeBuildingLine(data)),
        getComputedValue: ({ formData, allData, index }) => formatRound(computeBuildingLine({ sourceData: formData, allData, index }), 2),
        getTotalValue: (allData) => formatRound(computeTotalBuildingLine(allData), 2),
        unit: 'meters',
        readOnly: true,
    },
    depth: {
        // calc_saisie_prof = profondeur_calcul
        getValue: ({ sourceData }) => formatRound(sourceData.depth),
        getTotalValue: (allData) => formatRound(Math.max(...allData.map((plot) => plot['depth'])), 2),
        unit: 'meters',
    },
    computedFootprintSurface: {
        // calc_saisie_surf_emp_cal_2 = parseInt(calc_saisie_lin_bat*calc_saisie_prof)
        getValue: (data) => formatRound(computeComputedFootprintSurface(data)),
        getComputedValue: ({ formData, allData, index }) => formatRound(computeComputedFootprintSurface({
            sourceData: formData,
            allData,
            index,
        })),
        getTotalValue: (allData) => formatRound(computeTotalComputedFootprintSurface(allData)),
        unit: 'meters_square',
        readOnly: true,
        onCheck: true,
        borderBottom: true,
    },
    retainedFootprintSurface: {
        getValue: (data) => formatRound(computeRetainedFootprintSurface(data)),
        getComputedValue: ({ formData, allData, index }) => {
            if (formData.baseCalculationSelected === 'computedFootprintSurface') {
                return formatRound(computeComputedFootprintSurface({
                    sourceData: formData,
                    allData,
                    index,
                }));
            }
            return formatRound(computeAllowedFootprintSurface({ sourceData: formData }));
        },
        getTotalValue: (allData) => {
            return formatRound(computeTotalRetainedFootprintSurface(allData));
        },
        unit: 'meters_square',
        readOnly: true,
        borderBottom: true,
    },
    nbLevels: {
        // calc_saisie_nb_niveaux = niveaux_regl_epan_calcul
        getValue: ({ sourceData }) => sourceData.nbLevels,
        getTotalValue: (allData) => Math.max(...allData.map((plot) => plot['nbLevels'])),
        unit: 'levels',
        mainRow: true,
        inputOptions: {
            max: 100,
        },
    },
    grossSurface: {
        // calc_saisie_surf_brute
        getValue: (data) => formatRound(computeGrossSurface(data)),
        getComputedValue: ({ formData, allData, index }) => formatRound(computeGrossSurface({ sourceData: formData, allData, index })),
        getTotalValue: (data) => formatRound(computeTotalGrossSurface(data)),
        unit: 'meters_square',
        readOnly: true,
    },
    floorSpaceRatio: {
        // calc_saisie_ratio_1
        getValue: () => FLOOR_SPACE_RATIO,
        getTotalValue: () => FLOOR_SPACE_RATIO,
        unit: 'percent',
    },
    floorSpace: {
        //calc_saisie_surf_planc = parseInt(SommeSurf*calc_saisie_ratio_1/100)
        getValue: (data) => formatRound(computeFloorSpace(data)),
        getComputedValue: ({ formData, allData, index }) => formatRound(computeFloorSpace({ sourceData: formData, allData, index })),
        getTotalValue: (data) => formatRound(computeTotalFloorSpace(data)),
        unit: 'meters_square',
        readOnly: true,
        mainRow: true,
    },
    livingSpaceRatio: {
        // calc_saisie_ratio_2
        getValue: () => LIVING_SPACE_RATIO,
        getTotalValue: () => LIVING_SPACE_RATIO,
        unit: 'percent',
    },
    livingSpace: {
        // calc_saisie_surf_hab = parseInt(calc_saisie_surf_planc*calc_saisie_ratio_2/100);
        getValue: (data) => formatRound(computeLivingSpace(data)),
        getComputedValue: ({ formData, allData, index }) => formatRound(computeLivingSpace({ sourceData: formData, allData, index })),
        getTotalValue: (data) => formatRound(computeTotalLivingSpace(data)),
        unit: 'meters_square',
        readOnly: true,
        borderBottom: true,
    },
    averageAccommodationSize: {
        // calc_saisie_surf_moy_log
        getValue: () => AVERAGE_ACCOMMODATION_SIZE,
        getTotalValue: () => AVERAGE_ACCOMMODATION_SIZE,
        unit: 'meters_square',
    },
    accommodationsNumber: {
        // calc_saisie_nb_log = parseInt(calc_saisie_surf_planc/calc_saisie_surf_moy_log)
        getValue: (data) => formatRound(computeAccommodationsNumber(data)),
        getComputedValue: ({ formData, allData, index }) => formatRound(computeAccommodationsNumber({ sourceData: formData, allData, index })),
        getTotalValue: (data) => formatRound(computeTotalAccommodationsNumber(data)),
        unit: 'accommodations',
        readOnly: true,
        mainRow: true,
        borderBottom: true,
    },
    socialAccommodationsNumber: {
        // calc_label_mixite_nb_log = Math.ceil(calc_label_nb_log*calc_label_mixite_pct_emp/100) (or 0 if no social mix requirements)
        getValue: (data) => formatRound(computeSocialAccommodationsNumber(data)),
        getComputedValue: ({ formData, allData, index }) => formatRound(computeSocialAccommodationsNumber({
            sourceData: formData,
            allData,
            index,
        })),
        getTotalValue: (data) => formatRound(computeTotalSocialAccommodationsNumber(data)),
        unit: 'accommodations',
        readOnly: true,
        mainRow: true,
    },
    socialMixProportion: {
        // calc_saisie_mixite_pct_emp = mixite_pct_emp
        getValue: ({ sourceData }) => sourceData.socialMixProportion,
        getTotalValue: (allData) => {
            return computeTotalMax(allData, 'socialMixProportion');
        },
        unit: 'percent',
    },
    socialMixThreshold: {
        // calc_saisie_mixite_seuil = mixite_seuil
        getValue: ({ sourceData }) => sourceData.socialMixThreshold,
        getTotalValue: (allData) => {
            return computeTotalMax(allData, 'socialMixThreshold');
        },
        unit: 'meters_square',
        borderBottom: true,
    },
    requiredParkingNb: {
        // calc_saisie_nb_places_exigees = nb_places_exigees
        getValue: ({ sourceData }) => formatRound(sourceData.requiredParkingNb),
        getTotalValue: (allData) => {
            return formatRound(computeTotalMax(allData, 'requiredParkingNb'));
        },
        unit: 'per_accommodation',
    },
    surfaceForOneParking: {
        // calc_saisie_place_surf_planc = stationnement_habitations_calcul
        getValue: ({ sourceData }) => sourceData.surfaceForOneParking,
        getTotalValue: (allData) => {
            return computeTotalMax(allData, 'surfaceForOneParking');
        },
        unit: 'meters_square',
    },
    totalRequiredParkingNb: {
        getValue: (data) => formatRound(computeTotalRequiredParkingNb(data)),
        getComputedValue: ({ formData, allData, index }) => formatRound(computeTotalRequiredParkingNb({ sourceData: formData, allData, index })),
        getTotalValue: (data) => formatRound(computeSumTotalRequiredParkingNb(data)),
        mainRow: true,
        unit: 'parking_spaces',
        readOnly: true,
        borderBottom: true,
    },
    aboveGroundAvailableParkingSurface: {
        // calc_label_surf_ext_constr_park
        getValue: (data) => formatRound(computeAboveGroundAvailableParkingSurface(data)),
        getComputedValue: ({ formData, allData }) => formatRound(computeAboveGroundAvailableParkingSurface({
            sourceData: formData,
            allData,
        })),
        getTotalValue: (data) => formatRound(computeTotalAboveGroundAvailableParkingSurface(data)),
        unit: 'meters_square',
        readOnly: true,
    },
    parkingSpaceSize: {
        // calc_label_surf_park_ext
        getValue: () => PARKING_SPACE_SIZE,
        getTotalValue: () => PARKING_SPACE_SIZE,
        unit: 'meters_square',
    },
    aboveGroundAvailableParkingSpaces: {
        // calc_label_nb_places_ext
        getValue: (data) => formatRound(computeAboveGroundAvailableParkingSpaces(data)),
        getComputedValue: ({ formData, allData, index }) => formatRound(computeAboveGroundAvailableParkingSpaces({
            sourceData: formData,
            allData,
            index,
        })),
        getTotalValue: (data) => formatRound(computeTotalAboveGroundAvailableParkingSpaces(data)),
        mainRow: true,
        unit: 'parking_spaces',
        readOnly: true,
        borderBottom: true,
    },
    inconstructibleUndergroundSurface: {
        // calc_saisie_surf_pct_inconstr_ss_sol = espace_non_constructible
        getValue: ({ sourceData }) => sourceData.inconstructibleUndergroundSurface,
        getTotalValue: (data) => formatRound(computeTotalInconstructibleSpacePerUndergroundSurface(data), 2),
        unit: 'percent',
    },
    constructibleSpacePerUndergroundLevel: {
        // calc_label_surf_constr_ss_sol = parseInt(calc_label_surf_par*(100-calc_label_surf_pct_inconstr_ss_sol)/100);
        getValue: (data) => formatRound(computeConstructibleSpacePerUndergroundLevel(data)),
        getComputedValue: ({ formData }) => formatRound(computeConstructibleSpacePerUndergroundLevel({
            sourceData: formData,
        }), 2),
        getTotalValue: (data) => formatRound(computeTotalConstructibleSpacePerUndergroundLevel(data), 2),
        unit: 'meters_square',
    },
    undergroundParkingSize: {
        // calc_saisie_surf_park
        getValue: () => UNDERGROUND_PARKING_SIZE,
        getTotalValue: () => UNDERGROUND_PARKING_SIZE,
        unit: 'meters_square',
    },
    maxParkingSpacePerUndergroundLevel: {
        // calc_label_nb_places_par_ss_sol
        getValue: (data) => formatRound(computeMaxParkingSpacePerUndergroundLevel(data)),
        getComputedValue: ({ formData }) => formatRound(computeMaxParkingSpacePerUndergroundLevel({
            sourceData: formData,
        })),
        getTotalValue: (data) => formatRound(computeTotalMaxParkingSpacePerUndergroundLevel(data)),
        unit: 'parking_spaces',
        readOnly: true,
    },
    nbUndergroundLevels: {
        getValue: (data) => formatRound(computeNbUndergroundLevels(data)),
        getComputedValue: ({ formData, index }) => formatRound(computeNbUndergroundLevels({ sourceData: formData, index })),
        getTotalValue: (data) => formatRound(computeMaxNbUndergroundLevels(data)),
        unit: 'levels',
        mainRow: true,
        readOnly: true,
    },
};
const computeLevels = (data, key) => {
    const idKey = parseInt(key.slice(-1));
    const ratio = data.nbLevels - idKey;
    if (ratio >= 1) {
        return 100;
    }
    if (ratio < 1 && ratio >= 0) {
        return Math.round(ratio * 100);
    }
    return 0;
};
const computeUndergroundByLevels = (formData, key, allData, index) => {
    const numLevel = parseInt(key.slice(-1));
    const parkingToConstructUnderground = computeTotalRequiredParkingNb({ sourceData: formData, allData, index }) -
        computeAboveGroundAvailableParkingSpaces({
            sourceData: formData,
            allData,
            index,
        });
    const computedMaxParkingSpace = computeMaxParkingSpacePerUndergroundLevel({
        sourceData: formData,
    });
    const nbUndergroundParkingByLevel = parkingToConstructUnderground - computedMaxParkingSpace * numLevel;
    return nbUndergroundParkingByLevel > computedMaxParkingSpace
        ? computedMaxParkingSpace
        : nbUndergroundParkingByLevel > 0
            ? nbUndergroundParkingByLevel
            : 0;
};
export const plotFeasibilityExtraFields = {
    level: {
        getValue: ({ sourceData, id }) => {
            return computeLevels(sourceData, id);
        },
        getTotalValue: (allData, id) => {
            if (!id)
                return 0;
            const allLevels = allData.map((data) => data.nbLevels);
            return computeLevels({ nbLevels: allLevels.reduce((a, b) => (a > b ? a : b), 0) }, id);
        },
        unit: 'percent',
        getExtraInfo: ({ formData, id, allData }) => {
            const surface = formData.baseCalculationSelected === 'allowedFootprintSurface'
                ? computeAllowedFootprintSurface({ sourceData: formData })
                : computeComputedFootprintSurface({
                    sourceData: formData,
                    allData,
                    index: 0,
                });
            const surfaceByLevel = surface / (1 / formData[`level-${parseInt(id.slice(-1))}`]) / 100;
            return `soit ${Math.floor(surfaceByLevel)} m²`;
        },
        inputOptions: {
            min: 0,
            max: 100,
        },
    },
    undergroundLevel: {
        getValue: ({ sourceData, id, allData, index }) => {
            return formatRound(computeUndergroundByLevels(sourceData, id, allData, index));
        },
        getComputedValue: ({ formData, allData, id }) => {
            const totalUndergroundByLevels = computeUndergroundByLevels(formData, 'underground-level-' + id, allData, id);
            return formatRound(totalUndergroundByLevels);
        },
        getTotalValue: (allData, id) => {
            if (!id)
                return 0;
            let totalUndergroundByLevels = 0;
            allData.forEach((data, index) => {
                totalUndergroundByLevels += computeUndergroundByLevels(data, id, allData, index);
            });
            return formatRound(totalUndergroundByLevels);
        },
        unit: 'parking_spaces',
    },
};
export const DEFAULT_VALUES = {
    constructibleSurface: 0, // surface_const || surface_parcelle_origine
    footprint: 0, // emprise_calcul
    inconstructibleSurface: 0, // zones_inconstructibles_conso_calcul
    plotLineFirstSegmentMax: 0, //lineaire_premier_segment_maximal_calcul
    sideLimits: 0, // limites_laterales_calcul
    depth: 0, // profondeur_calcul
    nbLevels: 0, // niveaux_regl_epan_calcul
    nbUndergroundLevels: 0, // nb_ss_sol
    requiredParkingNb: 0, // nb_places_exigees
    surfaceForOneParking: 0, // stationnement_habitations_calcul
    socialMixThreshold: 0,
    socialMixProportion: 0,
    inconstructibleUndergroundSurface: 0, // espace_non_constructible
    baseCalculationSelected: null,
    floorSpaceRatio: FLOOR_SPACE_RATIO, // ratio_1
    livingSpaceRatio: LIVING_SPACE_RATIO, // ratio_2
    averageAccommodationSize: AVERAGE_ACCOMMODATION_SIZE, // surf_moy_log
    parkingSpaceSize: PARKING_SPACE_SIZE, // surf_park_ext
    undergroundParkingSize: UNDERGROUND_PARKING_SIZE, // surf_park
    aboveGroundAvailableParkingSurface: 0, //
};
export const EXTRA_ROWS_LEVEL_KEY = 'level';
export const EXTRA_ROWS_UNDERGROUND_LEVEL_KEY = 'undergroundLevel';
export const getFeasibilityDefaultValues = ({ feasibility, feasibilityPlots, }) => {
    const defaultValues = { ...DEFAULT_VALUES };
    if (Object.keys(feasibilityPlots)?.length) {
        const allField = Object.keys(plotFeasibilityFields);
        if (feasibility) {
            allField.forEach((key) => {
                const field = plotFeasibilityFields[key];
                defaultValues[key] = field.getValue({
                    sourceData: feasibility,
                    formData: defaultValues,
                });
            });
        }
        const allFeasibilityPlotsValues = Object.values(feasibilityPlots) ?? [];
        Object.keys(plotFeasibilityFields).forEach((key) => {
            if (!plotFeasibilityFields[key]?.getTotalValue)
                return;
            if (key === 'nbLevels') {
                const nbRowsMax = Math.round(Math.max(...Object.values(feasibilityPlots).map((plot) => plot[key])));
                if (nbRowsMax === 0) {
                    return;
                }
                Array.from(Array(nbRowsMax).keys()).forEach((row) => {
                    const rowId = `${EXTRA_ROWS_LEVEL_KEY}-${row}`;
                    defaultValues[rowId] = plotFeasibilityExtraFields[EXTRA_ROWS_LEVEL_KEY].getTotalValue(allFeasibilityPlotsValues, rowId);
                });
            }
            else if (key === 'nbUndergroundLevels') {
                const nbRowsMax = Math.round(Math.max(...Object.values(feasibilityPlots).map((plot) => plot[key])));
                if (nbRowsMax === 0 || isNaN(nbRowsMax)) {
                    return;
                }
                Array.from(Array(nbRowsMax).keys()).forEach((row) => {
                    const rowId = `${EXTRA_ROWS_UNDERGROUND_LEVEL_KEY}-${row}`;
                    defaultValues[rowId] = plotFeasibilityExtraFields[EXTRA_ROWS_UNDERGROUND_LEVEL_KEY].getTotalValue(allFeasibilityPlotsValues, rowId);
                });
            }
            defaultValues[key] = plotFeasibilityFields[key].getTotalValue(allFeasibilityPlotsValues);
        });
        // En fonction de la surface d'emprise au sol autorisé ou
        // de la surface d'emprise au sol calculé on coche la bonne case (base ou calculé)
        // Et aussi si l'emprise au sol autorisé est inférieur à 3000 (sinon on calculera avec allowedFootprintSurface)
        if (defaultValues['allowedFootprintSurface'] >
            defaultValues['computedFootprintSurface'] &&
            defaultValues['allowedFootprintSurface'] < 3000) {
            defaultValues['baseCalculationSelected'] = 'computedFootprintSurface';
        }
        else {
            defaultValues['baseCalculationSelected'] = 'allowedFootprintSurface';
        }
    }
    else {
        defaultValues.constructibleSurface = 0;
    }
    return defaultValues;
};
const formatRound = (number, precision = 0) => {
    if (precision === 0) {
        return Math.round(number);
    }
    else {
        return Math.round(number * 100) / 100;
    }
};
export const getFeasibilityRows = (showFeasibilityDetail = false) => showFeasibilityDetail
    ? Object.keys(plotFeasibilityFields)
    : Object.keys(plotFeasibilityFields).filter((key) => plotFeasibilityFields[key].mainRow);
