import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';
import FilterTitle from 'components/FilterTitle';
import { getTVA } from 'utils/formatText';
const SimpleTextSidebarContent = ({ traductionKey, selectedTown, }) => {
    const { t } = useTranslation();
    return (<Text as="span" textStyle="paragraph">
      <FilterTitle title={t(`sidebar_content.${traductionKey}.title`, {
            TVA: getTVA(selectedTown?.id),
        })}/>
    </Text>);
};
export default SimpleTextSidebarContent;
