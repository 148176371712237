import * as React from 'react';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlTileUrl, getNormalizedCoordinates, } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
export const PLOTS_ZOOM_LIMIT = 14;
const getLegends = () => {
    const legends = {};
    legends.plots = {
        layerKey: filtersKey.PLOTS,
        label: 'plots',
        needsTranslate: true,
        color: '#FFFFFE',
        borderColor: '#000000',
        borderWidth: 1,
        borderOpacity: 1,
    };
    return legends;
};
const usePlotsLayer = () => {
    const selectedTown = useSelectedTown();
    const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
        const normCoord = getNormalizedCoordinates(coord, zoom);
        if (!normCoord) {
            return null;
        }
        return getLayerBoundingTileUrlTileUrl(map, 'Applicatif', 'cadastre_national', zoom, coord, tileWidth, tileHeight);
    };
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.PLOTS],
        refreshParam: selectedTown?.id,
        getLegends,
        tileWidth: 1024,
        tileHeight: 1024,
    })(filtersKey.PLOTS);
};
const PlotsLayer = () => {
    usePlotsLayer();
    return null;
};
const PlotLayerWrapper = ({ computedZoom }) => {
    if (computedZoom < PLOTS_ZOOM_LIMIT) {
        return null;
    }
    return <PlotsLayer />;
};
export default PlotLayerWrapper;
