import * as React from 'react';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Input, Skeleton } from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import SearchInputWrapper from 'components/inputs/SearchInputWrapper';
import { useCloseAllAccordions, useIsAllOpened, useOpenAllAccordions, } from 'hooks/contexts/useFiltersAccordionContext';
import { useUpdateFiltersSearch } from 'hooks/contexts/useFiltersContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
const FiltersSearchInputSkeleton = () => {
    return (<Box>
      <Skeleton variant="rectangle" height="35px"/>
    </Box>);
};
const FiltersSearchInput = ({ isFilterSideBarLoading, isMainLayersLoading, }) => {
    const isAllOpened = useIsAllOpened();
    const openAllAccordions = useOpenAllAccordions();
    const closeAllAccordions = useCloseAllAccordions();
    const { t } = useTranslation();
    const inputSearchRef = useRef(null);
    const updateFiltersSearch = useUpdateFiltersSearch();
    const selectedTown = useSelectedTown();
    useEffect(() => {
        if (inputSearchRef.current) {
            inputSearchRef.current.value = '';
            updateFiltersSearch(null);
        }
    }, [selectedTown]);
    const debouncedSearchChange = useMemo(() => debounce(updateFiltersSearch, 600), []);
    if (isFilterSideBarLoading || isMainLayersLoading) {
        return <FiltersSearchInputSkeleton />;
    }
    return (<SearchInputWrapper>
      <Input ref={inputSearchRef} onChange={(e) => {
            const value = e.target.value;
            const formattedValue = value.trim() !== '' ? value.trim() : null;
            debouncedSearchChange(formattedValue);
            // On ouvre tout si nécessaire, seulement pour le premier caractère (pour éviter d'ouvrir à chaque caractère)
            if (formattedValue && !isAllOpened && formattedValue.length === 1) {
                openAllAccordions();
            }
            if (formattedValue === null && !isAllOpened) {
                closeAllAccordions(true);
            }
        }} type="search" width="full" placeholder={t('sidebar.search_layers.placeholder')} data-cy="filters_search_input"/>
    </SearchInputWrapper>);
};
export default FiltersSearchInput;
