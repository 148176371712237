import * as React from 'react';
import useAnalytics, { ANALYTICS_MAP_STICKY } from 'analytics/useAnalytics';
import { useIsFilterActive, useToggleFilter, } from 'hooks/contexts/useFiltersContext';
import LegendAccordion from './LegendAccordion';
const BasicLegendItem = ({ filterKey, label, }) => {
    const { trackEvent } = useAnalytics();
    const toggleFilter = useToggleFilter();
    const isFilterActive = useIsFilterActive();
    if (!isFilterActive(filterKey)) {
        return null;
    }
    return (<LegendAccordion id={filterKey} legendLabel={label} handleClose={() => {
            trackEvent({
                category: ANALYTICS_MAP_STICKY,
                action: `${label} off légende`,
            });
            toggleFilter(filterKey, false);
        }}/>);
};
export default BasicLegendItem;
