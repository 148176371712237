import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, FormLabel, Select } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_URBANISM_FILTERS, } from 'analytics/useAnalytics';
import { useExtraFilters, useToggleFilter, useUpdateExtraFilter, } from 'hooks/contexts/useFiltersContext';
import { filtersKey, getTimePeriodOptions, LAST_SIX_MONTHS, OLDER_YEAR, } from 'utils/filters';
const NewMarketPricesSidebarContent = ({ item, }) => {
    const { trackEvent } = useAnalytics();
    const extraFilters = useExtraFilters();
    const updateExtraFilter = useUpdateExtraFilter();
    const toggleFilter = useToggleFilter();
    const { t } = useTranslation();
    const selectedOption = extraFilters?.[item[0]]?.timePeriod || '';
    const displayOptions = getTimePeriodOptions().map((option) => {
        return {
            value: option,
            label: option === LAST_SIX_MONTHS
                ? t(`global.select.time_period.last_six_months`)
                : option === OLDER_YEAR
                    ? `${option} ${t('global.select.time_period.and_before')}`
                    : option,
        };
    });
    const handleChange = (e) => {
        toggleFilter(filtersKey.NEW_MARKET_PROGRAMS, true);
        const value = e.target.value;
        updateExtraFilter(`${filtersKey.NEW_MARKET_PRICES}.timePeriod`, value === LAST_SIX_MONTHS ? value : parseInt(value));
        trackEvent({
            category: ANALYTICS_URBANISM_FILTERS,
            action: 'Programmes commercialisés',
        });
    };
    return (<Box width="full">
      <FormControl display="flex" alignItems="center" marginBottom={2}>
        <FormLabel textStyle="small" marginBottom={0} minWidth="80px">
          {t('global.select.time_period.label')}&nbsp;:
        </FormLabel>
        <Select size="sm" placeholder={t('global.select.time_period.placeholder')} onChange={handleChange} value={selectedOption}>
          {displayOptions.map((option) => (<option key={option.value} value={option.value}>
              {option.label}
            </option>))}
        </Select>
      </FormControl>
    </Box>);
};
export default NewMarketPricesSidebarContent;
