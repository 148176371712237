import * as React from 'react';
import { Translation } from 'react-i18next';
import { Text } from '@chakra-ui/react';
import UpdateContactInfoModal from 'components/modals/UpdateContactInfoModal';
export const getColumnHeader = (column) => (<Text align="center">
    <Translation>{(t) => t(`tabs.user_plots.columns.${column}`)}</Translation>
  </Text>);
export const OWNER_COLUMNS = [
    {
        accessorKey: 'comment',
        header: () => getColumnHeader('comment'),
        cell: (info) => info.row.original.plotContactId ? (<UpdateContactInfoModal title={info.getValue() ? info.getValue() : 'Ajouter un commentaire'} plotId={info.row.original.plotId} plotContactId={info.row.original.plotContactId} defaultValues={{
                comment: info.getValue(),
                status: info.row.original.plotContactStatus,
            }}/>) : null,
        filterFn: 'arrIncludesSome',
    },
];
