import * as iots from 'io-ts';
import { get } from 'api/client';
import { useSetSectionHashToUrl } from 'hooks/contexts/useTabsContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import useOpenPlotFromMap from 'hooks/plot/useOpenPlotFromMap';
import { IOLayerItem, SALES_PLOT_TAB } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOOldMarketDvfLayerItem = IOLayerItem;
const fetchOldMarketDvf = (townId) => () => get(iots.array(IOOldMarketDvfLayerItem), `legacy/${townId}/old-market-dvf`);
const useOldMarketDvfLayer = () => {
    const setSectionHashToUrl = useSetSectionHashToUrl();
    const openPlotFromMap = useOpenPlotFromMap();
    createLayersHook({
        fetch: fetchOldMarketDvf,
        onClick: (item) => {
            openPlotFromMap(item);
            setSectionHashToUrl(SALES_PLOT_TAB);
        },
    })(filtersKey.OLD_MARKET_DVF);
};
const OldMarketDvfLayer = () => {
    useOldMarketDvfLayer();
    return null;
};
export default OldMarketDvfLayer;
