import { useEffect, useRef } from 'react';
import * as React from 'react';
import { Box, Stack } from '@chakra-ui/react';
import { useLayout, useMapSize } from 'hooks/contexts/useLayoutContext';
import { useStreetViewMode } from 'hooks/contexts/useMapContext';
import { useMenuItemSelected } from 'hooks/contexts/useMenuContext';
import { useSelectedPlot, useSelectedTab, useTabs, } from 'hooks/contexts/useTabsContext';
import useSyncNavigation from 'hooks/navigation/useSyncNavigation';
import ConfirmCity from './confirm/ConfirmCity';
import MapContainer from './map/MapContainer';
import { menuItems } from './sidebar/utils';
import TabsContainer from './tabs/TabsContainer';
const MainLayout = () => {
    const menuItemSelected = useMenuItemSelected();
    const streetViewMode = useStreetViewMode();
    const layout = useLayout();
    const { mapWidth, mapHeight } = useMapSize();
    const selectedTab = useSelectedTab();
    const tabs = useTabs();
    const syncNavigation = useSyncNavigation();
    const selectedPlot = useSelectedPlot();
    const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        const currentTab = tabs.find((tab) => tab.id === selectedTab);
        if (currentTab?.type !== 'plot' || selectedPlot) {
            syncNavigation(currentTab);
        }
    }, [selectedTab, selectedPlot]);
    return (<Stack id="MainLayout" flex="1" spacing={0} display={menuItemSelected !== menuItems.NEWS ? 'flex' : 'none'} height="100%" flexDir={layout === 'vertical' ? 'row' : 'column'} overflow="hidden" data-cy={`layout_${layout}`}>
      <Box id="MapLayout" height={!tabs?.length || streetViewMode ? '100%' : mapHeight} width={!tabs?.length || streetViewMode ? '100%' : mapWidth}>
        <MapContainer />
      </Box>

      {tabs?.length && <TabsContainer />}
      <ConfirmCity />
    </Stack>);
};
export default React.memo(MainLayout);
