import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Link } from '@chakra-ui/react';
import * as iots from 'io-ts';
import { URL_SIREN } from 'components/links/SirenLink';
import { filtersKey } from 'utils/filters';
const IOBuildingPermitNewMarketCategory = iots.union([
    iots.literal(filtersKey.BUILDING_PERMIT_HOUSING),
    iots.literal(filtersKey.BUILDING_PERMIT_OFFICES),
    iots.literal(filtersKey.BUILDING_PERMIT_PLANNING_PERMIT),
]);
const IOBuildingPermitNewMarketStatus = iots.union([
    iots.literal('allowed_permit'),
    iots.literal('work_started'),
    iots.literal('work_done'),
    iots.literal('canceled_permit'),
]);
const IOBuildingPermitNewMarketOperatingArea = iots.union([
    iots.literal('housing'),
    iots.literal('zac'),
    iots.literal('afu'),
    iots.literal('not_defined'),
]);
const IOBuildingPermitNewMarketProjectType = iots.union([
    iots.literal('new_building'),
    iots.literal('existing_building'),
]);
const IOBuildingPermitNewMarketPublicServiceType = iots.union([
    iots.literal('transportation'),
    iots.literal('education'),
    iots.literal('health'),
    iots.literal('social_work'),
    iots.literal('special_work'),
    iots.literal('culture'),
    iots.literal('not_defined'),
    iots.literal('multiple'),
]);
const IOBuildingPermitNewMarketServicedApartmentsType = iots.union([
    iots.literal('senior'),
    iots.literal('student'),
    iots.literal('tourism'),
    iots.literal('social_tourism'),
    iots.literal('social'),
    iots.literal('disabled'),
    iots.literal('other'),
    iots.literal('mix'),
    iots.literal('not_defined'),
]);
const IOBuildingPermitNewMarketRequesterCategory = iots.union([
    iots.literal('private_individual_sai'),
    iots.literal('private_individual'),
    iots.literal('private_individual_sci'),
    iots.literal('social_landlord_sai'),
    iots.literal('hlm_body'),
    iots.literal('epl'),
    iots.literal('other_body'),
    iots.literal('property_developer_sai'),
    iots.literal('acknowledged_property_developer'),
    iots.literal('sci'),
    iots.literal('other_construction_company'),
    iots.literal('public_administration_sai'),
    iots.literal('state_odac'),
    iots.literal('departments_and_regions'),
    iots.literal('towns_epic_and_towns_syndicates'),
    iots.literal('other_company'),
    iots.literal('not_applicable'),
    iots.literal('not_defined'),
]);
const IOBuildingPermitNewMarketUseType = iots.union([
    iots.literal('not_defined'),
    iots.literal('personal_usage'),
    iots.literal('sale'),
    iots.literal('sale_and_rent'),
    iots.literal('rent'),
]);
export const IOBuildingPermitNewMarketInfo = iots.type({
    parentCategory: IOBuildingPermitNewMarketCategory,
    status: iots.union([IOBuildingPermitNewMarketStatus, iots.null]),
    maxLevel: iots.union([iots.number, iots.null]),
    realGrantDate: iots.union([iots.string, iots.null]),
    workStartedDate: iots.union([iots.string, iots.null]),
    workDoneDate: iots.union([iots.string, iots.null]),
    requesterName: iots.union([iots.string, iots.null]),
    requesterSiret: iots.union([iots.string, iots.null]),
    requesterAddress: iots.union([iots.string, iots.null]),
    requesterCategory: iots.union([
        IOBuildingPermitNewMarketRequesterCategory,
        iots.null,
    ]),
    lotAddress: iots.union([iots.string, iots.null]),
    landRegister: iots.array(iots.type({
        plotId: iots.number,
        lat: iots.number,
        lng: iots.number,
        townId: iots.number,
        cadastreId: iots.string,
    }, 'IOBuildingPermitNewMarketInfoPlot')),
    lotSurface: iots.union([iots.number, iots.null]),
    operatingArea: iots.union([
        IOBuildingPermitNewMarketOperatingArea,
        iots.null,
    ]),
    projectType: iots.union([IOBuildingPermitNewMarketProjectType, iots.null]),
    extension: iots.union([iots.boolean, iots.null]),
    elevation: iots.union([iots.boolean, iots.null]),
    additionalLevel: iots.union([iots.boolean, iots.null]),
    hotelSurface: iots.union([iots.number, iots.null]),
    hotelSurfaceBefore: iots.union([iots.number, iots.null]),
    hotelSurfaceCreatedByUseChange: iots.union([iots.number, iots.null]),
    hotelSurfaceDemolished: iots.union([iots.number, iots.null]),
    hotelSurfaceRemovedByUseChange: iots.union([iots.number, iots.null]),
    housingSurface: iots.union([iots.number, iots.null]),
    housingSurfaceBefore: iots.union([iots.number, iots.null]),
    housingSurfaceCreatedByUseChange: iots.union([iots.number, iots.null]),
    housingSurfaceDemolished: iots.union([iots.number, iots.null]),
    housingSurfaceRemovedByUseChange: iots.union([iots.number, iots.null]),
    officesSurface: iots.union([iots.number, iots.null]),
    officesSurfaceBefore: iots.union([iots.number, iots.null]),
    officesSurfaceCreatedByUseChange: iots.union([iots.number, iots.null]),
    officesSurfaceDemolished: iots.union([iots.number, iots.null]),
    officesSurfaceRemovedByUseChange: iots.union([iots.number, iots.null]),
    storesSurface: iots.union([iots.number, iots.null]),
    storesSurfaceBefore: iots.union([iots.number, iots.null]),
    storesSurfaceCreatedByUseChange: iots.union([iots.number, iots.null]),
    storesSurfaceDemolished: iots.union([iots.number, iots.null]),
    storesSurfaceRemovedByUseChange: iots.union([iots.number, iots.null]),
    craftworkSurface: iots.union([iots.number, iots.null]),
    craftworkSurfaceBefore: iots.union([iots.number, iots.null]),
    craftworkSurfaceCreatedByUseChange: iots.union([iots.number, iots.null]),
    craftworkSurfaceDemolished: iots.union([iots.number, iots.null]),
    craftworkSurfaceRemovedByUseChange: iots.union([iots.number, iots.null]),
    industrySurface: iots.union([iots.number, iots.null]),
    industrySurfaceBefore: iots.union([iots.number, iots.null]),
    industrySurfaceCreatedByUseChange: iots.union([iots.number, iots.null]),
    industrySurfaceDemolished: iots.union([iots.number, iots.null]),
    industrySurfaceRemovedByUseChange: iots.union([iots.number, iots.null]),
    farmingSurface: iots.union([iots.number, iots.null]),
    farmingSurfaceBefore: iots.union([iots.number, iots.null]),
    farmingSurfaceCreatedByUseChange: iots.union([iots.number, iots.null]),
    farmingSurfaceDemolished: iots.union([iots.number, iots.null]),
    farmingSurfaceRemovedByUseChange: iots.union([iots.number, iots.null]),
    storageSurface: iots.union([iots.number, iots.null]),
    storageSurfaceBefore: iots.union([iots.number, iots.null]),
    storageSurfaceCreatedByUseChange: iots.union([iots.number, iots.null]),
    storageSurfaceDemolished: iots.union([iots.number, iots.null]),
    storageSurfaceRemovedByUseChange: iots.union([iots.number, iots.null]),
    publicServiceSurface: iots.union([iots.number, iots.null]),
    publicServiceSurfaceBefore: iots.union([iots.number, iots.null]),
    publicServiceSurfaceCreatedByUseChange: iots.union([iots.number, iots.null]),
    publicServiceSurfaceDemolished: iots.union([iots.number, iots.null]),
    publicServiceSurfaceRemovedByUseChange: iots.union([iots.number, iots.null]),
    publicServiceType: iots.union([
        IOBuildingPermitNewMarketPublicServiceType,
        iots.null,
    ]),
    nbRooms: iots.union([iots.number, iots.null]),
    totalCreatedHousing: iots.union([iots.number, iots.null]),
    totalCreatedCollectiveHousing: iots.union([iots.number, iots.null]),
    totalCreatedIndividualHousing: iots.union([iots.number, iots.null]),
    totalCreatedSocialRentalHousing: iots.union([iots.number, iots.null]),
    totalCreatedSupportiveHousing: iots.union([iots.number, iots.null]),
    totalCreatedPTZHousing: iots.union([iots.number, iots.null]),
    servicedApartmentsType: iots.union([
        IOBuildingPermitNewMarketServicedApartmentsType,
        iots.null,
    ]),
    oneRoomHousing: iots.union([iots.number, iots.null]),
    twoRoomsHousing: iots.union([iots.number, iots.null]),
    threeRoomsHousing: iots.union([iots.number, iots.null]),
    fourRoomsHousing: iots.union([iots.number, iots.null]),
    fiveRoomsHousing: iots.union([iots.number, iots.null]),
    sixRoomsAndMoreHousing: iots.union([iots.number, iots.null]),
    demolishedHousing: iots.union([iots.number, iots.null]),
    useType: iots.union([IOBuildingPermitNewMarketUseType, iots.null]),
    buildingPermitId: iots.string,
    median: iots.union([iots.number, iots.null]),
    propertyDeveloper: iots.union([iots.string, iots.null]),
    date: iots.union([iots.string, iots.null]),
    exitPrice: iots.union([iots.number, iots.null]),
    salePrice: iots.union([iots.number, iots.null]),
    saleDate: iots.union([iots.string, iots.null]),
    computedPropertyTaxes: iots.union([iots.number, iots.null]),
});
const PropertyTaxesForm = ({ info, }) => {
    const { t } = useTranslation();
    const salePrice = info.salePrice?.toString() || '0';
    const median = info.median?.toString() || '0';
    const surface = info.housingSurface?.toString() || '0';
    const exitPrice = info.exitPrice?.toString() || '0';
    const computedPropertyTaxes = info.computedPropertyTaxes?.toString() || '0';
    if (info.parentCategory !== filtersKey.BUILDING_PERMIT_HOUSING) {
        return null;
    }
    return (<>
      <tr>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td colSpan={2}>
          {t('info_window.building_permit_new_market.estimated_financial_statement')}
        </td>
      </tr>
      <tr>
        <td>
          <b>{t('info_window.building_permit_new_market.median')}</b>
        </td>
        <td style={{ textAlign: 'left' }}>
          <input id="median-input" type="text" style={{
            fontWeight: 'bold',
            border: '1px solid gray',
            color: 'black',
        }} className="info-window-field" defaultValue={median}/>
          <label style={{ display: 'inline-block', paddingLeft: '3px' }}>
            <b> € par m²</b>
          </label>
        </td>
      </tr>
      <tr>
        <td>
          {t('info_window.building_permit_new_market.property_developer')}
        </td>
        <td>{info.propertyDeveloper}</td>
      </tr>
      <tr>
        <td>{t('info_window.building_permit_new_market.date')}</td>
        <td>{info.date}</td>
      </tr>
      <tr>
        <td>
          <b>{t('info_window.building_permit_new_market.housing_surface')}</b>
        </td>
        <td style={{ textAlign: 'left' }}>
          <input id="surface-input" type="text" style={{ fontWeight: 'bold' }} className="info-window-field" defaultValue={surface} disabled/>
          <label style={{ display: 'inline-block', paddingLeft: '3px' }}>
            <b>m²</b>
          </label>
        </td>
      </tr>
      <tr>
        <td>
          <b>{t('info_window.building_permit_new_market.exit_price')}</b>
        </td>
        <td style={{ verticalAlign: 'top' }}>
          <input id="exit-price-input" type="text" style={{ fontWeight: 'bold' }} className="info-window-field" defaultValue={exitPrice} disabled/>
          <label style={{ display: 'inline-block', paddingLeft: '3px' }}>
            <b>€</b>
          </label>
        </td>
      </tr>
      <tr>
        <td>
          {t('info_window.building_permit_new_market.price_time_surface')}
        </td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>
          <b>{t('info_window.building_permit_new_market.sale_price')}</b>
        </td>
        <td>
          <input id="sale-price-input" type="text" style={{
            fontWeight: 'bold',
            border: '1px solid gray',
            color: 'black',
        }} className="info-window-field" defaultValue={salePrice}/>
          <label style={{ display: 'inline-block', paddingLeft: '3px' }}>
            <b>€</b>
          </label>
        </td>
      </tr>
      <tr>
        <td>{t('info_window.building_permit_new_market.sale_date')}</td>
        <td>{info.saleDate}</td>
      </tr>
      <tr>
        <td>
          <b>Charge foncière</b>
        </td>
        <td>
          <input id="computed-property-taxes-input" type="text" style={{ fontWeight: 'bold' }} className="info-window-field" defaultValue={computedPropertyTaxes} disabled/>
          <label style={{ display: 'inline-block' }}>
            <b>%</b>
          </label>
        </td>
      </tr>
      <tr>
        <td>
          {t('info_window.building_permit_new_market.sale_price_on_exit_price')}
        </td>
        <td>&nbsp;</td>
      </tr>
    </>);
};
const BuildingPermitNewMarketInfoWindow = ({ info }) => {
    const { t } = useTranslation();
    if (!info) {
        return null;
    }
    return (<Box maxHeight="400px">
      <table>
        {info.status && (<tr>
            <td>{t('info_window.building_permit_new_market.status.title')}</td>
            <td>
              {t(`info_window.building_permit_new_market.status.${info.status}`)}
            </td>
          </tr>)}
        {info.parentCategory === filtersKey.BUILDING_PERMIT_OFFICES && (<>
            {info.hotelSurface && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.hotel_surface')}
                </td>
                <td style={{ textAlign: 'left' }}>{info.hotelSurface}m²</td>
              </tr>)}
            {info.officesSurface && (<tr>
                <td>
                  <b>
                    {t('info_window.building_permit_new_market.offices_surface')}
                  </b>
                </td>
                <td style={{ textAlign: 'left' }}>
                  <b>{info.officesSurface} m²</b>
                </td>
              </tr>)}
            {info.storesSurface && (<tr>
                <td>
                  <b>
                    {t('info_window.building_permit_new_market.stores_surface')}
                  </b>
                </td>
                <td style={{ textAlign: 'left' }}>
                  <b>{info.storesSurface} m²</b>
                </td>
              </tr>)}
            {info.craftworkSurface && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.craftwork_surface')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.craftworkSurface} m²
                </td>
              </tr>)}
            {info.industrySurface && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.industry_surface')}
                </td>
                <td style={{ textAlign: 'left' }}>{info.industrySurface} m²</td>
              </tr>)}
            {info.farmingSurface && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.farming_surface')}
                </td>
                <td style={{ textAlign: 'left' }}>{info.farmingSurface} m²</td>
              </tr>)}
            {info.storageSurface && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.storage_surface')}
                </td>
                <td style={{ textAlign: 'left' }}>{info.storageSurface} m²</td>
              </tr>)}
            {info.publicServiceSurface && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.public_service_surface')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.publicServiceSurface} m²
                </td>
              </tr>)}
          </>)}
        {info.parentCategory === filtersKey.BUILDING_PERMIT_HOUSING && (<>
            {info.totalCreatedHousing && (<tr>
                <td>
                  <b>
                    {t('info_window.building_permit_new_market.total_created_housing')}
                  </b>
                </td>
                <td>
                  <b style={{ textAlign: 'left' }}>
                    {info.totalCreatedHousing}
                  </b>
                </td>
              </tr>)}
            {info.totalCreatedCollectiveHousing && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.total_created_collective_housing')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.totalCreatedCollectiveHousing}
                </td>
              </tr>)}
            {info.totalCreatedIndividualHousing && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.total_created_individual_housing')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.totalCreatedIndividualHousing}
                </td>
              </tr>)}
            {info.totalCreatedSocialRentalHousing && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.total_created_social_rental_housing')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.totalCreatedSocialRentalHousing}
                </td>
              </tr>)}
            {info.totalCreatedSupportiveHousing && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.total_created_supportive_housing')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.totalCreatedSupportiveHousing}
                </td>
              </tr>)}
            {info.totalCreatedPTZHousing && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.total_created_ptz_housing')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.totalCreatedPTZHousing}
                </td>
              </tr>)}
            {info.housingSurface && (<tr>
                <td>
                  <b>
                    {t('info_window.building_permit_new_market.housing_surface')}
                  </b>
                </td>
                <td>
                  <b style={{ textAlign: 'left' }}>{info.housingSurface} m²</b>
                </td>
              </tr>)}
          </>)}
        {info.parentCategory !== filtersKey.BUILDING_PERMIT_PLANNING_PERMIT &&
            info.maxLevel && (<tr>
              <td>{t('info_window.building_permit_new_market.max_level')}</td>
              <td style={{ textAlign: 'left' }}>{info.maxLevel}</td>
            </tr>)}
        {info.parentCategory === filtersKey.BUILDING_PERMIT_HOUSING &&
            info.servicedApartmentsType && (<tr>
              <td>
                {t('info_window.building_permit_new_market.serviced_apartments_type.title')}
              </td>
              <td>
                {t(`info_window.building_permit_new_market.serviced_apartments_type.${info.servicedApartmentsType}`)}
              </td>
            </tr>)}
        {info.realGrantDate && (<tr>
            <td>
              {t(`info_window.building_permit_new_market.${info.status === 'canceled_permit'
                ? 'cancellation_date'
                : 'initial_grant_date'}`)}
            </td>
            <td>{info.realGrantDate}</td>
          </tr>)}
        {info.workStartedDate && (<tr>
            <td>
              {t('info_window.building_permit_new_market.work_started_date')}
            </td>
            <td>{info.workStartedDate}</td>
          </tr>)}
        {info.workDoneDate && (<tr>
            <td>
              {t('info_window.building_permit_new_market.work_done_date')}
            </td>
            <td>{info.workDoneDate}</td>
          </tr>)}
        {info.requesterName && (<tr>
            <td>
              <b>
                {t('info_window.building_permit_new_market.requester_name')}
              </b>
            </td>
            <td>
              <b>{info.requesterName}</b>
            </td>
          </tr>)}
        {info.parentCategory === filtersKey.BUILDING_PERMIT_HOUSING &&
            info.requesterCategory && (<tr>
              <td>
                {t('info_window.building_permit_new_market.requester_category.title')}
              </td>
              <td>
                {t(`info_window.building_permit_new_market.requester_category.${info.requesterCategory}`)}
              </td>
            </tr>)}
        {info.requesterSiret && (<tr>
            <td>
              {t('info_window.building_permit_new_market.requester_siret')}
            </td>
            <td>
              <Link href={URL_SIREN + info.requesterSiret.substring(0, 9)} target="_blank" rel="noopener noreferrer">
                {info.requesterSiret}
              </Link>
            </td>
          </tr>)}
        {info.requesterAddress && (<tr>
            <td>
              {t('info_window.building_permit_new_market.requester_address')}
            </td>
            <td>{info.requesterAddress}</td>
          </tr>)}
        {info.landRegister && (<tr>
            <td>{t('info_window.building_permit_new_market.land_register')}</td>
            <td>
              {info.landRegister.map((plot) => {
                return (<div key={plot.plotId}>
                    <a rel="noopener noreferrer" target="_blank" href={`/parcelle/${plot.townId}/${plot.lat}/${plot.lng}/${plot.plotId}`}>
                      {plot.cadastreId}
                    </a>
                  </div>);
            })}
            </td>
          </tr>)}
        {info.lotAddress && (<tr>
            <td>{t('info_window.building_permit_new_market.lot_address')}</td>
            <td>{info.lotAddress}</td>
          </tr>)}
        {info.lotSurface && (<tr>
            <td>{t('info_window.building_permit_new_market.lot_surface')}</td>
            <td style={{ textAlign: 'left' }}>{info.lotSurface} m²</td>
          </tr>)}
        {info.operatingArea && (<tr>
            <td>
              {t('info_window.building_permit_new_market.operating_area.title')}
            </td>
            <td>
              {t(`info_window.building_permit_new_market.operating_area.${info.operatingArea}`)}
            </td>
          </tr>)}
        {info.parentCategory !== filtersKey.BUILDING_PERMIT_PLANNING_PERMIT && (<>
            {info.projectType && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.project_type.title')}
                </td>
                <td>
                  {t(`info_window.building_permit_new_market.project_type.${info.projectType}`)}
                </td>
              </tr>)}
            {info.extension && (<tr>
                <td>{t('info_window.building_permit_new_market.extension')}</td>
                <td style={{ textAlign: 'left' }}>{info.extension}</td>
              </tr>)}
            {info.elevation && (<tr>
                <td>{t('info_window.building_permit_new_market.elevation')}</td>
                <td style={{ textAlign: 'left' }}>{info.elevation}</td>
              </tr>)}
            {info.additionalLevel && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.additional_level')}
                </td>
                <td style={{ textAlign: 'left' }}>{info.additionalLevel}</td>
              </tr>)}
          </>)}
        {info.parentCategory === filtersKey.BUILDING_PERMIT_OFFICES && (<>
            {info.hotelSurfaceBefore && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.hotel_surface_before')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.hotelSurfaceBefore} m²
                </td>
              </tr>)}
            {info.hotelSurfaceCreatedByUseChange && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.hotel_surface_created_by_use_change')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.hotelSurfaceCreatedByUseChange}m²
                </td>
              </tr>)}
            {info.hotelSurfaceDemolished && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.hotel_surface_demolished')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.hotelSurfaceDemolished} m²
                </td>
              </tr>)}
            {info.hotelSurfaceRemovedByUseChange && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.hotel_surface_removed_by_use_change')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.hotelSurfaceRemovedByUseChange} m²
                </td>
              </tr>)}
            {info.officesSurfaceBefore && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.offices_surface_before')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.officesSurfaceBefore} m²
                </td>
              </tr>)}
            {info.officesSurfaceCreatedByUseChange && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.offices_surface_created_by_use_change')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.officesSurfaceCreatedByUseChange} m²
                </td>
              </tr>)}
            {info.officesSurfaceDemolished && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.offices_surface_demolished')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.officesSurfaceDemolished} m²
                </td>
              </tr>)}
            {info.officesSurfaceRemovedByUseChange && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.offices_surface_removed_by_use_change')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.officesSurfaceRemovedByUseChange} m²
                </td>
              </tr>)}
            {info.storesSurfaceBefore && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.stores_surface_before')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.storesSurfaceBefore} m²
                </td>
              </tr>)}
            {info.storesSurfaceCreatedByUseChange && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.stores_surface_created_by_use_change')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.storesSurfaceCreatedByUseChange} m²
                </td>
              </tr>)}
            {info.storesSurfaceDemolished && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.stores_surface_demolished')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.storesSurfaceDemolished} m²
                </td>
              </tr>)}
            {info.storesSurfaceRemovedByUseChange && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.stores_surface_removed_by_use_change')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.storesSurfaceRemovedByUseChange} m²
                </td>
              </tr>)}
            {info.craftworkSurfaceBefore && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.craftwork_surface_before')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.craftworkSurfaceBefore} m²
                </td>
              </tr>)}
            {info.craftworkSurfaceCreatedByUseChange && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.craftwork_surface_created_by_use_change')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.craftworkSurfaceCreatedByUseChange} m²
                </td>
              </tr>)}
            {info.craftworkSurfaceDemolished && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.craftwork_surface_demolished')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.craftworkSurfaceDemolished} m²
                </td>
              </tr>)}
            {info.craftworkSurfaceRemovedByUseChange && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.craftwork_surface_removed_by_use_change')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.craftworkSurfaceRemovedByUseChange} m²
                </td>
              </tr>)}
            {info.industrySurfaceBefore && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.industry_surface_before')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.industrySurfaceBefore} m²
                </td>
              </tr>)}
            {info.industrySurfaceCreatedByUseChange && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.industry_surface_created_by_use_change')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.industrySurfaceCreatedByUseChange} m²
                </td>
              </tr>)}
            {info.industrySurfaceDemolished && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.industry_surface_demolished')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.industrySurfaceDemolished} m²
                </td>
              </tr>)}
            {info.industrySurfaceRemovedByUseChange && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.industry_surface_removed_by_use_change')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.industrySurfaceRemovedByUseChange} m²
                </td>
              </tr>)}
            {info.farmingSurfaceBefore && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.farming_surface_before')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.farmingSurfaceBefore} m²
                </td>
              </tr>)}
            {info.farmingSurfaceCreatedByUseChange && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.farming_surface_created_by_use_change')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.farmingSurfaceCreatedByUseChange} m²
                </td>
              </tr>)}
            {info.farmingSurfaceDemolished && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.farming_surface_demolished')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.farmingSurfaceDemolished} m²
                </td>
              </tr>)}
            {info.farmingSurfaceRemovedByUseChange && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.farming_surface_removed_by_use_change')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.farmingSurfaceRemovedByUseChange} m²
                </td>
              </tr>)}
            {info.storageSurfaceBefore && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.storage_surface_before')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.storageSurfaceBefore} m²
                </td>
              </tr>)}
            {info.storageSurfaceCreatedByUseChange && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.storage_surface_created_by_use_change')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.storageSurfaceCreatedByUseChange} m²
                </td>
              </tr>)}
            {info.storageSurfaceDemolished && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.storage_surface_demolished')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.storageSurfaceDemolished} m²
                </td>
              </tr>)}
            {info.storesSurfaceRemovedByUseChange && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.storage_surface_removed_by_use_change')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.storageSurfaceRemovedByUseChange} m²
                </td>
              </tr>)}
            {info.publicServiceSurfaceBefore && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.public_service_surface_before')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.publicServiceSurfaceBefore} m²
                </td>
              </tr>)}
            {info.publicServiceSurfaceCreatedByUseChange && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.public_service_surface_created_by_use_change')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.publicServiceSurfaceCreatedByUseChange} m²
                </td>
              </tr>)}
            {info.publicServiceSurfaceDemolished && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.public_service_surface_demolished')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.publicServiceSurfaceDemolished} m²
                </td>
              </tr>)}
            {info.publicServiceSurfaceRemovedByUseChange && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.public_service_surface_removed_by_use_change')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.publicServiceSurfaceRemovedByUseChange} m²
                </td>
              </tr>)}
            {info.publicServiceType && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.public_service_type.title')}
                </td>
                <td>
                  {t(`info_window.building_permit_new_market.public_service_type.${info.publicServiceType}`)}
                </td>
              </tr>)}
            {info.nbRooms && (<tr>
                <td>{t('info_window.building_permit_new_market.nb_rooms')}</td>
                <td style={{ textAlign: 'left' }}>{info.nbRooms}</td>
              </tr>)}
          </>)}
        {info.parentCategory === filtersKey.BUILDING_PERMIT_HOUSING && (<>
            {info.oneRoomHousing && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.one_room_housing')}
                </td>
                <td>{info.oneRoomHousing}</td>
              </tr>)}
            {info.twoRoomsHousing && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.two_rooms_housing')}
                </td>
                <td>{info.twoRoomsHousing}</td>
              </tr>)}
            {info.threeRoomsHousing && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.three_rooms_housing')}
                </td>
                <td>{info.threeRoomsHousing}</td>
              </tr>)}
            {info.fourRoomsHousing && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.four_rooms_housing')}
                </td>
                <td>{info.fourRoomsHousing}</td>
              </tr>)}
            {info.fiveRoomsHousing && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.five_rooms_housing')}
                </td>
                <td>{info.fiveRoomsHousing}</td>
              </tr>)}
            {info.sixRoomsAndMoreHousing && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.six_rooms_and_more_housing')}
                </td>
                <td>{info.sixRoomsAndMoreHousing}</td>
              </tr>)}
            {info.demolishedHousing && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.demolished_housing')}
                </td>
                <td>{info.demolishedHousing}</td>
              </tr>)}
            {info.housingSurfaceBefore && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.housing_surface_before')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.housingSurfaceBefore}m²
                </td>
              </tr>)}
            {info.housingSurfaceCreatedByUseChange && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.housing_surface_created_by_use_change')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.housingSurfaceCreatedByUseChange}m²
                </td>
              </tr>)}
            {info.housingSurfaceDemolished && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.housing_surface_demolished')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.housingSurfaceDemolished}m²
                </td>
              </tr>)}
            {info.housingSurfaceRemovedByUseChange && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.housing_surface_removed_by_use_change')}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {info.housingSurfaceRemovedByUseChange}m²
                </td>
              </tr>)}
            {info.useType && (<tr>
                <td>
                  {t('info_window.building_permit_new_market.use_type.title')}
                </td>
                <td>
                  {t(`info_window.building_permit_new_market.use_type.${info.useType}`)}
                </td>
              </tr>)}
          </>)}
        <tr>
          <td>
            {t('info_window.building_permit_new_market.building_permit_id')}
          </td>
          <td>{info.buildingPermitId}</td>
        </tr>
        {info.parentCategory === filtersKey.BUILDING_PERMIT_HOUSING && (<PropertyTaxesForm info={info}/>)}
      </table>
    </Box>);
};
export default BuildingPermitNewMarketInfoWindow;
