import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IOEmpty } from 'types/api';
const IOSaleDetail = iots.type({
    type: iots.union([iots.string, iots.null]),
    builtArea: iots.number,
    floorArea: iots.number,
}, 'IOSaleDetail');
const IOPlotSalesInfo = iots.type({
    id: iots.string,
    date: iots.string,
    nature: iots.string,
    realEstateValue: iots.string,
    plotId: iots.string,
    cadastreId: iots.string,
    saleDetail: iots.array(IOSaleDetail),
});
const IOPlotSales = iots.record(iots.string, IOPlotSalesInfo);
const fetchPlotSales = (townshipId, plotId) => () => get(iots.union([IOEmpty, IOPlotSales]), `legacy/${townshipId}/plot-sales/${plotId}`);
export const getQueryKey = (townshipId, plotId) => [
    'plu-sales',
    townshipId,
    plotId,
];
const usePlotSales = (townshipId, plotId) => {
    return useQuery({
        queryKey: getQueryKey(townshipId, plotId),
        queryFn: fetchPlotSales(townshipId, plotId),
        enabled: !!townshipId && !!plotId,
    });
};
export default usePlotSales;
