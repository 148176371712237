import * as React from 'react';
import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CATEGORY_FIND_LAND, } from 'analytics/useAnalytics';
import PlotsSearchFormFields from 'components/sidebar/PlotSearchSideBar/form/PlotsSearchFormFields';
import { SEARCH_MODE } from 'context/PlotSearchContext';
import { useIsLoadingLayersTree } from 'hooks/contexts/useLayersTreeContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useCurrentSearchTownId, useIsLoading, useSetPlotSearchParams, } from 'hooks/contexts/usePlotSearchContext';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { PLOTS_SEARCH_DEFAUT_FORM_VALUES, PLOTS_SEARCH_FIELDS_TRANSLATIONS, } from 'utils/plotsSearch';
const PlotsSearchForm = () => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const isLoadingLayersTree = useIsLoadingLayersTree();
    const selectedTown = useSelectedTown();
    const { hasSearchField } = useSiteConfiguration();
    const setPlotSearchParams = useSetPlotSearchParams();
    const currentSearchTownId = useCurrentSearchTownId();
    const isLoadingSearchData = useIsLoading();
    const { handleSubmit } = useFormContext();
    const getFieldTranslation = (key) => {
        return (t(PLOTS_SEARCH_FIELDS_TRANSLATIONS[key], key) +
            (key?.includes('Min') ? ' Min' : key?.includes('Max') ? ' Max' : ''));
    };
    const trackPlotSearch = (data) => {
        const fieldsTouched = [];
        Object.entries(data).map(([key, value]) => {
            if ((Array.isArray(value) && value?.length > 0) ||
                (!Array.isArray(value) &&
                    PLOTS_SEARCH_DEFAUT_FORM_VALUES[key] !== value)) {
                fieldsTouched.push(key);
                trackEvent({
                    category: ANALYTICS_CATEGORY_FIND_LAND,
                    action: getFieldTranslation(key),
                });
            }
        });
        if (fieldsTouched?.length) {
            trackEvent({
                category: ANALYTICS_CATEGORY_FIND_LAND,
                action: fieldsTouched
                    ?.map((key) => getFieldTranslation(key))
                    .join(' / '),
            });
        }
    };
    const onSubmit = (data) => {
        currentSearchTownId.current = selectedTown?.id;
        setPlotSearchParams({
            mode: SEARCH_MODE,
            values: data,
        });
        trackPlotSearch(data);
    };
    return (<Box>
      <form onSubmit={handleSubmit(onSubmit)} id="search-plots-form">
        <PlotsSearchFormFields isLoadingLayersTree={isLoadingLayersTree} isLoadingSearchData={isLoadingSearchData} hasSearchField={hasSearchField}/>
      </form>
    </Box>);
};
export default memo(PlotsSearchForm);
