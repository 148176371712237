import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import qs from 'qs';
const PlotButton = ({ lat, lng, townId, children, onOpenPlot, analyticOrigin, analyticDetail, plotId, id, ...buttonProps }) => {
    return (<Button as={Link} to={{
            pathname: `/parcelle/${townId}/${lat}/${lng}/${plotId}`,
            search: qs.stringify({
                analyticOrigin,
                analyticDetail,
            }),
        }} onClick={onOpenPlot} {...buttonProps} data-cy={id + '_plot_button'} style={{ whiteSpace: 'nowrap' }}>
      {children}
    </Button>);
};
export default PlotButton;
