import * as React from 'react';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading, HStack, Spinner, Text, VStack } from '@chakra-ui/react';
import useUserStatisticsCRM from 'hooks/crm/useUserStatisticsCRM';
import { ACTIVITY_TYPE_OPTIONS, PLOT_STATUS_OPTIONS } from 'utils/constants';
import { isAvailable } from '../TeamStatisticsCRMPanel';
import UserStatisticsStatusItem from './UserStatisticsStatusItem';
const UserStatisticsActivitiesPanel = ({ filters, }) => {
    const { t } = useTranslation();
    const [dataFormatted, setDataFormatted] = useState({
        status: {},
        percent: {},
    });
    const [dataCourier, setDataCourier] = useState({
        nbSend: 0,
        percent: 0,
    });
    const { statsActivities, isLoadingStatsActivities, statsCouriers, isLoadingStatsCouriers, } = useUserStatisticsCRM();
    useEffect(() => {
        if (statsActivities) {
            const data = { status: {}, percent: {} };
            PLOT_STATUS_OPTIONS.map((option) => {
                data.status[option.value] = 0;
                data.percent[option.value] = 0;
            });
            const nbActivities = statsActivities.length;
            statsActivities.forEach((activity) => {
                const dateStart = new Date(activity.dateStart);
                const dateEnd = new Date(activity.dateEnd);
                if (!isAvailable({ filters, dateStart, dateEnd })) {
                    return;
                }
                data.status[activity.type] += 1;
                const percent = (data.status[activity.type] / nbActivities) * 100;
                data.percent[activity.type] = percent.toFixed(2);
            });
            setDataFormatted(data);
        }
    }, [statsActivities, filters]);
    useEffect(() => {
        if (statsCouriers) {
            const data = { nbSend: 0, percent: 0 };
            const nbCouriers = statsCouriers.length;
            statsCouriers.forEach((courier) => {
                const date = new Date(courier.sendAt);
                if (filters.dateStart && date.getTime() < filters.dateStart.getTime()) {
                    return;
                }
                if (filters.dateEnd && date.getTime() > filters.dateEnd.getTime()) {
                    return;
                }
                if (courier.send) {
                    data.nbSend += 1;
                }
                data.percent = Math.round((data.nbSend / nbCouriers) * 100);
            });
            setDataCourier(data);
        }
    }, [statsCouriers, filters]);
    return (<Box flex={1}>
      <Heading as="h3" variant="h3" marginBottom={2}>
        {t('tabs.my_statistics_crm.my_actions')}
      </Heading>
      <VStack spacing={1} alignItems="flex-start" position="relative">
        <HStack spacing={2} width="full">
          <HStack spacing={1} width="230px">
            <Text fontSize="14px">
              {t('tabs.my_statistics_crm.courier_send')}
            </Text>
          </HStack>
          <HStack spacing={1} width="calc(100% - 220px)">
            <Box display="flex" alignItems="center" justifyContent="center" backgroundColor="#EF3E22" height="18px" width={dataCourier.percent + '%'} transition="width 1s ease"/>
            {dataCourier.percent !== 0 && (<Text color="colorMode.font900" fontSize="13px" fontWeight="bold">
                {dataCourier.nbSend}
              </Text>)}
          </HStack>
        </HStack>
        {ACTIVITY_TYPE_OPTIONS.map((option) => {
            return (<UserStatisticsStatusItem key={option.value} option={option} data={dataFormatted}/>);
        })}
        {isLoadingStatsActivities && isLoadingStatsCouriers && (<Box display="flex" alignItems="center" justifyContent="center" position="absolute" top={0} left={0} width="100%" height="100%" backgroundColor="rgba(251, 250, 249, 0.6)">
            <Spinner />
          </Box>)}
      </VStack>
    </Box>);
};
export default memo(UserStatisticsActivitiesPanel);
