import * as React from 'react';
import { useTranslation } from 'react-i18next';
import findKey from 'lodash/findKey';
import { POI_FILTERS } from 'components/layers/POILayers';
import { LEGEND_FEASIBILITY_BUILDING } from 'context/LegendContext';
import { useFilters, useSetFilters, useToggleFilter, } from 'hooks/contexts/useFiltersContext';
import { filtersKey } from 'utils/filters';
import BasicLegendItem from './BasicLegendItem';
import LegendAccordion from './LegendAccordion';
import LegendLayers from './LegendLayers';
import PLUGPULegendItem from './PLUGPULegendItem';
import PLULegendItem from './PLULegendItem';
const LegendItemWrapper = ({ layerKey, legends, prefix, legendName, }) => {
    const { t } = useTranslation();
    const toggleFilter = useToggleFilter();
    const filters = useFilters();
    const setFilters = useSetFilters();
    const radioName = findKey(filters, (value) => value === layerKey);
    const handleClose = (e) => {
        e.preventDefault();
        if (radioName) {
            toggleFilter(radioName, null);
            return;
        }
        // Si le filtre est un POI
        if (POI_FILTERS[layerKey]) {
            const newFilters = { ...filters };
            Object.keys(POI_FILTERS[layerKey]).forEach((POILayerKey) => {
                if (newFilters[POILayerKey]) {
                    delete newFilters[POILayerKey];
                }
            });
            setFilters(newFilters);
            return;
        }
        toggleFilter(layerKey, false);
    };
    if (layerKey === filtersKey.PLU) {
        return <PLULegendItem />;
    }
    if (layerKey === filtersKey.PLU_ADJACENT) {
        return (<BasicLegendItem filterKey={filtersKey.PLU_ADJACENT} label={t('legends.plu_adjacent')}/>);
    }
    if (layerKey === filtersKey.TOWNS) {
        return (<BasicLegendItem filterKey={filtersKey.TOWNS} label={t('legends.town')}/>);
    }
    if (layerKey === filtersKey.EPCI) {
        return (<BasicLegendItem filterKey={filtersKey.EPCI} label={t('legends.epci')}/>);
    }
    if (layerKey === filtersKey.TOWN_AND_NEIGHBORHOODS) {
        return (<BasicLegendItem filterKey={filtersKey.TOWN_AND_NEIGHBORHOODS} label={t('legends.town_and_neightborhoods')}/>);
    }
    if (layerKey === filtersKey.PLOTS) {
        return (<BasicLegendItem filterKey={filtersKey.PLOTS} label={t('legends.plots')}/>);
    }
    if (layerKey === filtersKey.PLU_GPU) {
        return <PLUGPULegendItem />;
    }
    return (<LegendAccordion id={layerKey} legendLabel={`${prefix ? prefix + ' - ' : ''}  ${legendName}`} handleClose={layerKey !== LEGEND_FEASIBILITY_BUILDING ? handleClose : null} canToggle>
      <LegendLayers legends={legends}/>
    </LegendAccordion>);
};
export default LegendItemWrapper;
