import { useToast } from '@chakra-ui/react';
import { uniqueId } from 'lodash';
const useCustomToast = () => {
    const toast = useToast();
    const getSuccessToast = (props) => {
        if (!toast.isActive(`success_toast_${props.id}`)) {
            return toast({
                status: 'success',
                isClosable: true,
                position: 'top',
                ...props,
                id: props.id
                    ? `success_toast_${props.id}`
                    : `${uniqueId()}success_toast`,
            });
        }
    };
    const getErrorToast = (props) => {
        if (!toast.isActive(`error_toast_${props.id}`)) {
            return toast({
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'bottom',
                ...props,
                id: props.id ? `error_toast_${props.id}` : `${uniqueId()}error_toast`,
            });
        }
    };
    const getInfoToast = (props) => {
        if (!toast.isActive(`info_toast_${props.id}`)) {
            return toast({
                status: 'info',
                duration: 5000,
                isClosable: true,
                position: 'bottom',
                ...props,
                id: props.id ? `info_toast_${props.id}` : `${uniqueId()}info_toast`,
            });
        }
    };
    return { getSuccessToast, getErrorToast, getInfoToast };
};
export default useCustomToast;
