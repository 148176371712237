import { useEffect } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Stack, } from '@chakra-ui/react';
const CODE_PATTERN = /^\d{6}$/;
const TwoFactorForm = ({ confirm, fieldLabel, isError = false, }) => {
    const { t } = useTranslation();
    const { register, handleSubmit, formState: { isSubmitting, errors }, setError, } = useForm();
    const setCodeError = (message) => {
        setError('code', {
            type: 'server',
            message: t(`security.two_factor.code.${message || 'two_factor_failed'}`),
        });
    };
    useEffect(() => {
        if (isError) {
            setCodeError('two_factor_failed');
        }
    }, [isError]);
    const submit = async (values) => {
        try {
            await confirm(values.code);
        }
        catch (e) {
            setCodeError(e?.error);
        }
    };
    return (<form onSubmit={handleSubmit(submit)}>
      <Stack paddingBottom={2} width="300px" spacing={2}>
        <FormControl isInvalid={!!errors?.code}>
          <FormLabel>{fieldLabel}</FormLabel>
          <Input {...register('code', {
        required: t('security.two_factor.code.required'),
        pattern: {
            value: CODE_PATTERN,
            message: t('security.two_factor.code.required'),
        },
    })} placeholder={t('security.two_factor.code.placeholder')}/>
          <FormErrorMessage>{errors?.code?.message}</FormErrorMessage>
        </FormControl>
        <Button variant="primary" isDisabled={isSubmitting} type="submit">
          {t('security.two_factor.confirm')}
        </Button>
      </Stack>
    </form>);
};
export default TwoFactorForm;
