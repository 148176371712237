import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, } from '@chakra-ui/react';
function ConfirmDialog({ children, onConfirm, onCancel, header, confirmLabel, }) {
    const { t } = useTranslation();
    const cancelRef = React.useRef();
    const [open, setOpen] = React.useState(true);
    const onClose = () => {
        onCancel();
        setOpen(false);
    };
    const onValidate = () => {
        onConfirm();
        setOpen(false);
    };
    return (<AlertDialog isOpen={open} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          {header && (<AlertDialogHeader fontSize="lg" fontWeight="bold">
              {header}
            </AlertDialogHeader>)}

          <AlertDialogBody>{children}</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCancel}>
              {t('global.actions.cancel')}
            </Button>
            {confirmLabel && (<Button variant="primary" onClick={onValidate} ml={3}>
                {confirmLabel}
              </Button>)}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>);
}
export default ConfirmDialog;
