import * as React from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@chakra-ui/react';
import TeamPlotModal from 'components/modals/TeamPlotModal';
import { tableFiltersKey } from 'context/FiltersContext';
import { useTableFilters } from 'hooks/contexts/useFiltersContext';
import useTeamPlots from 'hooks/crm/useTeamPlots';
import createLayersHook from 'hooks/layers/createLayersHook';
import { VECTOR_TYPES } from 'types/types';
import { PLOT_STATUS_VALUES, PLOT_STATUS_VALUES_WITH_NONE, } from 'utils/constants';
import { filtersKey } from 'utils/filters';
const useTeamPlotsLayer = (handleClick) => {
    const { t } = useTranslation();
    const { teamPlots } = useTeamPlots();
    const tableFilters = useTableFilters();
    const userEmailFilter = tableFilters[tableFiltersKey.PLOT_USER_EMAIL];
    const statusFilter = tableFilters[tableFiltersKey.PLOT_STATUS];
    const plotLayerItems = useMemo(() => {
        if (!teamPlots) {
            return [];
        }
        const items = {};
        teamPlots?.forEach((plot) => {
            if (userEmailFilter && !userEmailFilter.includes(plot.userEmail)) {
                return;
            }
            if (statusFilter && !statusFilter.includes(plot.plotStatus)) {
                return;
            }
            items[plot.plotId] = {
                id: plot.plotId,
                layerKey: filtersKey.TEAM_PLOTS,
                type: VECTOR_TYPES.polygon,
                swkt: plot.swkt,
                opacity: 0.5,
                color: PLOT_STATUS_VALUES_WITH_NONE[plot.plotStatus].color,
            };
            if (PLOT_STATUS_VALUES[plot.plotStatus]) {
                items[plot.plotId] = {
                    ...items[plot.plotId],
                    legend: t(PLOT_STATUS_VALUES[plot.plotStatus].label),
                    legendOrder: PLOT_STATUS_VALUES[plot.plotStatus].legendOrder,
                };
            }
        });
        return Object.values(items);
    }, [teamPlots, userEmailFilter, statusFilter]);
    createLayersHook({
        providedData: plotLayerItems,
        onClick: (item) => {
            handleClick(teamPlots.find((plot) => plot.plotId === item.id));
        },
    })(filtersKey.TEAM_PLOTS);
};
const TeamPlotsLayer = () => {
    const [modalData, setModalData] = useState({});
    const { onOpen, ...rest } = useDisclosure();
    const handleClick = (item) => {
        setModalData(item);
        onOpen();
    };
    useTeamPlotsLayer(handleClick);
    return (<TeamPlotModal plot={{
            lat: modalData.plotLat,
            lng: modalData.plotLng,
            townshipId: modalData.townId,
            cadastreId: modalData.cadastreId,
            email: modalData.userEmail,
            address: modalData.address,
            plotId: modalData.plotId,
        }} triggerItem={() => <></>} onOpen={onOpen} {...rest}/>);
};
export default TeamPlotsLayer;
