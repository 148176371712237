import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
import LogoKelFoncier from 'images/logo_kelFoncier.png';
import KelfoncierWhite from 'images/logo_kelFoncier_white.png';
import { IOSite } from 'types/api';
export const SITE_FONCIERFACILE = '128';
export function getSiteLogoWhite(site) {
    return site?.id === SITE_FONCIERFACILE
        ? require('../images/sites/' + site.logo)
        : KelfoncierWhite;
}
export function getSiteLogo(site) {
    return site?.id === SITE_FONCIERFACILE
        ? require('../images/sites/' + site.logo)
        : LogoKelFoncier;
}
const QUERY_KEY = ['site'];
const fetchSite = () => () => get(IOSite, `public/site`);
const useSite = () => {
    const { data: site, isInitialLoading: isLoading } = useQuery({
        queryKey: QUERY_KEY,
        queryFn: fetchSite(),
    });
    return {
        site,
        isLoading,
    };
};
export default useSite;
