import * as iots from 'io-ts';
import { escapeRegExp } from 'lodash';
import getNested from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import { IOFilterItemWithLegend, IOTranslatedFilterItem } from 'types/api';
import { CONTENT_KEY, ENDPOINT_KEY, LAYERS_KEY } from 'utils/formatLayersNames';
import { getTVA } from './formatText';
import { extractTitle, removeAccent } from './search';
// ⚠️ This object contains both filters AND categories
export const filtersKey = {
    HIGHLIGHTED_PLOTS: 'highlighted_plots',
    SELECTED_PLOTS: 'selected_plots',
    USER_PLOTS: 'user_plots',
    CONTACT_PLOTS: 'contact_plots',
    TEAM_PLOTS: 'team_plots',
    TEAM_PROJECTS: 'team_projects',
    SECONDARY_PLOTS: 'secondary_plots',
    FEASIBILITY_PLOTS: 'feasibility_plots',
    PROJECT_PLOTS: 'project_plots',
    USER_PROJECTS: 'user_projects',
    SELECTED_PLOTS_BUILDINGS: 'selected_plots_buildings',
    COMMERCIAL_LINE: 'commercial_line',
    DEVELOPMENT_AREA: 'development_area',
    LAYOUT_ORIENTATION: 'layout_orientation',
    OVERALL_DEVELOPMENT_PLAN: 'overall_development_plan',
    PARTNERSHIP_URBAN_PROJECT: 'partnership_urban_project',
    PATRIMONY: 'patrimony',
    RESERVED_AREA: 'reserved_area',
    RIGHT_OF_FIRST_REFUSAL: 'right_of_first_refusal',
    SOCIAL_MIXING: 'social_mixing',
    TOWN: 'town',
    TOWNS: 'towns',
    VIEW_ANGLES_PROTECTION: 'view_angles_protection',
    ZNIEFF_TYPE1: 'znieff_type1',
    STAY_OF_PROCEEDINGS: 'stay_of_proceedings',
    LIMITED_SIZE_AND_ACCOMMODATION_CAPACITY_AREA: 'limited_size_and_accommodation_capacity_area',
    MISC_LIMITATIONS: 'misc_limitations',
    GREEN_SPACE_COEFF: 'green_space_coeff',
    MAXIMUM_HEIGHT: 'maximum_height',
    MAXIMUM_FOOTPRINT: 'maximum_footprint',
    PROTECTED_WOODLAND: 'protected_woodland',
    SETBACK: 'setback',
    PRESERVED_LANDSCAPE: 'preserved_landscape',
    PRINCIPLE_OF_ACCESS: 'principle_of_access',
    RECONSTRUCTION_OR_DEMOLITION_PROVISION: 'reconstruction_or_demolition_provision',
    VOLUMETRY: 'volumetry',
    TRAM_FOOTPRINT: 'tram_footprint',
    PARKING: 'parking',
    SPECIFIC_SETTING: 'specific_setting',
    MISC_EASEMENTS: 'misc_easements',
    EASEMENT: 'easement',
    FLOOD_ZONE: 'flood_zone',
    ECOLOGICAL_CONTINUUM: 'ecological_continuum',
    QUARRY_OR_WASTE: 'quarry_or_waste',
    ABF_NO_BUILDING_ZONE: 'abf_no_building_zone',
    ABF_STRONGLY_REGULATED_AREA: 'abf_strongly_regulated_area',
    ABF_ADVICE_REQUIRED: 'abf_advice_required',
    ABF_ADVICE_REQUIRED_IF_VISIBLE: 'abf_advice_required_if_visible',
    ABF_REGIONAL_PREFECT_ADVICE_REQUIRED: 'abf_regional_prefect_advice_required',
    ABF_HISTORICAL_BUILDING: 'abf_historical_building',
    NO_BUILDING_AREA_ENVIRONMENT: 'no_building_area_environment',
    LIMITED_BUILDING_AREA_ENVIRONMENT: 'limited_building_area_environment',
    WETLAND_AREA: 'wetland_area',
    WETLAND_AREA_POTENTIALLY: 'wetland_area_potentially',
    ZNIEFF_TYPE2: 'znieff_type2',
    RISING_WATER_TABLE_AREA: 'rising_water_table_area',
    BIRDS_ZICO: 'birds_zico',
    SENSITIVE_NATURAL_SPACE: 'sensitive_natural_space',
    COASTLINE_CONSERVATOIRE: 'coastline_conservatoire',
    REGIONAL_NATURAL_SPACE_CONSERVATOIRE: 'regional_natural_space_conservatoire',
    NO_BUILDING_AREA_ENVIRONMENT_OTHER: 'no_building_area_environment_other',
    BIOLOGICAL_RESERVE: 'biological_reserve',
    HUNTING_RESERVE: 'hunting_reserve',
    NATIONAL_RESERVE: 'national_reserve',
    REGIONAL_RESERVE: 'regional_reserve',
    NATURA_2000: 'natura_2000',
    NATURA_2000_SIC: 'natura_2000_sic',
    NATURA_2000_ZPS: 'natura_2000_zps',
    BIOTOP_PROTECTION: 'biotop_protection',
    BIOSPHERE_RESERVE: 'biosphere_reserve',
    NATIONAL_PARK: 'national_park',
    MARIN_PARK: 'marin_park',
    REGIONAL_PARK: 'regional_park',
    NO_BUILDING_ZONE_RESOURCES_AND_FACILITIES: 'no_building_zone_resources_and_facilities',
    LIMITED_BUILDING_ZONE_RESOURCES_AND_FACILITIES: 'limited_building_zone_resources_and_facilities',
    LOW_ALTITUDE_SETBA: 'low_altitude_setba',
    LOW_ALTITUDE_VOLTAC: 'low_altitude_voltac',
    LOW_ALTITUDE_RTBA: 'low_altitude_rtba',
    LOW_ALTITUDE_ASBA: 'low_altitude_asba',
    AERONAUTIC_ZONE_CRT: 'aeronautic_zone_crt', // Les zones de contrôle des aéroports (CTR)
    AERONAUTIC_ZONE_P: 'aeronautic_zone_p', // Les zones de survol interdit
    AERONAUTIC_ZONE_ZMT: 'aeronautic_zone_zmt', // Les Zones de Mise à Terre (ZMT)
    AERONAUTIC_SERVITUDE: 'aeronautic_servitude', // Servitudes aéronautiques
    ELECTRICAL_NETWORKS_CAPARESEAU: 'electrical_networks_capareseau',
    ELECTRICAL_NETWORKS_HIGH_VOLTAGE_LINE: 'electrical_networks_high_voltage_line',
    ELECTRICAL_NETWORKS_UNDERGROUND_LINE: 'electrical_networks_underground_line',
    ELECTRICAL_NETWORKS_ELECTRICITY_PYLON: 'electrical_networks_electricity_pylon',
    ELECTRICAL_NETWORKS_BT_AERIAL: 'reseau-bt',
    ELECTRICAL_NETWORKS_BT_UNDERGROUND: 'reseau-souterrain-bt',
    ELECTRICAL_NETWORKS_MEDIUM_HTA_AERIAL: 'reseau-hta',
    ELECTRICAL_NETWORKS_MEDIUM_HTA_UNDERGROUND: 'reseau-souterrain-hta',
    ELECTRICAL_NETWORKS_PYLON_HTA_BT: 'position-geographique-des-poteaux-hta-et-bt',
    ELECTRICAL_NETWORKS_DATA_LINE_AND_POST: 'donnees-relatives-aux-lignes-et-aux-postes',
    ELECTRICAL_NETWORKS_POST_HTA_BT: 'poste-electrique',
    ELECTRICAL_NETWORKS_POST_SOURCE_HTA_BT: 'poste-source',
    RAILWAY_NETWORKS_FRANCE_RAILWAY_NETWORK: 'railway_networks_france_railway_network',
    RAILWAY_NETWORKS_IDF_RAILWAY_NETWORK: 'railway_networks_IDF_railway_network',
    HAZMAT_TRANSPORT_PIPELINES: 'hazmat_transport_pipelines',
    PPR_FLOODING: 'ppr_flooding',
    PPR_NATURAL: 'ppr_natural',
    PPR_FIRE_HYDRANTS: 'ppr_fire_hydrants',
    PPR_FLOODING_NO_BUILDING_ZONE: 'ppr_flooding_no_building_zone',
    PPR_FLOODING_LIMITED_BUILDING_ZONE: 'ppr_flooding_limited_building_zone',
    PPR_FLOODING_LOW_CONTINGENCY_BUILDING_ZONE: 'ppr_flooding_low_contingency_building_zone',
    PPR_FLOODING_OTHER_CONTINGENCIES: 'ppr_flooding_other_contingencies',
    RAIN_ZONE: 'rain_zone',
    PPR_LANDSLIDE: 'ppr_landslide',
    PPR_EARTHQUAKE_HAZARD: 'ppr_earthquake_hazard',
    PPR_GROUND_MOVEMENTS: 'ppr_ground_movements',
    PPR_TECHNOLOGICAL: 'ppr_technological',
    ANRU_EXTENDS: 'anru_extends',
    ANRU: 'anru',
    SENSITIVE_URBAN_AREAS: 'sensitive_urban_areas',
    ORT: 'ort',
    EXEMPTED_NEIGHBORHOODS: 'exempted_neighborhoods',
    NPNRU: 'npnru',
    CITY_PROGRAMS: 'city_programs',
    CITIES_OF_TOMORROW: 'cities_of_tomorrow',
    HEART_OF_TOWN: 'heart_of_town',
    PARKING_SPACE_EXEMPTION: 'parking_space_exemption',
    PARKING_SPACE_EXEMPTION_LAYER_CURRENT: 'parking_space_exemption_layer_current',
    PINEL: 'pinel',
    PINEL_BRETAGNE: 'pinel_bretagne',
    PARKING_SPACE_EXEMPTION_LAYER_FUTURE: 'parking_space_exemption_layer_future',
    PPR_ICPE: 'ppr_icpe',
    PADDUC_NO_BUILDING_ZONE: 'padduc_no_building_zone',
    PADDUC_LIMITED_BUILDING_ZONE: 'padduc_limited_building_zone',
    PADDUC_VARIOUS_REQUIREMENTS: 'padduc_various_requirements',
    SCOT: 'scot',
    SRADDET: 'sraddet',
    PCAET: 'pcaet',
    URBAN_SCOT: 'urban_developement',
    COMMERCIAL_SCOT: 'commercial_developement',
    ECONOMICAL_SCOT: 'economical_developement',
    REGIONAL_PLAN_LOGISTIC_FRAME: 'regional_plan_logistic_frame',
    REGIONAL_PLAN_WATER_AREA: 'regional_plan_water_area',
    REGIONAL_PLAN_CONTINUITIES: 'regional_plan_continuities',
    REGIONAL_PLAN_WOODLAND_AREA: 'regional_plan_woodland_area',
    REGIONAL_PLAN_URBAN_AREA: 'regional_plan_urban_area',
    REGIONAL_PLAN_GREEN_AREA: 'regional_plan_green_area',
    REGIONAL_PLAN_URBAN_FRONT: 'regional_plan_urban_front',
    REGIONAL_PLAN_TRANSPORT_INFRASTRUCTURE: 'regional_plan_transport_infrastructure',
    REGIONAL_PLAN_NEW_URBAN_AREA: 'regional_plan_new_urban_area',
    REGIONAL_PLAN_POTENTIAL_LIMIT: 'regional_plan_potential_limit',
    ROAD_PROJECT_CROSSING: 'road_project_crossing',
    ROAD_PROJECT_MULTIMODALE: 'road_project_multimodale',
    ROAD_PROJECT_HIGHWAY: 'road_project_highway',
    TERRITORIAL_DEVELOPMENT_AGREEMENT: 'territorial_development_agreement',
    SHRINKAGE_SWELLING_OF_CLAYS: 'shrinkage_swelling_of_clays',
    STONE_QUARRY: 'stone_quarry',
    ACTIVE_EXPLOITATIONS: 'active_exploitations',
    INACTIVE_EXPLOITATIONS: 'inactive_exploitations',
    UNDERGROUND_CAVITIES: 'underground_cavities',
    QUARRIES_AND_CLOSED_OPERATIONS: 'quarries_and_closed_operations',
    TERMITES: 'termites',
    CONTAMINATED_SITES_AND_SOILS: 'contaminated_sites_and_soils',
    POTENTIALLY_CONTAMINATED_SITES_AND_SOILS: 'potentially_contaminated_sites_and_soils',
    OIN: 'oin',
    REAL_ESTATE_TAXES: 'real_estate_taxes',
    INCREASED_REAL_ESTATE_TAXES: 'increased_real_estate_taxes',
    PREEMPTION_AND_EPF: 'preemption_and_epf',
    PREEMPTION: 'preemption',
    RADIOACTIVE_GAS: 'radioactive_gas',
    URBAN_DEVELOPMENT_PROJECTS: 'urban_development_projects',
    ZAC: 'zac',
    ZAE: 'zae',
    LANDSCAPING_PROJECTS: 'landscaping_projects',
    ARCHEOLOGICAL_AREA: 'archeological_area',
    PRESUMED_ARCHEOLOGICAL_AREA: 'presumed_archeological_area',
    SENSITIVE_ARCHEOLOGICAL_AREA: 'sensitive_archeological_area',
    ROAD_NOISE: 'road_noise',
    RAILWAY_NOISE: 'railway_noise',
    HEATING_NETWORK: 'heating_network',
    NEW_MARKET_PRICES: 'new_market_prices', // category
    NEW_MARKET_PROGRAMS: 'new_market_programs',
    BUILDING_PERMIT_NEW_MARKET: 'building_permit_new_market',
    INCLINE_10: 'incline_10',
    INCLINE_30: 'incline_30',
    AVERAGE_SOLAR_IRRADIATION: 'average_solar_irradiation',
    MRAE: 'mrae',
    DREAL: 'dreal',
    PHOTOVOLTAIC_PRONE_AREA_AIRPORTS: 'photovoltaic_prone_area_airports',
    PHOTOVOLTAIC_PRONE_AREA_PORTS: 'photovoltaic_prone_area_ports',
    PHOTOVOLTAIC_PRONE_AREA_STATIONS: 'photovoltaic_prone_area_stations',
    PHOTOVOLTAIC_PRONE_AREA_WETLAND: 'photovoltaic_prone_area_wetland',
    PHOTOVOLTAIC_PRONE_AREA_MILITARY_GROUND: 'photovoltaic_prone_area_military_ground',
    ELECTRICAL_NETWORKS_SOURCE_POSTS: 'photovoltaic_prone_area_posts',
    PHOTOVOLTAIC_NOT_PRONE_AREA_ABANDONED_MOTORWAYS: 'photovoltaic_not_prone_area_abandoned_motorways',
    PHOTOVOLTAIC_NOT_PRONE_AREA_PARKING_SPACE_500: 'photovoltaic_not_prone_area_parking_space_500',
    PHOTOVOLTAIC_NOT_PRONE_LAND_UNIT_AREA_PARKING_SPACE_500: 'photovoltaic_not_prone_land_unit_parking_space_500',
    PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_LAST: 'photovoltaic_not_prone_area_rpg_last',
    PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2022: 'photovoltaic_not_prone_area_rpg_2022',
    PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2021: 'photovoltaic_not_prone_area_rpg_2021',
    PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2020: 'photovoltaic_not_prone_area_rpg_2020',
    PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2019: 'photovoltaic_not_prone_area_rpg_2019',
    PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2018: 'photovoltaic_not_prone_area_rpg_2018',
    PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2017: 'photovoltaic_not_prone_area_rpg_2017',
    PHOTOVOLTAIC_NOT_PRONE_AREA_RPG: 'photovoltaic_not_prone_area_rpg',
    PHOTOVOLTAIC_NOT_PRONE_AREA_AOC_WINE: 'photovoltaic_not_prone_area_aoc_wine',
    PHOTOVOLTAIC_NOT_PRONE_AREA_PRAIRIE: 'photovoltaic_not_prone_area_prairie',
    DRINKING_WATER_CAPTURE: 'drinking_water_capture',
    PUBLIC_FOREST: 'public_forest',
    WIND_FARM: 'wind_farm',
    WIND_TOWER: 'wind_tower',
    WIND_POTENTIAL: 'wind_potential',
    WIND_POTENTIAL_140: 'wind_potential_140',
    WIND_POTENTIAL_160: 'wind_potential_160',
    WIND_CONSTRAINT_ROAD: 'wind_constraint_road',
    WIND_CONSTRAINT_INCLINE: 'wind_constraint_incline',
    WIND_UNESCO_NATURAL: 'wind_unesco_natural',
    WIND_LARGE_FRENCH_SITE: 'wind_large_french_site',
    WIND_ZAER_EXCLUSION: 'wind_zaer_exclusion',
    WIND_CONSTRAINT_RAILWAY: 'wind_constraint_railway',
    WIND_CONSTRAINT_HOUSING: 'wind_constraint_housing',
    WIND_BIRD_AVIFAUNA: 'wind_bird_avifauna',
    WIND_BIRD_BAT: 'wind_bird_bat',
    WIND_MRAE: 'wind_mrae',
    TELECOM: 'telecom',
    OPTICAL_FIBER: 'optical_fiber',
    OPTICAL_FIBER_CENTER: 'optical_fiber_center',
    CELL_TOWER: 'cell_tower',
    OLD_MARKET_DVF: 'old_market_dvf',
    OLD_MARKET_BUILDABLE_LOTS_IDF: 'old_market_buildable_lots_idf',
    BUILDING_PERMIT_HOUSING: 'building_permit_housing',
    BUILDING_PERMIT_OFFICES: 'building_permit_offices',
    BUILDING_PERMIT_PLANNING_PERMIT: 'building_permit_planning_permit',
    BUILDING_PERMIT_HOUSING_ALLOWED_PERMITS: 'building_permit_housing_allowed_permits',
    BUILDING_PERMIT_HOUSING_WORK_STARTED: 'building_permit_housing_work_started',
    BUILDING_PERMIT_HOUSING_WORK_DONE: 'building_permit_housing_work_done',
    BUILDING_PERMIT_HOUSING_CANCELED_PERMITS: 'building_permit_housing_canceled_permits',
    BUILDING_PERMIT_OFFICES_ALLOWED_PERMITS: 'building_permit_offices_allowed_permits',
    BUILDING_PERMIT_OFFICES_WORK_STARTED: 'building_permit_offices_work_started',
    BUILDING_PERMIT_OFFICES_WORK_DONE: 'building_permit_offices_work_done',
    BUILDING_PERMIT_OFFICES_CANCELED_PERMITS: 'building_permit_offices_canceled_permits',
    BUILDING_PERMIT_PLANNING_PERMITS_ALLOWED_PERMITS: 'building_permit_planning_permits_allowed_permits',
    BUILDING_PERMIT_PLANNING_PERMITS_WORK_STARTED: 'building_permit_planning_permits_work_started',
    BUILDING_PERMIT_PLANNING_PERMITS_WORK_DONE: 'building_permit_planning_permits_work_done',
    BUILDING_PERMIT_PLANNING_PERMITS_CANCELED_PERMITS: 'building_permit_planning_permits_canceled_permits',
    OFFICE_BUILDINGS_WITHOUT_EMPLOYEE: 'office_buildings_without_employee',
    OFFICE_BUILDINGS_ONE_TO_TEN_EMPLOYEES: 'office_buildings_one_to_ten_employees',
    OFFICE_BUILDINGS_TEN_TO_FIFTY_EMPLOYEES: 'office_buildings_ten_to_fifty_employees',
    OFFICE_BUILDINGS_FIFTY_TO_ONE_HUNDRED_EMPLOYEES: 'office_buildings_fifty_to_one_hundred_employees',
    OFFICE_BUILDINGS_ONE_HUNDRED_TO_TWO_HUNDRED_AND_FIFTY_EMPLOYEES: 'office_buildings_one_hundred_to_two_hundred_and_fifty_employees',
    OFFICE_BUILDINGS_TWO_HUNDRED_AND_FIFTY_TO_ONE_THOUSAND_EMPLOYEES: 'office_buildings_two_hundred_and_fifty_to_one_thousand_employees',
    OFFICE_BUILDINGS_ONE_THOUSAND_TO_FIVE_THOUSAND_EMPLOYEES: 'office_buildings_one_thousand_to_five_thousand_employees',
    OFFICE_BUILDINGS_MORE_THAN_FIVE_THOUSAND_EMPLOYEES: 'office_buildings_more_than_five_thousand_employees',
    OFFICE_BUILDINGS_WORKFORCE_NOT_DISCLOSED: 'office_buildings_workforce_not_disclosed',
    FIBER_DEPLOYMENT_2020: 'fiber_deployment_2020',
    OFFICE_BUILDINGS: 'office_buildings',
    OFFICE_BUILDINGS_MAIN: 'office_buildings_main',
    COMMERCIAL_LINEAR: 'commercial_linear',
    URBAN_INTENSITY_PLACES: 'urban_intensity_places',
    WAREHOUSES_AND_DENSE_LOGISTICS_AREAS: 'warehouses_and_dense_logistics_areas',
    SENIOR_AND_STUDENTS_RESIDENCES: 'senior_and_students_residences',
    STUDENT_RESIDENCES: 'student_residences',
    SENIOR_RESIDENCES: 'senior_residences',
    POI_TRANSPORT: 'poi_transport',
    POI_TRANSPORT_BUS: 'poi_transport_bus', // Arrêt bus
    POI_TRANSPORT_STATION: 'poi_transport_station', // Gare ferroviaire
    POI_TRANSPORT_SUBWAY: 'poi_transport_subway', // Métro
    POI_TRANSPORT_FERRY: 'poi_transport_ferry', // Terminal de ferry
    POI_TRANSPORT_TRAMWAY: 'poi_transport_tramway', // Tramway
    POI_TRANSPORT_PARKING: 'poi_transport_parking', // Parking
    POI_TRANSPORT_BICYCLE_PARKING: 'poi_transport_bicycle_parking', // Parking à vélo
    POI_TRANSPORT_TAXI: 'poi_transport_taxi', // Station de taxi
    POI_TRANSPORT_GAS_STATION: 'poi_transport_gas_station', // Station service
    POI_TRANSPORT_BIKE_LOCATION: 'poi_transport_bike_location', // Location de vélo
    POI_TRANSPORT_VELIB: 'poi_transport_velib', // Vélib'
    POI_TRANSPORT_AIRPORT: 'poi_transport_airport', // Aéroport
    POI_TRANSPORT_RER: 'poi_transport_rer', // Rer
    POI_COMMERCE_AND_SERVICES: 'poi_commerce_and_services',
    POI_COMMERCE_AND_SERVICES_BAR: 'poi_commerce_and_services_bar',
    POI_COMMERCE_AND_SERVICES_BUTCHER_SHOP: 'poi_commerce_and_services_butcher_shop', // boucherie
    POI_COMMERCE_AND_SERVICES_BAKERY: 'poi_commerce_and_services_bakery', // boulangerie
    POI_COMMERCE_AND_SERVICES_NEIGHBORHOOD_MARKET: 'poi_commerce_and_services_neighborhood_market', // marché de quartier
    POI_COMMERCE_AND_SERVICES_RESTAURANT: 'poi_commerce_and_services_restaurant',
    POI_COMMERCE_AND_SERVICES_POST_OFFICE: 'poi_commerce_and_services_post_office',
    POI_COMMERCE_AND_SERVICES_SUPERMARKET: 'poi_commerce_and_services_supermarket',
    POI_COMMERCE_AND_SERVICES_MINIMARKET: 'poi_commerce_and_services_minimarket',
    POI_COMMERCE_AND_SERVICES_BANK: 'poi_commerce_and_services_bank',
    POI_COMMERCE_AND_SERVICES_TOBACCO_PRESS: 'poi_commerce_and_services_tobacco_press',
    POI_COMMERCE_AND_SERVICES_OTHER_BUSINESS: 'poi_commerce_and_services_other_business', // autre commerce
    POI_HOBBIES: 'poi_hobbies',
    POI_HOBBIES_CINEMA: 'poi_hobbies_cinema',
    POI_HOBBIES_FIELD_AND_SPORT_HALL: 'poi_hobbies_field_and_sport_hall',
    POI_HOBBIES_PARC: 'poi_hobbies_parc',
    POI_HOBBIES_CASINO: 'poi_hobbies_casino',
    POI_HOBBIES_BEACH: 'poi_hobbies_beach',
    POI_HOBBIES_BODYBUILDING_ROOM: 'poi_hobbies_bodybuilding_room',
    POI_EDUCATION: 'poi_education',
    POI_EDUCATION_COLLEGE: 'poi_education_college',
    POI_EDUCATION_KINDERGARTEN: 'poi_education_kindergarten', // Ecole maternelle
    POI_EDUCATION_PRIMARY_SCHOOL: 'poi_education_primary_school',
    POI_EDUCATION_HIGHER_EDUCATION: 'poi_education_higher_education', // Enseignement supérieur
    POI_EDUCATION_HIGH_SCHOOL: 'poi_education_high_school', // Lycée
    POI_CULTURE: 'poi_culture',
    POI_CULTURE_LIBRARY: 'poi_culture_library',
    POI_CULTURE_MONUMENT: 'poi_culture_monument',
    POI_CULTURE_THEATER: 'poi_culture_theater',
    POI_CULTURE_OTHER: 'poi_culture_other',
    POI_HEALTHCARE: 'poi_healthcare',
    POI_HEALTHCARE_HOSPITAL: 'poi_healthcare_hospital',
    POI_HEALTHCARE_PHARMACY: 'poi_healthcare_pharmacy',
    POI_HEALTHCARE_GENERAL_DOCTOR: 'poi_healthcare_general_doctor',
    POI_HEALTHCARE_SPECIALIST_DOCTOR: 'poi_healthcare_specialist_doctor',
    POI_EARLY_CHILDHOOD: 'poi_early_childhood',
    POI_EARLY_CHILDHOOD_TOY_LIBRARY: 'poi_early_toy_library', // ludothèque
    POI_EARLY_CHILDHOOD_CRECHE: 'poi_early_creche',
    POI_TOURISM: 'poi_tourism',
    POI_TOURISM_HOSTEL: 'poi_tourism_hostel',
    POI_TOURISM_CAMPING: 'poi_tourism_camping',
    POI_TOURISM_RESIDENCE_VILLAGE_PARK: 'poi_tourism_residence_village_park',
    POI_PUBLIC_SERVICE: 'poi_public_service',
    POI_PUBLIC_SERVICE_CITY_HALL: 'poi_public_service_city_hall',
    POI_PUBLIC_SERVICE_CAF: 'poi_public_service_caf',
    POI_PUBLIC_SERVICE_POLICE: 'poi_public_service_police',
    POI_PUBLIC_SERVICE_CPAM: 'poi_public_service_cpam',
    POI_PUBLIC_SERVICE_GENDARMERIE: 'poi_public_service_gendarmerie',
    POI_PUBLIC_SERVICE_JOB_CENTER: 'poi_public_service_job_center', // Pole emploi
    POI_PUBLIC_SERVICE_BUSINESS_TAX_DEPARTMENT: 'poi_public_service_business_tax_department', // Service des impôts des entreprises
    POI_PUBLIC_SERVICE_PERSONAL_TAX_DEPARTMENT: 'poi_public_service_personal_tax_department', // Service des impôts des particuliers
    POI_PUBLIC_SERVICE_PREFECTURE: 'poi_public_service_prefecture',
    POI_PUBLIC_SERVICE_GREFFE: 'poi_public_service_greffe',
    POI_RISKS: 'poi_risks',
    POI_RISKS_RELAY_ANTENNAS: 'poi_risks_relay_antennas',
    POI_RISKS_POLLUTING_ESTABLISHMENT: 'poi_risks_polluting_establishment',
    POI_RISKS_POLLUTED_SITE: 'poi_risks_polluted_site',
    POI_RISKS_POTENTIALLY_POLLUTED_SITE: 'poi_risks_potentially_polluted_site',
    POI_SPORTS_ACTIVITIES: 'poi_sports_activities',
    POI_SPORTS_ACTIVITIES_GOLF: 'poi_sports_activities_golf',
    POI_SPORTS_ACTIVITIES_VTT: 'poi_sports_activities_vtt',
    POI_SPORTS_ACTIVITIES_HIKING: 'poi_sports_activities_hiking', // Randonnée
    POI_SPORTS_ACTIVITIES_HORSE_RIDING: 'poi_sports_activities_horse_riding', // Equitation
    POI_SPORTS_ACTIVITIES_ESCALATION: 'poi_sports_activities_escalation',
    POI_SPORTS_ACTIVITIES_BILLIARD_BOWLING_PAINTBALL: 'poi_sports_activities_billiard_bowling_paintball',
    POI_SPORTS_ACTIVITIES_MECHANICAL_SPORTS: 'poi_sports_activities_mechanical_sports',
    POI_SPORTS_ACTIVITIES_AIR_SPORTS: 'poi_sports_activities_air_sports',
    POI_SPORTS_ACTIVITIES_FISHING: 'poi_sports_activities_fishing',
    POI_SPORTS_ACTIVITIES_SKI: 'poi_sports_activities_ski',
    POI_SPORTS_ACTIVITIES_SWIMMING_AND_WATER_SPORTS: 'poi_sports_activities_swimming_and_water_sports',
    POI_SPORTS_ACTIVITIES_TENNIS: 'poi_sports_activities_tennis',
    POI_SPORTS_ACTIVITIES_LEISURE_CENTER: 'poi_sports_activities_leisure_center', // base de loisir
    POI_RECREATIONAL_ACTIVITIES: 'poi_recreational_activities',
    POI_RECREATIONAL_ACTIVITIES_AMUSEMENT_PARK: 'poi_recreational_activities_amusement_park', // Parc d'attraction
    POI_RECREATIONAL_ACTIVITIES_AQUARIUM: 'poi_recreational_activities_aquarium',
    POI_RECREATIONAL_ACTIVITIES_ZOO: 'poi_recreational_activities_zoo',
    POI_RECREATIONAL_ACTIVITIES_MALL: 'poi_recreational_activities_mall', // centre commercial
    POI_TOURISTIC_PLACES: 'poi_touristic_places',
    POI_TOURISTIC_PLACES_UNAVOIDABLE: 'poi_touristic_places_unavoidable', // Incontournable
    POI_TOURISTIC_PLACES_MUSEUM: 'poi_touristic_places_museum',
    POI_FESTIVAL: 'poi_festival',
    POI_PLACES_OF_INTEREST: 'poi_places_of_interest',
    POI_PLACES_OF_INTEREST_BEACH: 'poi_places_of_interest_beach',
    POI_PLACES_OF_INTEREST_SWIMMING_LAKE_RIVER: 'poi_places_of_interest_swimming_lake_river', // Baignade en lac et rivière
    POI_PLACES_OF_INTEREST_SEA: 'poi_places_of_interest_sea',
    POI_PLACES_OF_INTEREST_MOUNTAIN: 'poi_places_of_interest_mountain',
    POI_PLACES_OF_INTEREST_CAMPAIGN: 'poi_places_of_interest_campaign',
    POI_PLACES_OF_INTEREST_CITY: 'poi_places_of_interest_city',
    POI_PLACES_OF_INTEREST_DOWNTOWN: 'poi_places_of_interest_downtown',
    POI_PLACES_OF_INTEREST_LAKE: 'poi_places_of_interest_lake',
    POI_PLACES_OF_INTEREST_SKI_STATION: 'poi_places_of_interest_ski_station',
    POI_PLACES_OF_INTEREST_AGGLOMERATION: 'poi_places_of_interest_agglomeration',
    PLU: 'plu',
    PLU_ADDITIONAL: 'plu_additional',
    PLU_ADJACENT: 'plu_adjacent',
    PLU_LABELS: 'plu_labels',
    PLU_GPU: 'plu_gpu',
    PLOTS: 'plots',
    PLOTS_SEARCH: 'plots_search',
    PLOTS_OWNERS: 'plots_owners',
    CADASTRE: 'cadastre',
    OLD_CADASTRE: 'old_cadastre',
    THEMATIC_MAPS: 'thematic_maps',
    THEMATIC_MAPS_NONE: 'thematic_maps_none',
    THEMATIC_MAPS_REAL_ESTATE: 'thematic_maps_real_estate',
    THEMATIC_MAP_REAL_ESTATE_POTENTIAL: 'thematic_map_real_estate_potential',
    THEMATIC_MAP_HOUSING_TAX: 'thematic_map_housing_tax',
    THEMATIC_MAP_PROPERTY_TAX: 'thematic_map_property_tax',
    THEMATIC_MAP_HLM_SOCIAL_HOUSING: 'thematic_map_hlm_social_housing',
    THEMATIC_MAP_OWNERS_PART: 'thematic_map_owners_part',
    THEMATIC_MAP_VACATION_HOME: 'thematic_map_vacation_home',
    THEMATIC_MAP_HOUSING_AGE: 'thematic_map_housing_age',
    THEMATIC_MAP_VACANT_HOUSING: 'thematic_map_vacant_housing',
    THEMATIC_MAP_NO_CENTRAL_HEATING: 'thematic_map_no_central_heating',
    THEMATIC_MAP_SMALL_SURFACES: 'thematic_map_small_surfaces',
    THEMATIC_MAP_BUSINESS_PROPERTY_TAX: 'thematic_map_business_property_tax',
    THEMATIC_MAP_HOUSING_DENSITY: 'thematic_map_housing_density',
    THEMATIC_MAP_HOUSING_TYPE: 'thematic_map_housing_type',
    THEMATIC_MAP_UNBUILT_LAND_PROPERTY_TAX: 'thematic_map_unbuilt_land_property_tax',
    THEMATIC_MAP_WASTE_COLLECTION_TAX: 'thematic_map_waste_collection_tax',
    THEMATIC_MAP_MONTHLY_INCOME: 'thematic_map_monthly_income',
    THEMATIC_MAP_UNEMPLOYMENT: 'thematic_map_unemployment',
    THEMATIC_MAP_POPULATION_TYPE: 'thematic_map_population_type',
    THEMATIC_MAP_AVERAGE_AGE: 'thematic_map_average_age',
    THEMATIC_MAP_GENTRIFICATION: 'thematic_map_gentrification',
    THEMATIC_MAP_INFLUX_OF_FAMILIES: 'thematic_map_influx_of_families',
    THEMATIC_MAP_POPULATION_DENSITY: 'thematic_map_population_density',
    THEMATIC_MAP_POPULATION_GROWTH: 'thematic_map_population_growth',
    THEMATIC_MAP_CHILDREN_AND_TEENAGERS: 'thematic_map_children_and_teenagers',
    THEMATIC_MAP_TRANSPORTATION_TO_WORK: 'thematic_map_transportation_to_work',
    THEMATIC_MAP_BACCALAUREATE_HOLDERS: 'thematic_map_baccalaureate_holders',
    THEMATIC_MAP_PLACE_OF_WORK: 'thematic_map_place_of_work',
    THEMATIC_MAP_FERTILITY_RATE: 'thematic_map_fertility_rate',
    THEMATIC_MAP_ANNUITIES_PENSIONS: 'thematic_map_annuities_pensions',
    THEMATIC_MAP_TAXABLE_HOUSEHOLDS: 'thematic_map_taxable_households',
    THEMATIC_MAP_SENIORS: 'thematic_map_seniors',
    THEMATIC_MAP_EXECUTIVES: 'thematic_map_executives',
    THEMATIC_MAP_EMPLOYEES_AND_WORKERS: 'thematic_map_employees_and_workers',
    THEMATIC_MAP_RETIREES: 'thematic_map_retirees',
    THEMATIC_MAP_HOUSEHOLD_SIZE: 'thematic_map_household_size',
    THEMATIC_MAP_RESIDENTS_UNDER_25: 'thematic_map_residents_under_25',
    THEMATIC_MAP_RESIDENTS_FROM_25_TO_55: 'thematic_map_residents_from_25_to_55',
    THEMATIC_MAP_RESIDENTS_FROM_55: 'thematic_map_residents_from_55',
    THEMATIC_MAP_ONE_CHILD_FAMILIES: 'thematic_map_one_child_families',
    THEMATIC_MAP_TWO_CHILDREN_FAMILIES: 'thematic_map_two_children_families',
    THEMATIC_MAP_THREE_CHILDREN_FAMILIES: 'thematic_map_three_children_families',
    THEMATIC_MAP_FOUR_OR_MORE_CHILDREN_FAMILIES: 'thematic_map_four_or_more_children_families',
    THEMATIC_MAP_RAIN: 'thematic_map_rain',
    THEMATIC_MAP_SUNLIGHT_EXPOSURE: 'thematic_map_sunlight_exposure',
    THEMATIC_MAP_SPORTS_GROUNDS_AND_HALLS: 'thematic_map_sports_grounds_and_halls',
    THEMATIC_MAP_GREEN_SPACES_AND_GARDENS: 'thematic_map_green_spaces_and_gardens',
    THEMATIC_MAP_FLOWER_TOWNS: 'thematic_map_flower_towns',
    THEMATIC_MAP_HOUSEHOLDS_WITHOUT_A_CAR: 'thematic_map_households_without_a_car',
    THEMATIC_MAP_LANDSCAPE_TYPE: 'thematic_map_landscape_type',
    THEMATIC_MAP_SUPERMARKETS: 'thematic_map_supermarkets',
    THEMATIC_MAP_RESTAURANTS: 'thematic_map_restaurants',
    THEMATIC_MAP_EDUCATION: 'thematic_map_education',
    THEMATIC_MAP_STORES: 'thematic_map_stores',
    THEMATIC_MAP_NIGHT_LIFE: 'thematic_map_night_life',
    THEMATIC_MAP_GREEN_SPACES: 'thematic_map_green_spaces',
    THEMATIC_MAP_PUBLIC_TRANSPORT: 'thematic_map_public_transport',
    THEMATIC_MAP_QUIETNESS: 'thematic_map_quietness',
    THEMATIC_MAP_SCHOOLS_AND_NURSERIES: 'thematic_map_schools_and_nurseries',
    THEMATIC_MAP_HIGH_SCHOOL_RESULTS: 'thematic_map_high_school_results',
    THEMATIC_MAP_HIGH_SCHOOL_SELECTIVITY: 'thematic_map_high_school_selectivity',
    THEMATIC_MAP_HIGH_SCHOOLS_ADDED_VALUE: 'thematic_map_high_schools_added_value',
    THEMATIC_MAP_GENERAL_PRACTITIONERS: 'thematic_map_general_practitioners',
    THEMATIC_MAP_TOURIST_RECEPTION_CAPACITY: 'thematic_map_tourist_reception_capacity',
    THEMATIC_MAP_2017_SECOND_ROUND_MACRON: 'thematic_map_2017_second_round_macron',
    THEMATIC_MAP_2017_SECOND_ROUND_LEPEN: 'thematic_map_2017_second_round_lepen',
    THEMATIC_MAP_2012_SECOND_ROUND_HOLLANDE: 'thematic_map_2012_second_round_hollande',
    THEMATIC_MAP_LEFT_RIGHT_BALANCE_OF_POWER: 'thematic_map_left_right_balance_of_power',
    THEMATIC_MAP_2012_FIRST_ROUND_SARKOZY: 'thematic_map_2012_first_round_sarkozy',
    THEMATIC_MAP_2012_FIRST_ROUND_HOLLANDE: 'thematic_map_2012_first_round_hollande',
    THEMATIC_MAP_2012_FIRST_ROUND_LEPEN: 'thematic_map_2012_first_round_lepen',
    THEMATIC_MAP_2012_FIRST_ROUND_MELENCHON: 'thematic_map_2012_first_round_melenchon',
    THEMATIC_MAP_2012_FIRST_ROUND_BAYROU: 'thematic_map_2012_first_round_bayrou',
    THEMATIC_MAP_2012_FIRST_ROUND_JOLY: 'thematic_map_2012_first_round_joly',
    THEMATIC_MAP_2012_FIRST_ROUND_DUPOND_AIGNAN: 'thematic_map_2012_first_round_dupond_aignan',
    THEMATIC_MAP_2012_FIRST_ROUND_POUTOU: 'thematic_map_2012_first_round_poutou',
    THEMATIC_MAP_2012_FIRST_ROUND_ABSTENTION_RATE: 'thematic_map_2012_first_round_abstention_rate',
    THEMATIC_MAP_2012_SECOND_ROUND_SARKOZY: 'thematic_map_2012_second_round_sarkozy',
    THEMATIC_MAP_INTENTIONAL_PHYSICAL_HARM: 'thematic_map_intentional_physical_harm',
    THEMATIC_MAP_DAMAGE_TO_PROPERTY: 'thematic_map_damage_to_property',
    THEMATIC_MAP_BURGLARIES: 'thematic_map_burglaries',
    THEMATIC_MAP_THEFTS_RELATED_TO_CARS_AND_MOTORCYCLES: 'thematic_map_thefts_related_to_cars_and_motorcycles',
    THEMATIC_MAP_PROPERTY_DESTRUCTION_AND_DEGRADATION: 'thematic_map_property_destruction_and_degradation',
    THEMATIC_MAP_RENT_APARTMENT: 'thematic_map_rent_apartment',
    THEMATIC_MAP_RENT_HOUSE: 'thematic_map_rent_house',
    OFFICE_BUILDINGS_OTHER: 'office_buildings_other',
    IMPACTED_PLOTS: 'impacted_plots',
    TOWN_AND_NEIGHBORHOODS: 'town_and_neighborhoods',
    NEIGHBORHOODS_INFOS: 'neighborhoods_infos',
    EPCI: 'epci',
    VOTE_PLACE: 'vote_place',
    ALIGNMENT: 'alignment',
    SENSITIVE_NATURAL_AREA: 'sensitive_natural_area',
    INSTALLATION_LIMIT: 'installation_limit',
    ROAD_EASEMENT: 'road_easement',
    STUDY_PERIMETER: 'study_perimeter',
    URBAN_SEQUENCE_PROTECTION: 'urban_sequence_protection',
    PROJECT_AREA: 'project_area',
    NON_AEDIFICANDI_AREA: 'non_aedificandi_area',
    PEDESTRIAN_CONNECTIONS: 'pedestrian_connections',
    LAND_MOVEMENT: 'land_movement',
    COASTAL_PROTECTION: 'coastal_protection',
    TECHNOLOGICAL_RISK: 'technological_risk',
    EASEMENT_FOR_NATURAL_AND_URBAN_SITES_PROTECTION: 'easement_for_natural_and_urban_sites_protection',
    SUBMERSIBLE_EASEMENT: 'submersible_easement',
    TUNNEL_EASEMENT: 'tunnel_easement',
    MISC_EASEMENT: 'misc_easement',
    OTHER_EASEMENT: 'other_easement',
    NOISE_AREA: 'noise_area',
    ADVERTISING_AREA: 'advertising_area',
    FLOODING_AREA: 'flooding_area',
    NATURAL_AREA_OF_ECOLOGICAL_INTEREST: 'natural_area_of_ecological_interest',
    WET_ZONE: 'wet_zone',
    PROHIBITED_CONSTRUCTION: 'prohibited_construction',
    ABF_DOC_URBA: 'abf_doc_urba',
    CONSTRUCTABLE_STRIP: 'constructable_strip',
    ECOLOGICAL_CONTINUITY_ELEMENTS: 'ecological_continuity_elements',
    NATURAL_PERI_URBAN_AREA_PROTECTION_PERIMETER: 'natural_peri_urban_area_protection_perimeter',
    STAY_OF_PROCEEDINGS_PERIMETER: 'stay_of_proceedings_perimeter',
    RICH_SOIL_PROTECTED_AREA: 'rich_soil_protected_area',
    FROST_PERIMETER: 'frost_perimeter',
    WATER_PIPELINE: 'water_pipeline',
    WOODLAND_STRIP: 'woodland_strip',
    ARCHITECTURAL_DISCIPLINE: 'architectural_discipline',
    WOODLAND_AREA: 'woodland_area',
    CONSTRUCTABILITY_LIMIT: 'constructability_limit',
    TEN_YEARS_OLD_HOUSING_WITH_RETAINED_REGULATION: 'ten_years_old_housing_with_retained_regulation',
    NOISE_EXPOSURE_PLAN: 'noise_exposure_plan',
    SPECIAL_ARCHITECTURAL_REQUIREMENTS: 'special_architectural_requirements',
    POLLUTION_RISKS: 'pollution_risks',
    FLOODING_RISKS: 'flooding_risks',
    PVR_AREAS: 'pvr_areas',
    AGRICULTURAL_USE_RESERVED_AREAS: 'agricultural_use_reserved_areas',
    AGRICULTURAL_USE_RESERVED_AREAS_BIS: 'agricultural_use_reserved_areas_bis',
    PROTECTED_OR_AT_RISK_SITES: 'protected_or_at_risk_sites',
    SEWERAGE_ZONING: 'sewerage_zoning',
    EXTERNAL_ASPECT: 'external_aspect',
    ENERGY_PERFORMANCE_AREA: 'energy_performance_area',
    PUBLIC_ELECTRICITY_NETWORKS: 'public_electricity_networks',
    INCREASE_IN_BUILDABLE_VOLUMES: 'increase_in_buildable_volumes',
    DECLARATION_OF_PUBLIC_INTEREST: 'declaration_of_public_interest',
    PLOT_UPDATED_BUILDING_POTENTIAL: 'plot_updated_building_potential',
    ALLOWED_LEVELS_DECREASED: 'allowed_levels_decreased',
    PLOT_ELEVATION_POSSIBLE: 'plot_elevation_possible',
    PRELOCATION: 'prelocation',
    FIRE_HAZARD: 'fire_hazard',
    PROTECTED_CULTIVATED_LAND: 'protected_cultivated_land',
    BANKRUPT_COMPANIES: 'bankrupt_companies',
    BANKRUPT_COMPANIES_PLOTS: 'bankrupt_companies_plots',
    COMPANY_OWNED_PLOTS: 'company_owned_plots',
    LIGHT_VAT_NEIGHBORHOODS: 'light_vat_neighborhoods',
    LIGHT_VAT_ESTATE: 'light_vat_estate',
    CONTENT_BREAK_LINE: 'content_break_line',
    NO_BUILDING_ALLOWED_ENVIRONMENTAL: 'no_building_allowed_environmental',
    LIMITED_BUILDING_ALLOWED_ENVIRONMENTAL: 'limited_building_allowed_environmental',
    PARKING_SPACE_EXEMPTION_CURRENT: 'parking_space_exemption_current',
    PARKING_SPACE_EXEMPTION_FUTURE: 'parking_space_exemption_future',
    EPF_LAND_CONTROL_AND_WATCH: 'epf_land_control_and_watch',
    EPF_LAND_CONTROL: 'epf_land_control',
    EPF_LAND_WATCH: 'epf_land_watch',
    REQUALIFICATION_OPERATION: 'requalification_operation',
    PLANNING_AND_STUDIES: 'planning_and_studies',
    REAL_ESTATE_PROMOTIONS: 'real_estate_promotions',
    WASTELAND: 'wasteland',
    USER_POIS: 'user_pois',
    AIRBORNE_NOISE: 'airborne_noise',
    RETAIL_HYPERMARKET: 'retail_hypermarket',
    RETAIL_SUPERMARKET: 'retail_supermarket',
    RETAIL_MINIMARKET: 'retail_minimarket',
    RETAIL_DEEP_FREEZE: 'retail_deep_freeze',
    RETAIL_HARD_DISCOUNT: 'retail_hard_discount',
    RETAIL_BIO: 'retail_bio',
    RETAIL_DRIVE: 'retail_drive',
    RETAIL_BULK: 'retail_bulk',
    CDAC: 'cdac',
    CNAC: 'cnac',
    LEVEL_CURVE: 'level_curve',
    DISPLAY_MAP: 'display_map',
    DPE: 'dpe',
    DPE_OLD: 'dpe_old',
    DPE_NEW: 'dpe_new',
    DPE_TERTIARY: 'dpe_tertiary',
    DEATH: 'death',
    COMPENSATORY_MEASURES_FOR_BIODIVERSITY_DAMAGE: 'compensatory_measures_for_biodiversity_damage',
    RADAR: 'radar',
    AGRICULTURE_REARING_COMPANY: 'agriculture_rearing_company',
    ZAER_SOLAR_PV_GROUND: 'zaer_solar_pv_ground',
    ZAER_SOLAR_PV_ROOF: 'zaer_solar_pv_roof',
    ZAER_SOLAR_PV_SHADOW_PARKING: 'zaer_solar_pv_shadow_parking',
    ZAER_SOLAR_THERMIC: 'zaer_solar_thermic',
    ZAER_WIND: 'zaer_wind',
    ZAER_GEOTHERMAL: 'zaer_geothermal',
    ZAER_BIOMETHANE: 'zaer_biomethane',
    ZAER_BIOMASS: 'zaer_biomass',
    ZAER_HYDROELECTRICITY: 'zaer_hydroelectricity',
    NATIONAL_MAJOR_PROJECT: 'national_major_project',
    SRCE: 'srce',
    OSM_BIOGAS: 'osm_biogas',
    OSM_BIOMASS: 'osm_biomass',
    OSM_GEOTHERMAL: 'osm_geothermal',
    OSM_SOLAR: 'osm_solar',
    OSM_TIDAL: 'osm_tidal',
    OSM_WIND: 'osm_wind',
    ORFEL: 'orfel',
    MAP_PLOTS: 'map_plots',
};
export const filterKeysGroup = {
    [filtersKey.POI_TRANSPORT]: [
        filtersKey.POI_TRANSPORT_BUS, // Arrêt bus
        filtersKey.POI_TRANSPORT_STATION, // Gare ferroviaire
        filtersKey.POI_TRANSPORT_SUBWAY, // Métro
        filtersKey.POI_TRANSPORT_FERRY, // Terminal de ferry
        filtersKey.POI_TRANSPORT_TRAMWAY, // Tramway
        filtersKey.POI_TRANSPORT_PARKING, // Parking
        filtersKey.POI_TRANSPORT_BICYCLE_PARKING, // Parking à vélo
        filtersKey.POI_TRANSPORT_TAXI, // Station de taxi
        filtersKey.POI_TRANSPORT_GAS_STATION, // Station service
        filtersKey.POI_TRANSPORT_BIKE_LOCATION, // Location de vélo
        filtersKey.POI_TRANSPORT_VELIB, // Vélib'
        filtersKey.POI_TRANSPORT_AIRPORT, // Aéroport
        filtersKey.POI_TRANSPORT_RER, // Rer
    ],
    [filtersKey.POI_COMMERCE_AND_SERVICES]: [
        filtersKey.POI_COMMERCE_AND_SERVICES_BAR,
        filtersKey.POI_COMMERCE_AND_SERVICES_BUTCHER_SHOP, // boucherie
        filtersKey.POI_COMMERCE_AND_SERVICES_BAKERY, // boulangerie
        filtersKey.POI_COMMERCE_AND_SERVICES_NEIGHBORHOOD_MARKET, // marché de quartier
        filtersKey.POI_COMMERCE_AND_SERVICES_RESTAURANT,
        filtersKey.POI_COMMERCE_AND_SERVICES_POST_OFFICE,
        filtersKey.POI_COMMERCE_AND_SERVICES_SUPERMARKET,
        filtersKey.POI_COMMERCE_AND_SERVICES_MINIMARKET,
        filtersKey.POI_COMMERCE_AND_SERVICES_BANK,
        filtersKey.POI_COMMERCE_AND_SERVICES_TOBACCO_PRESS,
        filtersKey.POI_COMMERCE_AND_SERVICES_OTHER_BUSINESS, // autre commerce
    ],
    [filtersKey.POI_HOBBIES]: [
        filtersKey.POI_HOBBIES_CINEMA,
        filtersKey.POI_HOBBIES_FIELD_AND_SPORT_HALL,
        filtersKey.POI_HOBBIES_PARC,
        filtersKey.POI_HOBBIES_CASINO,
        filtersKey.POI_HOBBIES_BEACH,
        filtersKey.POI_HOBBIES_BODYBUILDING_ROOM,
    ],
    [filtersKey.POI_EDUCATION]: [
        filtersKey.POI_EDUCATION_COLLEGE,
        filtersKey.POI_EDUCATION_KINDERGARTEN, // Ecole maternelle
        filtersKey.POI_EDUCATION_PRIMARY_SCHOOL,
        filtersKey.POI_EDUCATION_HIGHER_EDUCATION, // Enseignement supérieur
        filtersKey.POI_EDUCATION_HIGH_SCHOOL, // Lycée
    ],
    [filtersKey.POI_CULTURE]: [
        filtersKey.POI_CULTURE_LIBRARY,
        filtersKey.POI_CULTURE_MONUMENT,
        filtersKey.POI_CULTURE_THEATER,
        filtersKey.POI_CULTURE_OTHER,
    ],
    [filtersKey.POI_HEALTHCARE]: [
        filtersKey.POI_HEALTHCARE_HOSPITAL,
        filtersKey.POI_HEALTHCARE_PHARMACY,
        filtersKey.POI_HEALTHCARE_GENERAL_DOCTOR,
        filtersKey.POI_HEALTHCARE_SPECIALIST_DOCTOR,
    ],
    [filtersKey.POI_EARLY_CHILDHOOD]: [
        filtersKey.POI_EARLY_CHILDHOOD_TOY_LIBRARY, // ludothèque
        filtersKey.POI_EARLY_CHILDHOOD_CRECHE,
    ],
    [filtersKey.POI_TOURISM]: [
        filtersKey.POI_TOURISM_HOSTEL,
        filtersKey.POI_TOURISM_CAMPING,
        filtersKey.POI_TOURISM_RESIDENCE_VILLAGE_PARK,
    ],
    [filtersKey.POI_PUBLIC_SERVICE]: [
        filtersKey.POI_PUBLIC_SERVICE_CITY_HALL,
        filtersKey.POI_PUBLIC_SERVICE_CAF,
        filtersKey.POI_PUBLIC_SERVICE_POLICE,
        filtersKey.POI_PUBLIC_SERVICE_CPAM,
        filtersKey.POI_PUBLIC_SERVICE_GENDARMERIE,
        filtersKey.POI_PUBLIC_SERVICE_JOB_CENTER, // Pole emploi
        filtersKey.POI_PUBLIC_SERVICE_BUSINESS_TAX_DEPARTMENT, // Service des impôts des entreprises
        filtersKey.POI_PUBLIC_SERVICE_PERSONAL_TAX_DEPARTMENT, // Service des impôts des particuliers
        filtersKey.POI_PUBLIC_SERVICE_PREFECTURE,
        filtersKey.POI_PUBLIC_SERVICE_GREFFE,
    ],
    [filtersKey.POI_RISKS]: [
        filtersKey.POI_RISKS_RELAY_ANTENNAS,
        filtersKey.POI_RISKS_POLLUTING_ESTABLISHMENT,
        filtersKey.POI_RISKS_POLLUTED_SITE,
        filtersKey.POI_RISKS_POTENTIALLY_POLLUTED_SITE,
    ],
    [filtersKey.POI_SPORTS_ACTIVITIES]: [
        filtersKey.POI_SPORTS_ACTIVITIES_GOLF,
        filtersKey.POI_SPORTS_ACTIVITIES_VTT,
        filtersKey.POI_SPORTS_ACTIVITIES_HIKING, // Randonnée
        filtersKey.POI_SPORTS_ACTIVITIES_HORSE_RIDING, // Equitation
        filtersKey.POI_SPORTS_ACTIVITIES_ESCALATION,
        filtersKey.POI_SPORTS_ACTIVITIES_BILLIARD_BOWLING_PAINTBALL,
        filtersKey.POI_SPORTS_ACTIVITIES_MECHANICAL_SPORTS,
        filtersKey.POI_SPORTS_ACTIVITIES_AIR_SPORTS,
        filtersKey.POI_SPORTS_ACTIVITIES_FISHING,
        filtersKey.POI_SPORTS_ACTIVITIES_SKI,
        filtersKey.POI_SPORTS_ACTIVITIES_SWIMMING_AND_WATER_SPORTS,
        filtersKey.POI_SPORTS_ACTIVITIES_TENNIS,
        filtersKey.POI_SPORTS_ACTIVITIES_LEISURE_CENTER, // base de loisir
    ],
    [filtersKey.POI_RECREATIONAL_ACTIVITIES]: [
        filtersKey.POI_RECREATIONAL_ACTIVITIES_AMUSEMENT_PARK, // Parc d'attraction
        filtersKey.POI_RECREATIONAL_ACTIVITIES_AQUARIUM,
        filtersKey.POI_RECREATIONAL_ACTIVITIES_ZOO,
        filtersKey.POI_RECREATIONAL_ACTIVITIES_MALL, // centre commercial
    ],
    [filtersKey.POI_TOURISTIC_PLACES]: [
        filtersKey.POI_TOURISTIC_PLACES_UNAVOIDABLE, // Incontournable
        filtersKey.POI_TOURISTIC_PLACES_MUSEUM,
    ],
    [filtersKey.POI_PLACES_OF_INTEREST]: [
        filtersKey.POI_PLACES_OF_INTEREST_BEACH,
        filtersKey.POI_PLACES_OF_INTEREST_SWIMMING_LAKE_RIVER, // Baignade en lac et rivière
        filtersKey.POI_PLACES_OF_INTEREST_SEA,
        filtersKey.POI_PLACES_OF_INTEREST_MOUNTAIN,
        filtersKey.POI_PLACES_OF_INTEREST_CAMPAIGN,
        filtersKey.POI_PLACES_OF_INTEREST_CITY,
        filtersKey.POI_PLACES_OF_INTEREST_DOWNTOWN,
        filtersKey.POI_PLACES_OF_INTEREST_LAKE,
        filtersKey.POI_PLACES_OF_INTEREST_SKI_STATION,
        filtersKey.POI_PLACES_OF_INTEREST_AGGLOMERATION,
    ],
};
// Permet de ne pas charger ces thematique lorsque l'on clique dessus (car elles ont leur propre layer)
export const filterKeyNotReallyThematic = [
    filtersKey.THEMATIC_MAP_RENT_APARTMENT,
    filtersKey.THEMATIC_MAP_RENT_HOUSE,
];
const IOFilterKey = iots.keyof(Object.fromEntries(Object.keys(filtersKey).map((key) => [filtersKey[key], null])), 'IOFilterKey');
export const IOFilterObject = iots.union([
    IOTranslatedFilterItem,
    IOFilterItemWithLegend,
]);
const IOFilter = iots.union([IOFilterKey, IOFilterObject]);
const IOFilterContent = iots.unknown;
export const IOFilterCategory = iots.partial({
    endpoint: iots.union([iots.string, iots.array(iots.string)]),
    layers: iots.array(IOFilter),
    feasibility: iots.string,
    documents: iots.array(iots.string),
    find_out_more: iots.string,
    project_impact: iots.string,
    content: iots.array(IOFilterContent),
    content_after: IOFilterContent,
    content_by_layer: IOFilterContent,
    content_after_by_layer: IOFilterContent,
    hidden: iots.boolean,
    hide_when_empty: iots.boolean,
    only_content: iots.boolean,
    only_document: iots.boolean,
}, 'IOFilterCategory');
export const IOFiltersTree = iots.recursion('IOFiltersTree', () => iots.record(iots.string, iots.union([IOFiltersTree, IOFilterCategory])));
export const getFilterNamesFromPathAndPrefix = (layersTree, path, prefix = null) => {
    const category = layersTree
        ? getNested(layersTree, path)
        : { layers: [] };
    if (!category || !category.layers) {
        return [];
    }
    return category.layers
        .filter((layer) => {
        return (IOFilterObject.is(layer) && (!prefix || layer.id.startsWith(prefix)));
    })
        .map((layer) => layer.id);
};
export const hideCategory = (category) => {
    // Category is not of last level, we check its children
    if (!lastNode(category)) {
        return Object.keys(category).every((key) => hideCategory(category[key]));
    }
    const treeCategoryKeys = Object.keys(category).filter((key) => key !== 'hidden');
    // Category has no entries OR category has "hide when empty" set to true and no layers
    if (!treeCategoryKeys ||
        (category.hide_when_empty &&
            (((category.layers === undefined || category.layers?.length === 0) &&
                !category.only_content &&
                !category.only_document) ||
                (category.only_content && category.content.length === 0) ||
                (category.only_document && category.documents.length === 0)))) {
        return true;
    }
    // Category has only "layers" entry and it is empty
    return (treeCategoryKeys.length === 1 &&
        treeCategoryKeys[0] === LAYERS_KEY &&
        ((category.layers?.length === 0 &&
            !category.only_content &&
            !category.only_document) ||
            (category.only_content && category.content.length === 0) ||
            (category.only_document && category.documents.length === 0)));
};
// Pour savoir si on est sur le dernier noeud des categories pour les filtres
export const lastNode = (tree) => {
    if (!isObject(tree)) {
        return true;
    }
    return LAYERS_KEY in tree || ENDPOINT_KEY in tree || CONTENT_KEY in tree;
};
export const getFilterKey = (filter) => IOFilterObject.is(filter) ? filter.id : filter;
export const filtersAreEqual = (filter1, filter2) => {
    return getFilterKey(filter1) === getFilterKey(filter2);
};
export const isRadioFilter = (filter) => {
    return IOFilterObject.is(filter) && !!filter.radioName;
};
export const getFilterRadioName = (filter) => {
    return (IOFilterObject.is(filter) && filter.radioName) ?? null;
};
export const isFilterChecked = (filter, filters) => {
    const filterKey = getFilterKey(filter);
    const isFilterObject = IOFilterObject.is(filter);
    const radioName = isFilterObject && filter.radioName ? filter.radioName : null;
    return radioName
        ? filters[radioName] === filterKey
        : !!filters[filterKey];
};
export const getFlattenedFilters = (filters) => {
    if (lastNode(filters)) {
        return filters?.layers ?? [];
    }
    let flattenedFilters = [];
    Object.keys(filters).forEach((key) => {
        flattenedFilters = flattenedFilters.concat(getFlattenedFilters(filters[key]));
    });
    return flattenedFilters;
};
export const getFiltersSearchRegex = (search) => new RegExp(removeAccent(escapeRegExp(search)), 'gi');
export const titleMatch = (title, search) => {
    title = removeAccent(title);
    return title && title.match(search);
};
export const highlightTitle = (title, search) => {
    let titleNoAccent = removeAccent(title);
    titleNoAccent = titleNoAccent.replace(search, '|$&|');
    const start = titleNoAccent.indexOf('|');
    const end = titleNoAccent.indexOf('|', start + 1);
    return (title.substring(0, start) +
        '<mark>' +
        title.substring(start, end - 1) +
        '</mark>' +
        title.substring(end - 1, title.length));
};
const filterLayers = (t, layers, search, selectedTownId) => {
    return layers.filter((layer) => {
        const isFilterObject = IOFilterObject.is(layer);
        const title = isFilterObject
            ? layer.needsTranslate
                ? t(`sidebar.layers.${layer.title}`, {
                    TVA: getTVA(selectedTownId),
                })
                : layer.title
            : t(`sidebar.layers.${layer}`, {
                TVA: getTVA(selectedTownId),
            });
        return titleMatch(title, search);
    });
};
const filterDocuments = (t, documents, search, selectedTown) => {
    return documents
        .map((documentKey) => extractTitle(selectedTown?.docs[documentKey]))
        .filter((title) => titleMatch(title, search));
};
const filterLayerTitle = (t, titles, search) => {
    return Object.values(titles).filter((title) => {
        const text = t(title + '.title');
        return titleMatch(text, search);
    });
};
export const filterLayersTree = (t, tree, search, selectedTown) => {
    if (lastNode(tree)) {
        const { layers, documents, content_by_layer, ...rest } = tree;
        const filteredLayers = filterLayers(t, layers ?? [], search, selectedTown?.id);
        const filteredDocuments = filterDocuments(t, documents ?? [], search, selectedTown);
        const filteredTitleLayer = filterLayerTitle(t, content_by_layer ?? {}, search);
        return isEmpty(filteredLayers) &&
            isEmpty(filteredDocuments) &&
            isEmpty(filteredTitleLayer)
            ? {}
            : {
                [LAYERS_KEY]: filteredLayers
                    .concat(filteredDocuments)
                    .concat(filteredTitleLayer),
                ...rest,
            };
    }
    const filteredTree = {};
    Object.keys(tree).forEach((key) => {
        // if current category match the filter, we keep all of it
        const categoryTitle = t(`sidebar.categories.${key}`);
        const subTree = titleMatch(categoryTitle, search)
            ? tree[key]
            : filterLayersTree(t, tree[key], search, selectedTown);
        if (!isEmpty(subTree)) {
            if (subTree.layers) {
                if (subTree.layers.length !== 0 ||
                    (!subTree.hide_when_empty && subTree.layers.length === 0) ||
                    (subTree.hide_when_empty &&
                        subTree.only_document &&
                        subTree.documents.length !== 0)) {
                    filteredTree[key] = subTree;
                }
            }
            else {
                filteredTree[key] = subTree;
            }
        }
    });
    return filteredTree;
};
export const LAST_SIX_MONTHS = 'lastSixMonths';
export const OLDER_YEAR = 2019;
export const getTimePeriodOptions = (yearStart = OLDER_YEAR) => {
    const options = [LAST_SIX_MONTHS];
    const date = new Date();
    for (let i = date.getFullYear(); i >= yearStart; i--) {
        options.push(i);
    }
    return options;
};
export const getTownPlotsInfo = (mainLayers) => {
    if (mainLayers) {
        const plotsLayer = mainLayers?.layers?.find((layer) => {
            return IOFilterObject.is(layer) && layer?.id === filtersKey.PLOTS;
        });
        return plotsLayer
            ? {
                zones: plotsLayer?.subAreas,
                count: plotsLayer?.count,
                zonesAdditional: plotsLayer?.subAreasAdditional,
            }
            : null;
    }
};
export const registerTableFilters = ({ keysToRegister = [], columnFilters, updateTableFilter, }) => {
    keysToRegister.map(({ name, formatValue }) => {
        const filter = columnFilters.find((filter) => filter.id === name);
        if (filter && Array.isArray(filter.value) && filter.value.length > 0) {
            updateTableFilter(name, formatValue ? formatValue(filter.value) : filter.value);
        }
        else {
            updateTableFilter(name, null);
        }
    });
};
