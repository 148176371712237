import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import ContactSelect from 'components/inputs/ContactSelect';
import TextInput from 'components/inputs/TextInput';
import CountryField from 'components/modals/contactModal/CountryField';
import PostalCodeTownFields from 'components/modals/contactModal/PostalCodeTownFields';
import useContactList from 'hooks/crm/useContactList';
import { FROM_CONTACT } from 'utils/constants';
import { getRecipientDefaultValues } from './CourierFormUtils';
const ContactRecipientSelect = ({ fromPanel }) => {
    const { t } = useTranslation();
    const { contacts } = useContactList();
    const { setValue } = useFormContext();
    const [showContactSelect, setShowContactSelect] = React.useState(fromPanel === FROM_CONTACT);
    const recipientDefaultValues = getRecipientDefaultValues();
    const onChangeContactId = (value) => {
        if (!value) {
            Object.keys(recipientDefaultValues).map((field) => setValue(field, recipientDefaultValues[field]));
            return;
        }
        const contact = contacts.find((contact) => contact.id === value);
        Object.keys(recipientDefaultValues).map((field) => setValue(field, contact[field], { shouldValidate: true }));
        setValue('civility', contact.titleStr);
        setValue('postalCode', contact.postalCode?.replace(/[^\d]/g, ''));
        setValue('country', contact.country);
    };
    return (<>
      {showContactSelect ? (<Box width="full" display={fromPanel === FROM_CONTACT ? 'none' : ''}>
          <ContactSelect name="contactId" onChange={onChangeContactId}/>
        </Box>) : (<Text fontSize={14}>
          {t('courier.win_time')} :{' '}
          <Text cursor="pointer" as="span" color="red" onClick={() => setShowContactSelect(true)}>
            {t('courier.add_contact')}
          </Text>
        </Text>)}
    </>);
};
const RecipientFields = ({ fromPanel }) => {
    const { t } = useTranslation();
    return (<VStack width="full" spacing={1} alignItems="flex-start">
      <Text fontWeight="bold">
        {fromPanel === FROM_CONTACT
            ? '2. ' + t('courier.fill_data_recipient_contact')
            : '2. ' + t('courier.fill_data_recipient')}
      </Text>
      <ContactRecipientSelect fromPanel={fromPanel}/>
      <VStack width="full" spacing={2} alignItems="flex-start">
        <TextInput name="civility" placeholder={t(`courier.fields.title.placeholder`)}/>

        <TextInput name="firstname" placeholder={t(`courier.fields.firstname.placeholder`)}/>

        <TextInput name="lastname" placeholder={t(`courier.fields.lastname.placeholder`)} required data-cy="courier_form_lastname"/>

        <TextInput name="company" placeholder={t(`courier.fields.company.placeholder`)}/>

        <TextInput name="address" placeholder={t(`courier.fields.address.placeholder`)} required data-cy="courier_form_address"/>

        <HStack width="full" alignItems="flex-start">
          <PostalCodeTownFields noLabel={true} id="courier"/>
          <CountryField noLabel={true} id="courier" disableCountry={true}/>
        </HStack>

        <TextInput name="addressBP" placeholder={t(`courier.fields.addressBP.placeholder`)}/>

        <TextInput name="addressComplement" placeholder={t(`courier.fields.addressComplement.placeholder`)}/>

        <Text fontSize={12}>* {t(`courier.mandatory_field`)}</Text>
      </VStack>
    </VStack>);
};
export default RecipientFields;
