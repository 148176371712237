import { useCallback, useMemo } from 'react';
import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import useCurrentSite from 'hooks/useCurrentSite';
import { getSiteImage } from 'hooks/useSiteLogo';
import customMarkerImg from 'images/custom_marker.png';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOUserPOIMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        legend: iots.union([iots.string, iots.null]),
        name: iots.union([iots.string, iots.null]),
        address: iots.string,
        status: iots.number,
        deliveryYear: iots.string,
        lodgings: iots.string,
        label1: iots.union([iots.string, iots.null]),
        info1: iots.union([iots.string, iots.null]),
        label2: iots.union([iots.string, iots.null]),
        info2: iots.union([iots.string, iots.null]),
        label3: iots.union([iots.string, iots.null]),
        info3: iots.union([iots.string, iots.null]),
        label4: iots.union([iots.string, iots.null]),
        info4: iots.union([iots.string, iots.null]),
        label5: iots.union([iots.string, iots.null]),
        info5: iots.union([iots.string, iots.null]),
        label6: iots.union([iots.string, iots.null]),
        info6: iots.union([iots.string, iots.null]),
        label7: iots.union([iots.string, iots.null]),
        info7: iots.union([iots.string, iots.null]),
        label8: iots.union([iots.string, iots.null]),
        info8: iots.union([iots.string, iots.null]),
    }),
], 'IOUserPOIMarkerItem');
const getMarkerImg = (marker) => {
    if (!marker.legend)
        return null;
    try {
        return require(`../../images/UserPOIs/${marker.legend}`);
    }
    catch (e) {
        if (e instanceof Error) {
            //eslint-disable-next-line
            console.error("Can't load ", marker.legend);
        }
        else
            throw e;
    }
    return customMarkerImg;
};
const fetchUserPOIs = (townId, townScot, selectedTown, id) => () => get(iots.array(IOUserPOIMarkerItem), `legacy/user-pois/${id}`);
const useUserPOIsLayer = () => {
    const { t } = useTranslation();
    const { currentSite } = useCurrentSite();
    const getInfoWindowContent = useCallback((item) => {
        const content = (<Box>
          {item.name && (<p>
              <b>{t('info_window.user_pois.name')}</b>
              {' : '}
              {item.name}
            </p>)}
          {item.address && (<p>
              <b>{t('info_window.user_pois.address')}</b>
              {' : '}
              {item.address}
            </p>)}
          {item.deliveryYear && (<p>
              <b>{t('info_window.user_pois.delivery_year')}</b>
              {' : '}
              {item.deliveryYear}
            </p>)}
          {item.lodgings && (<p>
              <b>{t('info_window.user_pois.lodgings')}</b>
              {' : '}
              {item.lodgings}
            </p>)}
          {[...Array(20)].map((_, index) => {
                const info = item[`info${index}`];
                const label = item[`label${index}`];
                return info ? (<p key={index}>
                <b>{label}</b>
                {' : '}
                {info.startsWith('http') ? (<a href={info} target="_blank" rel="noreferrer">
                    {info}
                  </a>) : (info)}
              </p>) : (<></>);
            })}
        </Box>);
        return ReactDOMServer.renderToStaticMarkup(content);
    }, [t]);
    const legends = useMemo(() => ({
        [filtersKey.USER_POIS]: {
            layerKey: filtersKey.USER_POIS,
            label: filtersKey.USER_POIS,
            needsTranslate: true,
            image: getSiteImage(currentSite.favicon),
        },
    }), [currentSite]);
    createLayersHook({
        fetch: fetchUserPOIs,
        markerImg: getMarkerImg,
        legends,
        getInfoWindowContent,
        extraParams: [currentSite?.legacyId],
        disableQuery: !currentSite,
    })(filtersKey.USER_POIS);
};
const UserPOIsLayers = () => {
    useUserPOIsLayer();
    return null;
};
export default UserPOIsLayers;
