import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Popover, PopoverContent, PopoverTrigger, useDisclosure, } from '@chakra-ui/react';
import { useMapDisplay } from 'hooks/contexts/useMapContext';
import { filtersKey } from 'utils/filters';
import { toolbarButtonsStyle } from './LayersButtons';
const PopoverMenu = ({ label, isActive, menuItems, showCheck, trigger = 'click', placement = 'bottom-end', children, selectDefault = () => ({}), handleClose = () => ({}), ...buttonProps }) => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const { t } = useTranslation();
    const { historyMapYear } = useMapDisplay();
    const handleCloseMenu = () => {
        handleClose();
        onClose();
    };
    return (<Popover isLazy placement={placement} trigger={trigger} isOpen={isOpen} onOpen={onOpen} onClose={handleCloseMenu}>
      <PopoverTrigger>
        <Button {...toolbarButtonsStyle} backgroundColor={isActive ? 'colorMode.background700' : 'colorMode.background900'} as={Button} rightIcon={<ChevronDownIcon />} data-cy={buttonProps?.id} {...buttonProps} onClick={() => selectDefault(filtersKey.CADASTRE)}>
          {label === t(`map.map_type.historyMap`) && (<CheckIcon color={historyMapYear ? 'inherit' : 'transparent'} marginRight="0.5rem" marginLeft="-0.5rem"/>)}
          {label}
        </Button>
      </PopoverTrigger>
      <PopoverContent width="auto">
        {menuItems?.map(({ label, isActive, handleClick }) => {
            return (<Button size="sm" key={label} borderRadius={0} leftIcon={showCheck ? (<CheckIcon color={isActive ? 'inherit' : 'transparent'} marginLeft="0.5rem"/>) : null} onClick={() => {
                    handleClick();
                    onClose();
                }} justifyContent="left" paddingLeft="0">
              {label}
            </Button>);
        })}
        {children}
      </PopoverContent>
    </Popover>);
};
export default PopoverMenu;
