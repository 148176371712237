import * as React from 'react';
import { Table, Tbody, Td, Tr } from '@chakra-ui/react';
import { ANALYTICS_PLOT_TAB } from 'analytics/useAnalytics';
import CreateContactButton from 'components/buttons/CreateContactButton';
import OwnerDetail from './OwnerDetail';
import OwnerIdentity from './OwnerIdentity';
import OwnerType from './OwnerType';
import { formatOwnerContactInfo, NoOwner } from './PlotOwnerDetailWrapper';
const OwnerTableDisplay = ({ owners, setContactToCreate, }) => {
    return (<Table padding={4} width="full" variant="owner">
      <Tbody>
        {owners.length > 0 ? (owners?.map((owner, index) => {
            return (<Tr key={`owner-${index}`}>
                <Td alignItems="start" width="22%">
                  <OwnerIdentity owner={owner}/>
                </Td>

                <Td alignItems="start" width="22%">
                  <OwnerDetail owner={owner}/>
                </Td>

                <Td>
                  <OwnerType owner={owner}/>
                </Td>
                <Td width="180px">
                  <CreateContactButton newContact={formatOwnerContactInfo(owner)} setContactToCreate={setContactToCreate} analyticsCategory={ANALYTICS_PLOT_TAB} analyticsAction="Création contact - sous onglet propriétaire"/>
                </Td>
              </Tr>);
        })) : (<Tr>
            <Td>
              <NoOwner />
            </Td>
          </Tr>)}
      </Tbody>
    </Table>);
};
export default OwnerTableDisplay;
