import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Box, FormControl, FormErrorMessage, FormLabel, HStack, Icon, Input, Link, Select, Stack, Switch, Text, Textarea, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_OPEN } from 'analytics/useAnalytics';
import InfoPopover from 'components/InfoPopover';
import LogoFacebook from 'images/logo/facebook.svg';
import LogoGoogle from 'images/logo/google.svg';
import LogoLinkedin from 'images/logo/linkedIn.svg';
import LogoPageJaune from 'images/logo/pages_jaunes.png';
import LogoPappers from 'images/logo/pappers.png';
import { ACTION_CREATE, CONTACT_TYPES_OPTIONS } from 'utils/constants';
import CountryField from './CountryField';
import PostalCodeTownFields from './PostalCodeTownFields';
const MandatoryMessage = () => {
    const { t } = useTranslation();
    return (<Text as="span" color="red.500" fontWeight={100} fontSize="xx-small">
      - {t('global.fields.mandatory')}
    </Text>);
};
const ContactForm = ({ onChange, action }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const { formState: { errors }, register, getValues, watch, } = useFormContext();
    const onChangeAction = onChange ? { onChange: onChange } : {};
    const handleKeyUpSiren = (e) => {
        const element = e.target;
        if (element.value.length > 9) {
            element.value = element.value.slice(0, 9);
        }
    };
    const getLink = (type) => {
        const postalCode = watch('postalCode');
        const town = watch('town');
        const address = watch('address');
        const lastname = watch('lastname');
        const firstname = watch('firstname');
        const company = watch('company');
        const siren = watch('siren');
        let addressUrl = address;
        let nameUrl = '';
        if ((address && postalCode) || (address && town)) {
            if (postalCode) {
                addressUrl += ' ' + postalCode;
            }
            if (town) {
                addressUrl += ' ' + town;
            }
            addressUrl = encodeURI(addressUrl);
        }
        if (lastname || firstname) {
            nameUrl = encodeURI(lastname + ' ' + firstname);
        }
        let url = '';
        switch (type) {
            case 'white_page':
                trackEvent({
                    category: ANALYTICS_OPEN,
                    action: 'Pages blanches - Contact',
                });
                if (addressUrl || nameUrl) {
                    return `https://www.pagesjaunes.fr/pagesblanches/recherche?quoiqui=${nameUrl}&ou=${addressUrl}&univers=pagesblanches`;
                }
                else {
                    return `https://www.pagesjaunes.fr/pagesblanches`;
                }
            case 'linkedin':
                trackEvent({
                    category: ANALYTICS_OPEN,
                    action: 'Linkedin - Contact',
                });
                if (nameUrl) {
                    return `https://www.linkedin.com/search/results/all/?keywords=${nameUrl}&origin=GLOBAL_SEARCH_HEADER&sid=P2p`;
                }
                else {
                    return `https://www.linkedin.com`;
                }
            case 'facebook':
                trackEvent({
                    category: ANALYTICS_OPEN,
                    action: 'Facebook - Contact',
                });
                if (nameUrl) {
                    return `https://fr-fr.facebook.com/public/${nameUrl}`;
                }
                else {
                    return `https://fr-fr.facebook.com/public/Search/`;
                }
            case 'google':
                trackEvent({
                    category: ANALYTICS_OPEN,
                    action: 'Google - Contact',
                });
                url = nameUrl;
                if (company) {
                    url += encodeURI(' ' + company);
                }
                if (siren) {
                    url += encodeURI(' ' + siren);
                }
                if (url) {
                    return `https://www.google.com/search?q=${url}`;
                }
                else {
                    return `https://www.google.com`;
                }
            case 'pappers':
                trackEvent({
                    category: ANALYTICS_OPEN,
                    action: 'Pappers - Contact',
                });
                url = nameUrl;
                if (siren) {
                    url += encodeURI(' ' + siren);
                }
                if (url) {
                    return `https://www.pappers.fr/recherche?q=${url}`;
                }
                else {
                    return `https://www.pappers.fr/recherche`;
                }
        }
    };
    const openAll = () => {
        document.getElementById('link_white_page').click();
        document.getElementById('link_linkedin').click();
        document.getElementById('link_facebook').click();
        document.getElementById('link_google').click();
        document.getElementById('link_pappers').click();
    };
    return (<Stack spacing={3} data-cy="contact_form">
      <FormControl isInvalid={!!errors?.lastname}>
        <FormLabel fontWeight={700}>
          {t('contact.fields.lastname.label')} <MandatoryMessage />
        </FormLabel>
        <Input type="text" width="full" placeholder={t('contact.fields.lastname.placeholder')} data-cy="contact_form_lastname" {...register('lastname')}/>
        <FormErrorMessage>{t(errors?.lastname?.message)}</FormErrorMessage>
      </FormControl>

      <HStack>
        <FormControl width="35%">
          <FormLabel>{t('contact.fields.title.label')}</FormLabel>

          <Input type="text" width="full" placeholder={t('contact.fields.title.label')} {...register('titleStr')}/>
        </FormControl>

        <FormControl width="65%">
          <FormLabel>{t('contact.fields.firstname.label')}</FormLabel>
          <Input type="text" width="full" placeholder={t('contact.fields.firstname.label')} data-cy="contact_form_firstname" {...register('firstname', { ...onChangeAction })}/>
        </FormControl>
      </HStack>

      <HStack>
        <FormControl>
          <FormLabel>{t('contact.fields.company.label')}</FormLabel>
          <Input type="text" width="full" placeholder={t('contact.fields.company.placeholder')} data-cy="contact_form_company" {...register('company', { ...onChangeAction })}/>
        </FormControl>

        <FormControl width="38%">
          <FormLabel>{t('contact.fields.siren.label')}</FormLabel>
          <Input type="number" width="full" onKeyUp={handleKeyUpSiren} placeholder={t('contact.fields.siren.placeholder')} maxLength={9} data-cy="contact_form_siren" {...register('siren', { ...onChangeAction })}/>
        </FormControl>
      </HStack>

      <FormControl>
        <FormLabel display="flex" alignItems="center">
          {t('contact.fields.address.label')}
          <HStack spacing={2}>
            <Text as="span" color="red.500">
              <Icon as={ExternalLinkIcon} width="16px" height="16px" fill="currentColor" marginRight="3px" marginLeft="7px" marginBottom="3px"/>
              {t('contact.search_on')}
            </Text>
            <Link id="link_white_page" isExternal display="flex" variant="primary" href={getLink('white_page')}>
              <img width={16} src={LogoPageJaune} alt="Page Jaune" title="Page Jaune" style={{ marginRight: '3px' }}/>
            </Link>
            <Link id="link_linkedin" isExternal display="flex" variant="primary" href={getLink('linkedin')}>
              <img width={16} src={LogoLinkedin} alt="Linkedin" title="Linkedin"/>
            </Link>
            <Link id="link_facebook" isExternal display="flex" variant="primary" href={getLink('facebook')}>
              <img width={16} src={LogoFacebook} alt="Facebook" title="Facebook"/>
            </Link>
            <Link id="link_google" isExternal display="flex" variant="primary" href={getLink('google')}>
              <img width={16} src={LogoGoogle} alt="Google" title="Google"/>
            </Link>
            <Link id="link_pappers" isExternal display="flex" variant="primary" href={getLink('pappers')}>
              <img width={16} src={LogoPappers} alt="Pappers" title="Pappers"/>
            </Link>
            <Link isExternal display="flex" variant="primary" onClick={openAll}>
              {t('contact.all')}
            </Link>
          </HStack>
        </FormLabel>
        <Input type="text" width="full" placeholder={t('contact.fields.address.placeholder')} data-cy="contact_form_address" {...register('address', { ...onChangeAction })}/>
      </FormControl>

      <HStack width="full" alignItems="flex-start">
        <PostalCodeTownFields id="contact"/>
        <CountryField id="contact"/>
      </HStack>

      <HStack>
        <FormControl>
          <FormLabel>{t('contact.fields.addressBP.label')}</FormLabel>
          <Input type="text" width="full" placeholder={t('contact.fields.addressBP.label')} data-cy="contact_form_addressBP" {...register('addressBP', { ...onChangeAction })}/>
        </FormControl>

        <FormControl>
          <FormLabel>{t('contact.fields.addressComplement.label')}</FormLabel>
          <Input type="text" width="full" placeholder={t('contact.fields.addressComplement.placeholder')} data-cy="contact_form_addressComplement" {...register('addressComplement', { ...onChangeAction })}/>
        </FormControl>
      </HStack>
      <HStack>
        <FormControl isInvalid={!!errors?.mobile}>
          <FormLabel>{t('contact.fields.mobile.label')}</FormLabel>
          <Input type="text" width="full" title={t('contact.fields.mobile.help')} placeholder={t('contact.fields.mobile.placeholder')} data-cy="contact_form_mobile" {...register('mobile', {
        ...onChangeAction,
    })}/>
          <FormErrorMessage>{errors?.mobile?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors?.landline}>
          <FormLabel opacity={0}>{t('contact.fields.mobile.label')}</FormLabel>
          <Input type="tel" width="full" placeholder={t('contact.fields.landline.placeholder')} data-cy="contact_form_landline" {...register('landline', {
        ...onChangeAction,
    })}/>

          <FormErrorMessage>{errors?.landline?.message}</FormErrorMessage>
        </FormControl>
      </HStack>

      <HStack>
        <FormControl>
          <FormLabel>{t('contact.fields.primaryEmail.label')}</FormLabel>
          <Input type="email" width="full" placeholder={t('contact.fields.primaryEmail.label')} data-cy="contact_form_primaryEmail" {...register('primaryEmail', { ...onChangeAction })}/>
        </FormControl>

        <FormControl>
          <FormLabel>{t('contact.fields.type.label')}</FormLabel>
          <Select size="sm" {...register('type', { valueAsNumber: true })}>
            {Object.keys(CONTACT_TYPES_OPTIONS).map((key) => (<option key={key} value={CONTACT_TYPES_OPTIONS[key].value}>
                {t(CONTACT_TYPES_OPTIONS[key].label)}
              </option>))}
          </Select>
        </FormControl>
      </HStack>

      {action === ACTION_CREATE && (<FormControl>
          <FormLabel display="flex" alignItems="center">
            {t('contact.fields.comment.label')}
            <Box marginLeft={1}>
              <InfoPopover withTitle={false} closeOnOut={true}>
                <Stack>
                  <Text dangerouslySetInnerHTML={{
                __html: t('contact.fields.comment.info'),
            }}/>
                </Stack>
              </InfoPopover>
            </Box>
          </FormLabel>
          <Textarea {...register('comment')} minHeight="80px" placeholder={t('contact.fields.comment.placeholder')}/>
        </FormControl>)}

      <FormControl>
        <FormLabel>{t('contact.fields.addressesPlots.label')}</FormLabel>
        <Input type="text" width="full" placeholder={t('contact.fields.addressesPlots.label')} data-cy="contact_form_addressesPlots" {...register('addressesPlots', { ...onChangeAction })}/>
      </FormControl>

      <FormControl display="flex" alignItems="center">
        <FormLabel marginBottom={0}>
          {t('contact.fields.vendor.label')}
        </FormLabel>
        <Switch defaultChecked={!!getValues('vendor')} {...register('vendor', { ...onChangeAction })}/>
      </FormControl>

      <FormControl display="flex" alignItems="center">
        <FormLabel marginBottom={0}>{t('contact.fields.npr.label')}</FormLabel>
        <Switch defaultChecked={!!getValues('npr')} {...register('npr', { ...onChangeAction })}/>
      </FormControl>

      <FormControl display="flex" alignItems="center">
        <FormLabel display="flex" alignItems="center" marginBottom={0}>
          {t('contact.fields.npai.label')}
          <Box marginLeft={1}>
            <InfoPopover withTitle={false} closeOnOut={true}>
              <Stack>
                <Text>{t('contact.fields.npai.info')}</Text>
              </Stack>
            </InfoPopover>
          </Box>
        </FormLabel>
        <Switch defaultChecked={getValues('npai')} {...register('npai', { ...onChangeAction })}/>
      </FormControl>
    </Stack>);
};
export default ContactForm;
