import * as React from 'react';
import { useCallback, useState } from 'react';
import useAnalytics, { ANALYTICS_CATEGORY_SEARCH } from 'analytics/useAnalytics';
import set from 'lodash/set';
import { createContext } from 'use-context-selector';
import { CADASTRE_ZOOM_LIMIT } from 'components/layers/CadastreLayer';
import { PLOTS_ZOOM_LIMIT } from 'components/layers/PlotsLayer';
import { PLU_GPU_ZOOM_LIMIT } from 'components/layers/PLUGPULayer';
import { PLU_ZOOM_LIMIT } from 'components/layers/PLULayer';
import { filterKeysGroup, filtersKey, getFiltersSearchRegex, } from 'utils/filters';
const INITIAL_FILTERS = {
    [filtersKey.TOWNS]: true,
    [filtersKey.TOWN]: true,
};
const INITIAL_EXTRA_FILTERS = {};
export const tableFiltersKey = {
    PLOT_STATUS: 'plotStatus',
    PLOT_USER_EMAIL: 'userEmail',
};
export const FiltersContext = createContext(null);
export const isFilterAllowed = (filter, computedZoom, selectedTown) => {
    if (computedZoom < PLU_ZOOM_LIMIT && filter === filtersKey.PLU) {
        return false;
    }
    if (computedZoom < PLU_GPU_ZOOM_LIMIT && filter === filtersKey.PLU_GPU) {
        return false;
    }
    if (computedZoom < PLOTS_ZOOM_LIMIT && filter === filtersKey.PLOTS) {
        return false;
    }
    if (computedZoom < CADASTRE_ZOOM_LIMIT && filter === filtersKey.CADASTRE) {
        return false;
    }
    if (selectedTown) {
        return filter !== filtersKey.TOWNS;
    }
    return filter === filtersKey.TOWNS;
};
export const FiltersProvider = ({ children }) => {
    const { trackEvent } = useAnalytics();
    const [filters, setFilters] = useState(INITIAL_FILTERS);
    const [extraFilters, setExtraFilters] = useState(INITIAL_EXTRA_FILTERS);
    const [filtersSearchRegex, setFiltersSearchRegex] = useState(null);
    const [tableFilters, setTableFilters] = useState({});
    const updateFiltersSearch = (input) => {
        if (input === null) {
            setFiltersSearchRegex(null);
            return;
        }
        trackEvent({ category: ANALYTICS_CATEGORY_SEARCH, action: input });
        setFiltersSearchRegex(getFiltersSearchRegex(input));
    };
    const isFilterActive = (name) => !!filters[name];
    const toggleFilter = useCallback((name, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value !== undefined ? value : !filters[name],
        }));
    }, [JSON.stringify(filters)]);
    const updateFilters = (partialFilters) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            ...partialFilters,
        }));
    };
    const resetFilters = (filtersKeysToKeep) => {
        const newFilters = { ...INITIAL_FILTERS };
        const newExtraFilters = { ...INITIAL_EXTRA_FILTERS };
        setFilters((prevFilters) => {
            if (filtersKeysToKeep) {
                filtersKeysToKeep.forEach((key) => {
                    newFilters[key] = prevFilters[key];
                });
            }
            return newFilters;
        });
        setExtraFilters((prevExtraFilters) => {
            if (filtersKeysToKeep) {
                filtersKeysToKeep.forEach((key) => {
                    newExtraFilters[key] = prevExtraFilters[key];
                });
            }
            return newExtraFilters;
        });
    };
    const clearFilters = (withSearchFilters = true) => {
        setFilters(withSearchFilters
            ? { [filtersKey.TOWN]: true }
            : {
                [filtersKey.PLOTS_SEARCH]: filters[filtersKey.PLOTS_SEARCH],
                [filtersKey.TOWN]: true,
            });
        setExtraFilters(INITIAL_EXTRA_FILTERS);
    };
    const updateExtraFilter = (path, value) => {
        return updateFilter(path, value, setExtraFilters);
    };
    const updateTableFilter = (path, value) => {
        return updateFilter(path, value, setTableFilters);
    };
    const updateFilter = (path, value, setter) => {
        setter((prevFilters) => {
            const newFilters = {
                ...prevFilters,
            };
            set(newFilters, path, value);
            return newFilters;
        });
    };
    const activeFiltersCount = Object.entries(filters).filter(([key, value]) => Boolean(value) && key !== 'towns').length;
    // Hide or show filters from legend
    const [hiddenFilters, setHiddenFilters] = useState([]);
    const toggleHiddenFilter = ({ hideFilter, filterName }) => {
        if (filterKeysGroup[filterName]) {
            toggleHiddenFilters(hideFilter ?? true, [
                ...filterKeysGroup[filterName],
                filterName,
            ]);
            return;
        }
        const isHidden = hiddenFilters?.includes(filterName);
        const shouldHide = hideFilter !== undefined ? hideFilter : !isHidden;
        if (!shouldHide && isHidden) {
            setHiddenFilters([...hiddenFilters].filter((filter) => {
                return filter !== filterName;
            }));
            return;
        }
        if (shouldHide && !isHidden) {
            setHiddenFilters([...hiddenFilters, filterName]);
        }
    };
    const toggleHiddenFilters = (hideFilter, filtersName) => {
        let toHidden = [];
        if (hideFilter) {
            // On cache ceux à cacher
            toHidden = filtersName.filter((filterName) => !hiddenFilters.includes(filterName));
        }
        // On retire de hiddenFilters ceux qui était caché
        const filterHiddenFilters = hiddenFilters.filter((filterName) => !filtersName.includes(filterName));
        setHiddenFilters([...filterHiddenFilters, ...toHidden]);
    };
    const isHiddenFilter = (filterNames) => filterNames?.some((filter) => hiddenFilters?.includes(filter));
    return (<FiltersContext.Provider value={{
            filters,
            toggleFilter,
            updateFilters,
            resetFilters,
            clearFilters,
            extraFilters,
            updateExtraFilter,
            activeFiltersCount,
            filtersSearchRegex,
            updateFiltersSearch,
            updateTableFilter,
            tableFilters,
            setFilters,
            hiddenFilters,
            toggleHiddenFilter,
            toggleHiddenFilters,
            isHiddenFilter,
            isFilterActive,
        }}>
      {children}
    </FiltersContext.Provider>);
};
export default FiltersContext;
