import * as React from 'react';
import usePlotStatus from 'hooks/crm/usePlotStatus';
import { PLOT_STATUS_OPTIONS } from 'utils/constants';
import StatusSelect from './StatusSelect';
const PlotStatusSelect = ({ plotId, status, onChange, }) => {
    const { changePlotStatus, isUpdating } = usePlotStatus();
    return (<StatusSelect data={PLOT_STATUS_OPTIONS} onChange={(value) => {
            changePlotStatus({
                plotId,
                status: value.value,
            });
            onChange && onChange();
        }} value={status} isLoading={isUpdating}/>);
};
export default PlotStatusSelect;
