import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, HStack, Menu, MenuButton, MenuItem, MenuList, Text, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_HEADER } from 'analytics/useAnalytics';
import PersonalDataModal from 'components/modals/PersonalDataModal';
import ProfileModal from 'components/modals/ProfileModal';
import SecurityModal from 'components/modals/security/SecurityModal';
import ColorModeSwitch from 'components/switches/ColorModeSwitch';
import { useLogout, useUserData } from 'hooks/contexts/useAuthContext';
import DropdownIcon from 'images/icons/Dropdown';
import Logout from 'images/icons/Logout';
import UserIcon from 'images/icons/User';
const AccountDropdown = () => {
    const { t } = useTranslation();
    const logout = useLogout();
    const userData = useUserData();
    const { trackEvent } = useAnalytics();
    return (<Menu>
      {({ isOpen }) => (<>
          <MenuButton as={Button} leftIcon={<UserIcon fill="var(--colorModeFont-800)"/>} rightIcon={<Box transform={isOpen ? 'rotateX(180deg)' : 'rotateX(0deg)'} transition="transform 0.5s">
                <DropdownIcon fill="var(--colorModeFont-800)"/>
              </Box>} variant="ghost" padding={2} onClick={() => trackEvent({
                category: ANALYTICS_HEADER,
                action: 'Prénom Nom',
            })} data-cy="account_dropdown">
            <Text textStyle="paragraph" display={{
                base: 'none',
                lg: 'inline-block',
            }} data-cy="header_username" textOverflow="ellipsis" overflow="hidden" width="100%">
              {userData?.name}
            </Text>
          </MenuButton>
          <MenuList padding={0} backgroundColor="colorMode.background900" borderRadius="md" zIndex="21">
            <PersonalDataModal />
            <ProfileModal />
            <SecurityModal />

            <VStack paddingX={4} paddingY={3} spacing={2} alignItems="stretch">
              <HStack justifyContent="space-between">
                <Text color="colorMode.font900" textStyle="paragraph">
                  {t('header.account.mode')}
                </Text>
                <ColorModeSwitch />
              </HStack>

              {/*<HStack justifyContent="space-between">*/}
              {/*  <Text color="colorMode.font900" textStyle="paragraph">*/}
              {/*    {t('header.account.display')}*/}
              {/*  </Text>*/}
              {/*  <DisplaySwitch />*/}
              {/*</HStack>*/}
            </VStack>

            <MenuItem paddingX={4} paddingY={3} borderBottomRadius="md" icon={<Logout fill="var(--chakra-colors-red-500)"/>} bgColor="colorMode.background800" onClick={() => {
                logout();
                trackEvent({
                    category: ANALYTICS_HEADER,
                    action: 'Se déconnecter',
                });
            }} data-cy="logout_btn">
              <Text textStyle="paragraph" color="red.500">
                {t('header.account.logout')}
              </Text>
            </MenuItem>
          </MenuList>
        </>)}
    </Menu>);
};
export default AccountDropdown;
