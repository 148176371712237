import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, HStack, Icon, Link, Skeleton, Text, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CATEGORY_DOWNLOAD, ANALYTICS_MAP_STICKY, } from 'analytics/useAnalytics';
import { useIsFilterActive, useToggleFilter, } from 'hooks/contexts/useFiltersContext';
import { useIsLoadingPLUGPUInfo, usePLUGPUInfo, } from 'hooks/contexts/useMapContext';
import DownloadIcon from 'images/icons/Download';
import { filtersKey } from 'utils/filters';
import LegendAccordion from './LegendAccordion';
const PLUGPULegendSkeleton = () => {
    return (<VStack alignItems="flex-start">
      <Skeleton height="10px" width="60%"/>
      <Skeleton height="10px" width="80%"/>
      <Skeleton height="10px" width="100%"/>
      <Skeleton height="20px" width="80%"/>
    </VStack>);
};
const PLUGPULegendItem = () => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const toggleFilter = useToggleFilter();
    const PLUGPUInfo = usePLUGPUInfo();
    const isLoadingPLUGPUInfo = useIsLoadingPLUGPUInfo();
    const isFilterActive = useIsFilterActive();
    if (!isFilterActive(filtersKey.PLU_GPU) &&
        !PLUGPUInfo &&
        !isLoadingPLUGPUInfo) {
        return null;
    }
    return (<LegendAccordion id={filtersKey.PLU_GPU} isLoading={isLoadingPLUGPUInfo} skeleton={<PLUGPULegendSkeleton />} legendLabel={t('legends.plu.title')} handleClose={() => {
            trackEvent({
                category: ANALYTICS_MAP_STICKY,
                action: `${t('legends.plu_gpu.title')} off légende`,
            });
            toggleFilter(filtersKey.PLU_GPU, false);
        }}>
      {PLUGPUInfo && (<Box key={PLUGPUInfo?.id}>
          <Text fontWeight="900">{PLUGPUInfo?.area}</Text>
          <Text>
            {t('legends.plu.subarea')} : <b>{PLUGPUInfo.subarea}</b>
          </Text>
          <Text>{PLUGPUInfo.name}</Text>
          {PLUGPUInfo.url && (<HStack alignItems="start" marginTop={1}>
              <Icon as={DownloadIcon} width={4} height={4} color="colorMode.revertBase" alignSelf="center"/>
              <Link isExternal={true} fontSize="12px" href={PLUGPUInfo.url} color="colorMode.revertBase" onClick={() => trackEvent({
                    category: ANALYTICS_CATEGORY_DOWNLOAD,
                    action: 'Télécharger règlement carte GPU',
                })}>
                {t('legends.plu.url')}
              </Link>
            </HStack>)}
        </Box>)}
    </LegendAccordion>);
};
export default PLUGPULegendItem;
