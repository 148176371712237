import { useTranslation } from 'react-i18next';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import * as iots from 'io-ts';
import { BUILDING_PLOT_TAB, FEASIBILITY_PLOT_TAB, MY_CRM_PLOT_TAB, NEIGHBORHOOD_PLOT_TAB, OCCUPANT_PLOT_TAB, OWNER_PLOT_TAB, PLOT_PLOT_TAB, PLU_REGULATION_PLOT_TAB, SALES_PLOT_TAB, } from 'types/api';
export const ANALYTICS_CATEGORY_DOWNLOAD = 'Télécharger';
export const ANALYTICS_CATEGORY_SEARCH = 'Rechercher';
export const ANALYTICS_CATEGORY_SEARCH_TOWN = 'Commune recherchée';
export const ANALYTICS_CRM = 'CRM';
export const ANALYTICS_DASHBOARD = 'Dashboard';
export const ANALYTICS_DISPLAY = 'display';
export const ANALYTICS_HEADER = 'Header';
export const ANALYTICS_LOGIN = 'Login';
export const ANALYTICS_LAND_POINT = 'Point foncier';
export const ANALYTICS_MAP_BUTTONS = 'Carte Boutons';
export const ANALYTICS_MAP_DISPLAY = 'Afficher une carte';
export const ANALYTICS_MAP_STICKY = 'Carte sticky';
export const ANALYTICS_MASQUER = 'Masquer';
export const ANALYTICS_MORE_INFO = 'En savoir plus';
export const ANALYTICS_ONGLET = 'Onglet';
export const ANALYTICS_OPEN = 'Ouvrir';
export const ANALYTICS_PLOT_TAB = 'Parcelle';
export const ANALYTICS_PROJECT_TAB = 'Projet';
export const ANALYTICS_CONTACT_TAB = 'Contact';
export const ANALYTICS_RACCOURCI = 'Raccourci';
export const ANALYTICS_TABLE = 'Filtrer et trier les tableaux';
export const ANALYTICS_TELECOMMANDE = 'Télécommande';
export const ANALYTICS_TELECOMMANDE_ACTIONS = 'Télécommande actions';
export const ANALYTICS_URBANISM_FILTERS = 'Filtres urbanisme';
export const ANALYTICS_AGENDA = 'Agenda';
export const ANALYTICS_COURIER = 'Courrier';
export const ANALYTICS_FEASIBILITY = 'Faisabilité';
export const ANALYTICS_IMPORT_CONTACT = 'Import contact';
export const ANALYTICS_IMPORT_PUBLIPOSTAGE = 'Import publipostage';
export const ANALYTICS_MANAGER = 'Manager';
export const ANALYTICS_CATEGORY_FIND_LAND = 'Trouver foncier';
export const IOAnalyticsEvent = iots.intersection([
    iots.type({
        category: iots.string,
        action: iots.string,
    }),
    iots.partial({
        name: iots.string,
        value: iots.number,
    }),
], 'IOAnalyticsEvent');
export const IOAnalyticsLink = iots.type({
    href: iots.string,
    linkType: iots.keyof({ download: 'download', link: 'link' }),
}, 'IOAnalyticsLink');
const useAnalytics = () => {
    const { t } = useTranslation();
    const { trackEvent: trackEventMatomo, trackLink: trackLinkMatomo } = useMatomo();
    const trackEvent = (event) => {
        trackEventMatomo({
            category: event.category,
            action: event.action,
            name: event.name ?? '',
            href: '', // Pour ne pas avoir le lien dans les tracking de matomo
        });
    };
    const trackLink = (link) => {
        trackLinkMatomo({ href: link.href, linkType: link.linkType });
    };
    const trackGetActionFilter = (filter, label) => {
        switch (filter) {
            case 'tax_incentives':
                return t(`sidebar.categories.tax_incentives_track`);
            case 'airborne_noise':
                return t(`sidebar.categories.${filter}`);
            default:
                return t(`sidebar.categories.${filter}`) + ' ' + label;
        }
    };
    // Pour les exports (Excel/PDF) d'une parcelle
    const trackGetNameExport = (name) => {
        switch (name) {
            case PLOT_PLOT_TAB:
                return 'Onglet Parcelle';
            case FEASIBILITY_PLOT_TAB:
                return 'Onglet Faisabilité';
            case PLU_REGULATION_PLOT_TAB:
                return 'Onglet PLU';
            case OWNER_PLOT_TAB:
                return 'Onglet Propriétaire';
            case OCCUPANT_PLOT_TAB:
                return 'Onglet Occupants';
            case BUILDING_PLOT_TAB:
                return 'Onglet Bâtiments';
            case SALES_PLOT_TAB:
                return 'Onglet Prix de vente';
            case NEIGHBORHOOD_PLOT_TAB:
                return 'Onglet Quartier';
            case MY_CRM_PLOT_TAB:
                return 'Onglet Mon CRM';
            default:
                return 'Export';
        }
    };
    return { trackEvent, trackLink, trackGetNameExport, trackGetActionFilter };
};
export default useAnalytics;
