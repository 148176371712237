import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, Select } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_URBANISM_FILTERS, } from 'analytics/useAnalytics';
import { useExtraFilters, useIsFilterActive, useToggleFilter, useUpdateExtraFilter, } from 'hooks/contexts/useFiltersContext';
import { useToggleLayerTab } from 'hooks/contexts/useTabsContext';
import { CODE_APE_OPTIONS } from 'utils/constants';
import { filtersKey } from 'utils/filters';
const AgricultureRearingCompanySidebarContent = () => {
    const { trackEvent } = useAnalytics();
    const extraFilters = useExtraFilters();
    const updateExtraFilter = useUpdateExtraFilter();
    const { t } = useTranslation();
    const toggleFilter = useToggleFilter();
    const isFilterActive = useIsFilterActive();
    const toggleLayerTab = useToggleLayerTab();
    const codeAPESelectedOption = extraFilters?.[filtersKey.AGRICULTURE_REARING_COMPANY]?.codeAPE || '011';
    const codeAPEDisplayOptions = CODE_APE_OPTIONS.map((option) => {
        return {
            value: option.value,
            label: option.label,
        };
    });
    const handleCodeAPEChange = (e) => {
        const value = e.target.value;
        updateExtraFilter(`${filtersKey.AGRICULTURE_REARING_COMPANY}.codeAPE`, value);
        if (!isFilterActive(filtersKey.AGRICULTURE_REARING_COMPANY) &&
            value !== '') {
            toggleFilter(filtersKey.AGRICULTURE_REARING_COMPANY, true);
            toggleLayerTab({ tabId: filtersKey.AGRICULTURE_REARING_COMPANY });
        }
        else if (value === '') {
            toggleFilter(filtersKey.AGRICULTURE_REARING_COMPANY, false);
        }
        trackEvent({
            category: ANALYTICS_URBANISM_FILTERS,
            action: `${t(`sidebar.categories.${filtersKey.AGRICULTURE_REARING_COMPANY}`)} ${t('sidebar_content.agriculture_rearing_company.code_ape.label')}`,
        });
    };
    return (<Box width="full">
      <FormControl display="flex" alignItems="center">
        <Select size="sm" onChange={handleCodeAPEChange} value={codeAPESelectedOption}>
          {codeAPEDisplayOptions.map((option) => (<option key={option.value} value={option.value}>
              {option.value.length === 4 ? '   ' : ''}
              {option.label}
            </option>))}
        </Select>
      </FormControl>
    </Box>);
};
export default AgricultureRearingCompanySidebarContent;
