import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Skeleton, VStack } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_PLOT_TAB } from 'analytics/useAnalytics';
import PlotButton from 'components/buttons/PlotButton';
import { menuItems } from 'components/sidebar/utils';
import PlotStatus from 'components/tabs/plots/PlotSections/info/PlotStatus';
import { useClearPlotSummary, useIsLoadingPlotSummary, usePlotSummary, } from 'hooks/contexts/useLegendContext';
import { useToggleMenu } from 'hooks/contexts/useMenuContext';
import LegendAccordion from '../LegendAccordion';
import PlotSummaryInfo from './PlotSummaryInfo';
import PlotSummaryProjects from './PlotSummaryProjects';
const PlotSummarySkeleton = () => {
    return (<VStack alignItems="flex-start">
      <Skeleton height="20px" width="60%"/>
      <Skeleton height="10px" width="80%"/>
      <Skeleton height="10px" width="40%"/>
      <Skeleton height="10px" width="80%"/>
      <Skeleton height="30px" width="100%"/>
    </VStack>);
};
const PlotSummaryLegendItem = () => {
    const { t } = useTranslation();
    const clearPlotSummary = useClearPlotSummary();
    const plotSummary = usePlotSummary();
    const { trackEvent } = useAnalytics();
    const isLoadingPlotSummary = useIsLoadingPlotSummary();
    const toggleMenu = useToggleMenu();
    if (plotSummary || isLoadingPlotSummary)
        return (<Accordion defaultIndex={[0]} allowMultiple borderColor="transparent" backgroundColor="colorMode.background900">
        <LegendAccordion id="plot_legend" handleClose={() => {
                clearPlotSummary();
            }} legendLabel={t('legends.my_plot')} isLoading={isLoadingPlotSummary} skeleton={<PlotSummarySkeleton />} borderColor="transparent">
          <>
            {!!plotSummary && (<VStack alignItems="flex-start" spacing={4} data-cy="plot_legend_summary">
                <PlotSummaryInfo />

                <PlotStatus plotId={plotSummary?.id}/>

                <PlotSummaryProjects plot={plotSummary}/>

                <PlotButton lat={plotSummary.lat} lng={plotSummary.lng} townId={plotSummary.townId} plotId={plotSummary.id} variant="outline" width="100%" onOpenPlot={() => {
                    toggleMenu(menuItems?.LAYERS_TREE, false);
                    trackEvent({
                        category: ANALYTICS_PLOT_TAB,
                        action: 'Voir la parcelle',
                    });
                }} id="plot_legend">
                  {t('legends.plot_info.see_plot')}
                </PlotButton>
              </VStack>)}
          </>
        </LegendAccordion>
      </Accordion>);
    return null;
};
export default React.memo(PlotSummaryLegendItem);
