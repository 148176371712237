import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { createContext } from 'use-context-selector';
import { CADASTRE_ZOOM_LIMIT } from 'components/layers/CadastreLayer';
import { useFilters, useSetFilters, useToggleFilter, useUpdateFilters, } from 'hooks/contexts/useFiltersContext';
import { useCloseLayout, useSetLayoutHalf, useTabsView, } from 'hooks/contexts/useLayoutContext';
import { useLastTownAddedToHistory, useSetDisplayedPlot, } from 'hooks/contexts/useLocationContext';
import { useClearParentFeasibility } from 'hooks/contexts/useSecondaryPlotsContext';
import useUserHistory from 'hooks/crm/useUserHistory';
import useHash from 'hooks/useHash';
import { filtersKey } from 'utils/filters';
import { TABS_VIEW_CLOSED, TABS_VIEW_CLOSED_VERTICAL, TABS_VIEW_HALF, } from './LayoutContext';
export const PLOT_TAB_TYPE = 'plot';
export const PROJECT_TAB_TYPE = 'project';
export const CONTACT_TAB_TYPE = 'contact';
export const DASHBOARD_TAB_TYPE = 'dashboard';
export const CRM_TAB_TYPE = 'crmTab';
export const FILTER_TAB_TYPE = 'filterTab';
export const CRM_TABS = {
    USER_PLOTS: 'user_plots',
    USER_PROJECTS: 'user_projects',
    CONTACT_LIST: 'contact_list',
    TEAM_ACCESS_RIGHTS: 'team_access_rights',
    TEAM_PLOTS: 'team_plots',
    TEAM_PROJECTS: 'team_projects',
    TEAM_STATISTICS: 'team_statistics',
    TEAM_STATISTICS_CRM: 'team_statistics_crm',
    DASHBOARD: 'dashboard',
    MY_STATISTICS: 'my_statistics',
    MY_STATISTICS_CRM: 'my_statistics_crm',
    ACTIVITY: 'activity',
    COURIER: 'courier',
    USER_PLOTS_NEWS: 'user_plots_news',
    LAND_POINT: 'land_point',
};
// Tabs linked to a filter checked / search filled in urbanism or plot finder menu
const FILTER_LAYER_TABS = [
    filtersKey.BANKRUPT_COMPANIES,
    filtersKey.BANKRUPT_COMPANIES_PLOTS,
    filtersKey.COMPANY_OWNED_PLOTS,
    filtersKey.PLOTS_SEARCH,
    filtersKey.CNAC,
    filtersKey.DEATH,
    filtersKey.AGRICULTURE_REARING_COMPANY,
];
// Building filter has a special format with child filters
export const FILTERS_BUILDING_PERMIT = {
    [filtersKey.BUILDING_PERMIT_HOUSING]: [
        filtersKey.BUILDING_PERMIT_HOUSING_ALLOWED_PERMITS,
        filtersKey.BUILDING_PERMIT_HOUSING_WORK_STARTED,
        filtersKey.BUILDING_PERMIT_HOUSING_WORK_DONE,
        filtersKey.BUILDING_PERMIT_HOUSING_CANCELED_PERMITS,
    ],
    [filtersKey.BUILDING_PERMIT_OFFICES]: [
        filtersKey.BUILDING_PERMIT_OFFICES_ALLOWED_PERMITS,
        filtersKey.BUILDING_PERMIT_OFFICES_WORK_STARTED,
        filtersKey.BUILDING_PERMIT_OFFICES_WORK_DONE,
        filtersKey.BUILDING_PERMIT_OFFICES_CANCELED_PERMITS,
    ],
    [filtersKey.BUILDING_PERMIT_PLANNING_PERMIT]: [
        filtersKey.BUILDING_PERMIT_PLANNING_PERMITS_ALLOWED_PERMITS,
        filtersKey.BUILDING_PERMIT_PLANNING_PERMITS_WORK_STARTED,
        filtersKey.BUILDING_PERMIT_PLANNING_PERMITS_WORK_DONE,
        filtersKey.BUILDING_PERMIT_PLANNING_PERMITS_CANCELED_PERMITS,
    ],
};
// Layer tabs related to user info
export const CRM_LAYER_TABS = [
    CRM_TABS.USER_PLOTS,
    CRM_TABS.CONTACT_LIST,
    CRM_TABS.USER_PROJECTS,
    CRM_TABS.TEAM_PLOTS,
    CRM_TABS.TEAM_PROJECTS,
];
// Layer tabs related to selected town (plot search or filters)
export const ALL_FILTER_TABS_KEYS = [
    ...FILTER_LAYER_TABS,
    ...Object.values(FILTERS_BUILDING_PERMIT).flat(),
];
// All tabs linked to any layer displayed on the map
export const ALL_LAYER_TABS_KEYS = [...ALL_FILTER_TABS_KEYS, ...CRM_LAYER_TABS];
export const TabsContext = createContext(null);
export const TabsProvider = ({ children }) => {
    const filters = useFilters();
    const setFilters = useSetFilters();
    const toggleFilter = useToggleFilter();
    const updateFilters = useUpdateFilters();
    const setLayoutHalf = useSetLayoutHalf();
    const closeLayout = useCloseLayout();
    const tabsView = useTabsView();
    const setDisplayedPlot = useSetDisplayedPlot();
    const [lastTownAddedToHistory, setLastTownAddedToHistory] = useLastTownAddedToHistory();
    const { addAddressToHistory } = useUserHistory(false);
    const clearAllPlotFeasibility = useClearParentFeasibility();
    // Keep plots/projects/contacts/tabs order
    const [tabs, setTabs] = useState([]);
    // one project = one tab
    const [projects, setProjects] = useState({});
    // one contact = one tab
    const [contacts, setContacts] = useState({});
    // on plot = one tab
    const [plots, setPlots] = useState({});
    const [selectedBuilding, setSelectedBuilding] = useState(null);
    const [selectedTab, setSelectedTab] = React.useState(null);
    const selectedPlot = useMemo(() => {
        const currentTab = tabs.find((tab) => tab.id === selectedTab);
        return plots[currentTab?.id];
    }, [plots, selectedTab, tabs]);
    const selectedProject = useMemo(() => {
        const currentTab = tabs.find((tab) => tab.id === selectedTab);
        return projects[currentTab?.id];
    }, [projects, selectedTab, tabs]);
    useEffect(() => {
        // Before the plot is loaded, we will still get a plot with only a few fields.
        // We need to wait for the full plot to be loaded before saving it to history
        if (!selectedPlot || !selectedPlot.address) {
            return;
        }
        // When the selected plot changes, we save the plot address only if the town was not yet saved
        if (selectedPlot.townId === lastTownAddedToHistory) {
            return;
        }
        const address = selectedPlot.address.length
            ? selectedPlot.address[0]
            : selectedPlot.street && selectedPlot.street !== ''
                ? selectedPlot.street + ' ' + selectedPlot.town
                : selectedPlot.town;
        addAddressToHistory({
            townId: selectedPlot.townId,
            address: address,
            lat: selectedPlot.lat,
            lng: selectedPlot.lng,
            acuteness: CADASTRE_ZOOM_LIMIT,
        });
        setLastTownAddedToHistory(selectedPlot.townId);
    }, [selectedPlot]);
    const resetTabs = ({ resetLayerTabs }) => {
        setSelectedTab(null);
        setProjects({});
        setContacts({});
        setPlots({});
        setLayoutHalf();
        if (resetLayerTabs) {
            resetLayerTabsFilters();
        }
        setTabs([]);
    };
    const { sectionHash, setSectionHashToUrl, clearSectionHash } = useHash();
    useEffect(() => {
        if (Object.keys(plots)?.length === 0) {
            clearSectionHash();
        }
    }, [plots]);
    const removeTabAndSelectNewOne = async (tabId) => {
        let deletedTabIndex = null;
        await setTabs((previousTabs) => {
            const newTabs = [...previousTabs];
            deletedTabIndex = newTabs.findIndex((tab) => tab.id === tabId);
            if (deletedTabIndex > -1) {
                newTabs.splice(deletedTabIndex, 1);
            }
            return newTabs;
        });
        if (deletedTabIndex > -1 && selectedTab === tabId) {
            selectDefaultTab(deletedTabIndex);
        }
    };
    const deleteTab = (tab) => {
        if (tab.type === PLOT_TAB_TYPE) {
            removeTabAndSelectNewOne(tab.id);
            const { [tab.id]: _, ...cleanedPlots } = plots;
            setPlots(cleanedPlots);
            // Suppression des faisabilités (vu que l'on ferme la parcelle)
            clearAllPlotFeasibility(tab.id);
            return;
        }
        if (tab.type === CONTACT_TAB_TYPE) {
            removeTabAndSelectNewOne(tab.id);
            const { [tab.id]: _, ...cleanedContacts } = contacts;
            setContacts(cleanedContacts);
            return;
        }
        if (tab.type === PROJECT_TAB_TYPE) {
            removeTabAndSelectNewOne(tab.id);
            toggleFilter(filtersKey.MAP_PLOTS, false);
            const { [tab.id]: _, ...cleanedProjects } = projects;
            setProjects(cleanedProjects);
            return;
        }
        if (tab.type === FILTER_TAB_TYPE) {
            toggleLayerTab({ tabId: tab.id, closeTab: true });
        }
        if (tab.type === CRM_TAB_TYPE) {
            toggleCRMTab({ tabId: tab.id, closeTab: true });
        }
    };
    const toggleResourceTab = (setter, type) => ({ resource }) => {
        const isOpened = isTabOpened(resource?.id);
        if (!isOpened) {
            setter((prevResources) => {
                const newResources = { ...prevResources };
                if (!isOpened) {
                    newResources[resource.id] = resource;
                    if (!isOpened && !tabs.find((tab) => tab.id === resource.id)) {
                        tabs.push({ id: resource.id, type });
                    }
                }
                setTabs(tabs);
                return newResources;
            });
        }
        changeSelectedTab(resource.id, TABS_VIEW_HALF);
    };
    //PLOTS
    const togglePlot = ({ resource, ...rest }) => {
        setDisplayedPlot(resource);
        setLayoutHalf();
        return toggleResourceTab(setPlots, PLOT_TAB_TYPE)({
            ...rest,
            resource,
        });
    };
    const updatePlot = (newPlotData) => {
        const newPlots = { ...plots };
        newPlots[newPlotData?.id] = newPlotData;
        setPlots(newPlots);
    };
    //PROJECTS
    const toggleProject = ({ resource, ...rest }) => {
        return toggleResourceTab(setProjects, PROJECT_TAB_TYPE)({ ...rest, resource });
    };
    const updateProjectName = ({ projectId, newName, }) => {
        const newProjects = {
            ...projects,
            [projectId]: { ...projects[projectId], label: newName },
        };
        setProjects(newProjects);
    };
    // CONTACT
    const toggleContact = ({ resource, ...rest }) => {
        return toggleResourceTab(setContacts, CONTACT_TAB_TYPE)({ ...rest, resource });
    };
    const updateContactInfo = ({ contactId, newInfo }) => {
        const newContact = {
            ...contacts,
            [contactId]: { ...contacts[contactId], ...newInfo },
        };
        setContacts(newContact);
    };
    // CRM TAB
    const toggleCRMTab = async ({ tabId, closeTab }) => {
        const isOpen = isTabOpened(tabId);
        if (closeTab) {
            await removeTabAndSelectNewOne(tabId);
        }
        else {
            if (!isOpen) {
                await setTabs((previousTabs) => [
                    ...previousTabs,
                    { id: tabId, type: CRM_TAB_TYPE },
                ]);
            }
            changeSelectedTab(tabId, TABS_VIEW_HALF);
        }
    };
    // LAYER TAB
    const resetLayerTabsFilters = () => {
        const filtersToRemove = {};
        ALL_LAYER_TABS_KEYS.map((key) => (filtersToRemove[key] = false));
        setFilters({ ...filters, ...filtersToRemove });
    };
    const toggleLayerTab = async ({ tabId, closeTab, skipFilter, layout = TABS_VIEW_HALF, }) => {
        const isOpen = isTabOpened(tabId);
        if (closeTab) {
            if (!skipFilter) {
                const updatedFilters = { [tabId]: false };
                if (Object.keys(FILTERS_BUILDING_PERMIT).includes(tabId)) {
                    FILTERS_BUILDING_PERMIT[tabId].forEach((filter) => (updatedFilters[filter] = false));
                }
                updateFilters(updatedFilters);
            }
            removeTabAndSelectNewOne(tabId);
        }
        else {
            !skipFilter && toggleFilter(tabId, true);
            if (!isOpen) {
                await setTabs((previousTabs) => [
                    ...previousTabs,
                    { id: tabId, type: FILTER_TAB_TYPE },
                ]);
            }
            changeSelectedTab(tabId, layout);
        }
    };
    const adaptLayerTabs = (filters) => {
        // Open or close tabs depending on associated filter state
        ;
        [...FILTER_LAYER_TABS, ...CRM_LAYER_TABS]?.map((tabId) => {
            if (isTabOpened(tabId) && !filters[tabId]) {
                toggleLayerTab({ tabId, closeTab: true, skipFilter: true });
            }
            else if (!!filters[tabId] && !isTabOpened(tabId)) {
                toggleLayerTab({ tabId, skipFilter: true });
            }
        });
        Object.keys(FILTERS_BUILDING_PERMIT).map((tabId) => {
            const hasChildFiltersChecked = FILTERS_BUILDING_PERMIT[tabId].some((el) => !!filters[el]);
            if (isTabOpened(tabId) && !hasChildFiltersChecked) {
                toggleLayerTab({ tabId, closeTab: true, skipFilter: true });
            }
            else if (!isTabOpened(tabId) && hasChildFiltersChecked) {
                toggleLayerTab({ tabId, skipFilter: true });
            }
        });
    };
    useEffect(() => {
        adaptLayerTabs(filters);
    }, [filters]);
    // TAB NAVIGATION
    const selectDefaultTab = (deletedTabIndex) => {
        setSelectedTab((previouslySelectedTab) => {
            const newTabsIds = tabs
                .map((tab) => tab.id)
                .filter((id) => id !== previouslySelectedTab);
            if (!newTabsIds.length) {
                return null;
            }
            // Select left tab if available
            if (deletedTabIndex > 0 && newTabsIds[deletedTabIndex - 1]) {
                return newTabsIds[deletedTabIndex - 1];
            }
            // First tab deleted : select new first tab
            return newTabsIds[0];
        });
    };
    const changeSelectedTab = (tabId, layout = TABS_VIEW_HALF) => {
        setSelectedTab(tabId);
        if ([TABS_VIEW_CLOSED, TABS_VIEW_CLOSED_VERTICAL]?.includes(tabsView) &&
            layout === TABS_VIEW_HALF) {
            setLayoutHalf();
        }
        else if (layout === TABS_VIEW_CLOSED ||
            layout === TABS_VIEW_CLOSED_VERTICAL) {
            closeLayout();
        }
    };
    const isTabOpened = (tabId) => {
        return !!tabId && tabs.map((tab) => tab?.id)?.includes(tabId);
    };
    return (<TabsContext.Provider value={{
            tabs,
            selectedTab,
            changeSelectedTab,
            togglePlot,
            selectedBuilding,
            setSelectedBuilding,
            projects,
            selectedProject,
            toggleProject,
            updateProjectName,
            contacts,
            toggleContact,
            updateContactInfo,
            plots,
            selectedPlot,
            updatePlot,
            toggleCRMTab,
            toggleLayerTab,
            isTabOpened,
            sectionHash,
            setSectionHashToUrl,
            resetTabs,
            deleteTab,
        }}>
      {children}
    </TabsContext.Provider>);
};
export default TabsContext;
